import { Theme } from '@material-ui/core';
import { StylesObjectType } from '../../../types/types';

export default function styles(theme: Theme): StylesObjectType {
  return {
    box: {
      position: 'absolute' as const, // strange ts type casting problem
      top: '50%',
      left: '50%',
      display: 'flex',
      flexDirection: 'column' as const,
      transform: 'translate(-50%, -50%)',
      minWidth: '800px',
      maxWidth: '1200px',
      maxHeight: '100%',
      borderRadius: '10px',
      background: theme.palette.background.paper,
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        width: '100%',
        minWidth: 'auto',
        height: '100%',
        borderRadius: '0',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    modalHeader: {
      flex: '0 0 auto',
      display: 'flex',
      alignItem: 'center',
      padding: '20px',
      color: theme.palette.info.dark,
      borderBottom: `1px solid ${theme.palette.grey[500]}`,
    },
    contentWrap: {
      maxHeight: '550px',
      padding: '20px',
      overflowY: 'scroll' as const,
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        flex: '1 1 auto',
        maxHeight: 'unset',
      },
    },
    buttonBlock: {
      flex: '0 0 auto',
      padding: '10px',
      background: theme.palette.grey[100],
      borderRadius: '10px',
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        borderRadius: '0',
      },
    },
  };
}
