import axios from 'axios';

import { logOut } from '../redux/authenticate/slice';
import { API } from '../constants/api';
import { AUTH_COOKIES } from '../constants/authCookies';
import store from '../redux/store';
import { authCookie } from '../utils/cookies';
import { resetSession, saveSession } from '../utils/session';
import { ROUTES } from '../app/AppRoutes/routes';

interface RefreshToken {
  idToken: string;
  expiresIn: number;
  accessToken: string;
  tokenType: 'Bearer';
}

const { REACT_APP_RAK_CLIENT_ID: clientId, REACT_APP_RAK_ID_API: rakIdUrl } =
  process.env;

const rakIdApi = axios.create({
  baseURL: rakIdUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const handleLogout = () => {
  resetSession();
  store.dispatch(logOut());
  window.location.href = ROUTES.ROOT;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const clearRakSession = async () => {
  const refreshToken = authCookie.getCookie(AUTH_COOKIES.refreshToken);
  const rakIdRequest = axios.create({
    baseURL: rakIdUrl,
    headers: {
      'Content-Type': 'application/json',
      authorization: `Bearer ${authCookie.getCookie(AUTH_COOKIES.accessToken)}`,
    },
  });

  const response = await rakIdRequest.post(API.RAK_ID.LOGOUT, {
    refreshToken,
  });

  return response;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const refreshAccessToken = async () => {
  const refreshToken = authCookie.getCookie(AUTH_COOKIES.refreshToken);

  try {
    const response = await rakIdApi.post<RefreshToken>(
      API.RAK_ID.REFRESH_TOKEN,
      {
        refreshToken,
        grantType: 'refresh_token',
        clientId,
      },
    );

    const { accessToken, idToken } = response.data;

    if (!accessToken) {
      handleLogout();
    } else {
      saveSession({ accessToken, idToken });
    }

    return response;
  } catch (error) {
    return handleLogout();
  }
};
