import React from 'react';
import { makeStyles, MenuItem } from '@material-ui/core';
import ArrowForward from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { SpecificationsListType } from '../../../../types/spec';
import DropdownMenu from '../../../../components/ui/DropdownMenu';
import { setFirmwareToShow } from '../../../../redux/deviceDetails/slice';
import { ROUTES } from '../../../../app/AppRoutes/routes';
import styles from './styles';

const useStyles = makeStyles(styles);

const SupportedDevice = ({
  modelId,
  firmwareVersions,
}: TProps): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMenuItemClick = (firmware: string) => {
    dispatch(setFirmwareToShow({ firmware, modelId }));
    setTimeout(() => navigate(ROUTES.DEVICES));
  };

  const firmwareList = firmwareVersions.map((firmware) => (
    <MenuItem
      key={firmware}
      onClick={(e) => {
        e.stopPropagation();
        handleMenuItemClick(firmware);
      }}
    >
      {firmware}
    </MenuItem>
  ));

  return (
    <MenuItem className={classes.subMenu}>
      <DropdownMenu
        anchor={
          <div className={classes.menuItem}>
            {modelId.toLowerCase()}
            <ArrowForward fontSize="small" />
          </div>
        }
        menuItems={firmwareList}
        placement="right-start"
      />
    </MenuItem>
  );
};

type TProps = SpecificationsListType['supportedDevices'][0];

export default SupportedDevice;
