import { all, put, takeLatest, select, takeLeading } from 'redux-saga/effects';
import requestSaga from '../api/sagas';
import {
  fetchSpecList,
  fetchSpecListRequest,
  fetchSensorSpecListRequest,
} from './actions';
import { FetchSpecsListMods } from './interface';
import { getSpecsState } from './selectors';
import { deletePrevToken, setPrevToken } from './slice';
import types from './types';
import { SpecificationType } from '../../types/spec';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* fetchSpecsListSaga(action: ReturnType<typeof fetchSpecList>): any {
  const state: ReturnType<typeof getSpecsState> = yield select(getSpecsState);

  const mode = action.payload;
  const { nextToken, limit, prevTokens, sortBy, sortOrder } = state;

  let token;
  switch (mode) {
    case FetchSpecsListMods.prev:
      token = prevTokens[prevTokens.length - 2] || undefined;
      yield put(
        deletePrevToken([nextToken, prevTokens[prevTokens.length - 1]]),
      );
      break;
    case FetchSpecsListMods.next:
      token = nextToken || undefined;
      yield put(setPrevToken(nextToken));
      break;
    default:
      token = undefined;
  }

  yield put(
    fetchSpecListRequest({
      params: { nextToken: token, limit, sortBy, sortOrder },
    }),
  );
}

function* fetchSensorSpecsListSaga(
  action: ReturnType<typeof fetchSpecList>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  const state: ReturnType<typeof getSpecsState> = yield select(getSpecsState);

  const mode = action.payload;
  const { nextToken, limit, prevTokens, sortBy, sortOrder } = state;

  let token;
  switch (mode) {
    case FetchSpecsListMods.prev:
      token = prevTokens[prevTokens.length - 2] || undefined;
      yield put(
        deletePrevToken([nextToken, prevTokens[prevTokens.length - 1]]),
      );
      break;
    case FetchSpecsListMods.next:
      token = nextToken || undefined;
      yield put(setPrevToken(nextToken));
      break;
    default:
      token = undefined;
  }

  yield put(
    fetchSensorSpecListRequest({
      params: {
        nextToken: token,
        limit,
        sortBy,
        sortOrder,
        type: SpecificationType.SENSOR_DATA,
      },
    }),
  );
}

export function* specsWatcherSaga(): Generator {
  yield all([
    takeLatest(types.FETCH_SPEC_LIST_REQUEST, requestSaga),
    takeLatest(types.FETCH_SENSOR_SPEC_LIST_REQUEST, requestSaga),
    takeLatest(types.FETCH_SPEC_LIST, fetchSpecsListSaga),
    takeLatest(types.FETCH_SENSOR_SPEC_LIST, fetchSensorSpecsListSaga),
    takeLeading(types.FETCH_AT_VERSIONS_LIST, requestSaga),
    takeLeading(types.FETCH_SENSOR_SPEC_VERSIONS_LIST, requestSaga),
  ]);
}
