export const getTooltipText = (
  withTooltips: boolean,
): {
  title: string;
  accordionTitle: string;
  atCommand: string;
  conditionalDependency: string;
  dependOn: string;
  queue: string;
  action: string;
} => {
  const toolTips = {
    title: 'Set field title (e.g. Firmware version)',
    accordionTitle:
      'Set name for group of commands. Use the same Accordion Title, if you want to combine different AT commands under one Group name.',
    atCommand: 'Set AT Command (e.g. AT+VER)',
    conditionalDependency: 'Set dependency if field would be shown on UI',
    dependOn: 'Set command dependency (e.g. section_command=value)',
    queue: 'Specify if command should be added to queue',
    action:
      'Specifies what action should be made after user triggers UI element',
  };
  if (withTooltips) {
    return toolTips;
  }
  return {
    title: '',
    accordionTitle: '',
    atCommand: '',
    conditionalDependency: '',
    dependOn: '',
    queue: '',
    action: '',
  };
};
