import { all, takeLatest, takeLeading, put, select } from 'redux-saga/effects';
import requestSaga from '../../redux/api/sagas';
import { DeviceDetailsType } from '../../types/device';
import {
  createFirmwareActions,
  fetchDeviceDetails,
  updateDeviceActions,
  updateFirmwareActions,
} from './actions';
import { getDeviceId } from './selectors';

import types from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* requestDeviceSaga(): any {
  const deviceId: DeviceDetailsType['id'] = yield select(getDeviceId);
  yield put(fetchDeviceDetails({ uriParams: { deviceId } }));
}

export function* deviceDetailsWatcherSaga(): Generator {
  yield all([
    takeLatest(types.FETCH_DEVICE, requestSaga),
    takeLeading(types.CREATE_DEVICE, requestSaga),
    takeLeading(types.CREATE_FIRMWARE, requestSaga),
    takeLeading(types.DELETE_DEVICE, requestSaga),
    takeLeading(types.DELETE_FIRMWARE, requestSaga),
    takeLatest(types.UPDATE_DEVICE, requestSaga),
    takeLeading(types.UPDATE_FIRMWARE, requestSaga),
    takeLatest(
      [
        updateFirmwareActions.SUCCESS,
        createFirmwareActions.SUCCESS,
        updateDeviceActions.SUCCESS,
      ],
      requestDeviceSaga,
    ),
  ]);
}
