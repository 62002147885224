import pick from 'lodash/pick';
import { CreateSpecificationBody } from '../../../redux/specification/interface';

export const prepareFormValues = (
  values: CreateSpecificationBody,
): FormData => {
  const specFields = pick(values, 'atVersion', 'aliasName', 'supportedDevices');

  const jsonData = JSON.stringify(specFields);
  const file = values.files;
  const formData = new FormData();
  formData.set('jsonData', jsonData);

  if (file instanceof Blob) {
    formData.set('files', file);
  }

  return formData;
};
