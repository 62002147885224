import React, { useMemo } from 'react';
import { styled, Typography } from '@material-ui/core';

import { AsyncAction } from '../../../../redux/api/interface';
import {
  DeviceDetailsType,
  DeviceStatus,
  FirmwareType,
} from '../../../../types/device';
import Section from '../../../../components/ui/Section';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import FirmwareModal from '../../../../components/modals/FirmwareModal';
import useModal from '../../../../hooks/useModal';

import EditDeviceModal from '../../../../components/modals/EditDeviceModal';
import DeviceViewForm from '../DeviceViewForm';
import DeviceFirmware from '../DeviceFirmware';
import AddEntityBlock from '../../../../components/ui/AddEntityBlock';

const FirmwareSection = styled('div')(() => ({
  marginTop: '26px',
}));

const DeviceDetails = ({
  firmwares,
  deviceData,
  handleDeleteDevice,
  handleDeleteFirmware,
  isLoading,
}: DeviceDetailsProps): JSX.Element => {
  const {
    isModalOpen: deleteDeviceModalOpen,
    toggleModal: toggleDeleteDeviceModal,
  } = useModal();
  const {
    isModalOpen: editDeviceModalOpen,
    toggleModal: toggleEditDeviceModal,
  } = useModal();

  const isPublished = deviceData.status === DeviceStatus.PUBLISHED;
  const editDeviceClickHandler = () => {
    toggleEditDeviceModal(true);
  };
  const closeEitModalHandler = () => {
    toggleEditDeviceModal(false);
  };

  const deleteDeviceClickHandler = () => {
    toggleDeleteDeviceModal(true);
  };

  const lastFirmwareVersion = useMemo(
    () =>
      firmwares
        ?.filter(({ status }) => status === 'PUBLISHED')
        ?.sort(
          (a, b) => +new Date(b.releaseDate) - +new Date(a.releaseDate),
        )[0],
    [firmwares],
  );

  return (
    <>
      {deleteDeviceModalOpen && (
        <ConfirmModal
          headerText="Delete device"
          handleCancel={() => toggleDeleteDeviceModal(false)}
          handleConfirm={() =>
            handleDeleteDevice(deviceData.id).promise?.then(() =>
              toggleDeleteDeviceModal(false),
            )
          }
        >
          <Typography>
            {isPublished
              ? 'Are you sure to delete published device model?'
              : 'Are you sure to delete draft device model?'}
          </Typography>
        </ConfirmModal>
      )}
      {editDeviceModalOpen && (
        <EditDeviceModal
          handleClose={closeEitModalHandler}
          deviceData={deviceData}
        />
      )}

      {deviceData.id && (
        <Section
          headerText={`${
            deviceData.modelId ? deviceData.modelId.toUpperCase() : ''
          } Device details`}
          withDelete
          toggleDeleteDevice={toggleDeleteDeviceModal}
          editCallback={editDeviceClickHandler}
          deleteCallback={deleteDeviceClickHandler}
          withEdit
          withHide
          sectionVariant="primary"
          initialIsOpen
        >
          <DeviceViewForm deviceData={deviceData} />

          <FirmwareSection>
            <Section headerText="FIRMWARE VERSIONS" sectionVariant="secondary">
              {firmwares?.map((firmware) => (
                <DeviceFirmware
                  isLast={lastFirmwareVersion?.id === firmware.id}
                  isLoading={isLoading}
                  key={firmware.id}
                  firmware={firmware}
                  deviceId={deviceData.id}
                  handleDeleteFirmware={() =>
                    handleDeleteFirmware(firmware.id, deviceData.id)
                  }
                />
              ))}
              <AddEntityBlock
                modalElement={FirmwareModal}
                modalProps={{ deviceId: deviceData.id }}
                buttonText="Add new version"
              />
            </Section>
          </FirmwareSection>
        </Section>
      )}
    </>
  );
};

type DeviceDetailsProps = {
  deviceData: DeviceDetailsType;
  firmwares: FirmwareType[];
  handleDeleteDevice: (id: DeviceDetailsType['id']) => AsyncAction<unknown>;
  handleDeleteFirmware: (
    id: FirmwareType['id'],
    deviceId: DeviceDetailsType['id'],
  ) => AsyncAction<unknown>;
  isLoading: boolean;
};

export default DeviceDetails;
