import {
  AsyncAction,
  DispatchTypes,
  RequestType,
} from '../../redux/api/interface';
import { AsyncActionCreator, RequestArgs } from './interface';

export default function asyncActionCreator<
  ResponseType = unknown,
  RequestDataType = undefined,
  RequestParamsType = undefined,
  RequestURIType = undefined,
>(
  type: string,
  method: RequestType['method'],
  url: string | ((arg: RequestURIType) => string),
): AsyncActionCreator<
  RequestDataType,
  RequestParamsType,
  RequestURIType,
  ResponseType
> {
  const dispatchTypes: DispatchTypes = {
    START: `${type}/START`,
    SUCCESS: `${type}/SUCCESS`,
    ERROR: `${type}/ERROR`,
  };

  const actionCreator = ({
    data,
    params,
    uriParams,
  }: RequestArgs<
    RequestDataType,
    RequestParamsType,
    RequestURIType
  >): AsyncAction<ResponseType> => {
    return {
      type,
      payload: {
        request: {
          url:
            url instanceof Function
              ? url(uriParams ? uriParams : ({} as RequestURIType))
              : url,
          method,
          data,
          params,
        },
        dispatch: dispatchTypes,
      },
    };
  };

  return [actionCreator, dispatchTypes];
}
