import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';

import { SpecificationsListType } from '../../../../types/spec';
import AddSpecModal from '../../../../components/modals/AddSpecModal';
import AddEntityBlock from '../../../../components/ui/AddEntityBlock';

import { useSelector } from 'react-redux';
import { RequestStatus } from '../../../../redux/api/interface';
import { getMultiRequestStatus } from '../../../../redux/api/selectors';
import {
  DataGrid,
  GridColumnHeaderParams,
  GridSortModel,
} from '@mui/x-data-grid';
import specificationTypes from '../../../../redux/specs/types';
import styles from './styles';
import { columns, SORT_OPTION_SPECIFICATION } from './config';

const useStyles = makeStyles(styles);

const alphabeticalSort = ['atVersion', 'supportedDevices'];
const dateSort = ['createdAt', 'modifiedDate'];

const ListOfSpec = ({
  specs,
  // handleFetchSpecDetails,
  totalItems,
  handleChoseSizeOfPageClick,
  pageSizeState,
  handleOnPageChange,
  handleSortClick,
}: TProps): JSX.Element => {
  const classes = useStyles();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [tooltipText, setTooltipText] = useState<string>('');

  const setCurrentSortOptionsToLocalStorage = (model: GridSortModel) => {
    setSortModel(model);
    handleSortClick(model);
    localStorage.setItem(SORT_OPTION_SPECIFICATION, JSON.stringify(model));
  };

  const isLoading = useSelector(
    getMultiRequestStatus(
      [specificationTypes.FETCH_SPEC_LIST_REQUEST],
      RequestStatus.loading,
    ),
  );

  const onHeaderOverHandler = (params: GridColumnHeaderParams) => {
    const isConditionTrue =
      sortModel.length > 0 && params.field === sortModel[0].field;
    const addition = new Date().getMilliseconds();
    if (alphabeticalSort.includes(params.field) && isConditionTrue) {
      const sortType = sortModel[0].sort === 'asc' ? 'From A-Z' : 'From Z-A';
      setTooltipText(`${sortType}/${addition}`);
    } else if (dateSort.includes(params.field) && isConditionTrue) {
      const sortType =
        sortModel[0].sort === 'desc'
          ? 'From Newest to Oldest'
          : 'From Oldest to Newest';
      setTooltipText(`${sortType}/${addition}`);
    } else {
      setTooltipText(`Sort/${addition}`);
    }
  };

  useEffect(() => {
    const option = localStorage.getItem(SORT_OPTION_SPECIFICATION);
    if (option) {
      setSortModel(JSON.parse(option));
      handleSortClick(JSON.parse(option));
    }
  }, []);

  return (
    <div className={classes.wrap}>
      <DataGrid
        rows={specs ?? []}
        columns={columns}
        sortingMode="server"
        loading={isLoading}
        autoHeight
        disableColumnMenu
        hideFooterSelectedRowCount
        className={classes.root}
        // onRowClick={(params) => handleFetchSpecDetails(String(params.row.id))}
        rowsPerPageOptions={[5, 10, 20]}
        onPageSizeChange={handleChoseSizeOfPageClick}
        sortModel={sortModel}
        localeText={{
          columnHeaderSortIconLabel: tooltipText.split('/')[0],
        }}
        onSortModelChange={setCurrentSortOptionsToLocalStorage}
        onColumnHeaderOver={onHeaderOverHandler}
        onPageChange={handleOnPageChange}
        rowCount={totalItems}
        pageSize={pageSizeState}
        paginationMode="server"
      />
      <AddEntityBlock
        buttonText="Add new specification"
        modalElement={AddSpecModal}
        modalProps={{ isSubmiting: true }}
        buttonVariant="secondary"
      />
    </div>
  );
};

type TProps = {
  specs: SpecificationsListType[] | null;
  handleFetchSpecDetails: (id: SpecificationsListType['atVersion']) => void;
  totalItems: number;
  handleChoseSizeOfPageClick: (pageSize: number) => void;
  pageSizeState: number;
  handleOnPageChange: (page: number) => void;
  handleSortClick: (model: GridSortModel) => void;
};

export default ListOfSpec;
