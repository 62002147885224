export default {
  FETCH_SPEC_DETAILS: 'specification/FETCH_SPEC_DETAILS',
  CREATE_SPEC: 'specification/CREATE_SPEC',
  CREATE_SPEC_WITH_FILE: 'specification/CREATE_SPEC_WITH_FILE',
  CREATE_NEW_SECTION: 'specifications/CREATE_NEW_SECTION',
  CREATE_NEW_AT_COMMAND: 'specifications/CREATE_NEW_AT_COMMAND',
  DELETE_SPECIFICATION: 'specifications/DELETE_SPECIFICATION',
  DELETE_SPECIFICATION_SECTION: 'specifications/DELETE_SPECIFICATION_SECTION',
  DELETE_AT_COMMAND: 'specifications/DELETE_AT_COMMAND',
  DELETE_UI_ELEMENT: 'specifications/DELETE_UI_ELEMENT',
  EDIT_AT_COMMAND: 'specifications/EDIT_AT_COMMAND',
  PUBLISH_AT_SPECIFICATION: 'specifications/PUBLISH_AT_SPECIFICATION',
};
