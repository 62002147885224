import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchAtVersionListActions,
  fetchSpecListRequestActions,
  fetchSensorSpecListRequestActions,
  fetchSensorSpecVersionListActions,
} from './actions';
import { DEFAULT_SPECS_PAGE_SIZE } from './config';
import { FetchAtCommandsResponse, FetchSpecsListResponse } from './interface';
import { sortByTypes, TInitialState } from './types';

const initialState: TInitialState = {
  limit: DEFAULT_SPECS_PAGE_SIZE,
  page: 1,
  totalItems: 0,
  nextToken: '',
  prevTokens: [],
  specifications: [],
  atVersions: [],
  sensorSpecVersions: [],
  sortBy: sortByTypes.atVersion,
  sortOrder: 'ASC',
};

const specsSlice = createSlice({
  name: 'specs',
  initialState,
  reducers: {
    clearSpecifications(state) {
      state.specifications = initialState.specifications;
      state.sortBy = initialState.sortBy;
      state.sortOrder = initialState.sortOrder;
      state.page = initialState.page;
      state.limit = initialState.limit;
      state.totalItems = initialState.totalItems;
    },
    setPrevToken(state, action) {
      state.prevTokens = state.prevTokens
        ? [...state.prevTokens, action.payload]
        : [action.payload];
    },
    deletePrevToken(state, action) {
      state.prevTokens = state.prevTokens.filter(
        (item) => !action.payload.includes(item),
      );
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setSortParams(state, action) {
      const { sortBy, sortOrder } = action.payload;
      state.sortBy = sortBy;
      state.sortOrder = sortOrder;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchSpecListRequestActions.SUCCESS,
      (state, action: PayloadAction<FetchSpecsListResponse>) => {
        const { data } = action.payload;
        const { nextToken, specifications, quantity } = data;

        state.specifications = specifications;
        state.nextToken = nextToken;
        state.totalItems = quantity;
      },
    );
    builder.addCase(
      fetchSensorSpecListRequestActions.SUCCESS,
      (state, action: PayloadAction<FetchSpecsListResponse>) => {
        const { data } = action.payload;
        const { nextToken, specifications, quantity } = data;

        state.specifications = specifications;
        state.nextToken = nextToken;
        state.totalItems = quantity;
      },
    );
    builder.addCase(
      fetchAtVersionListActions.SUCCESS,
      (state, action: PayloadAction<FetchAtCommandsResponse>) => {
        const { data } = action.payload;
        state.atVersions = data.atVersions;
      },
    );
    builder.addCase(
      fetchSensorSpecVersionListActions.SUCCESS,
      (state, action: PayloadAction<FetchAtCommandsResponse>) => {
        const { data } = action.payload;
        state.sensorSpecVersions = data.atVersions;
      },
    );
  },
});

export const {
  deletePrevToken,
  setPrevToken,
  setLimit,
  setSortParams,
  clearSpecifications,
} = specsSlice.actions;

export default specsSlice.reducer;
