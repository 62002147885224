import { createDraftSafeSelector, EntityState } from '@reduxjs/toolkit';
import { RootStateType } from '../../redux/store';
import { DeviceShortType } from '../../types/device';

export const getDevicesState = (
  state: RootStateType,
): EntityState<DeviceShortType> & { modelIds: string[] } => state.devices;

export const getDevicesList = createDraftSafeSelector(
  getDevicesState,
  (counties) =>
    Object.values(
      counties.entities as {
        [key: DeviceShortType['id']]: DeviceShortType;
      },
    ),
);
