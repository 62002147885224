import React from 'react';
import { Control } from 'react-hook-form';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import DropZoneField from '../DropZoneField';

import styles from '../PhotoDropZone/styles';

const useStyles = makeStyles(styles);

const FileDropZone = ({
  name,
  control,
  label,
  filename = 'UPLOAD FILE',
  disabled = false,
}: FileDropZoneProps): JSX.Element => {
  const classes = useStyles();

  return (
    <DropZoneField
      name={name}
      control={control}
      icon={InsertDriveFileIcon}
      label={label}
      disabled={disabled}
    >
      <div className={classes.contentWrap}>
        <Typography color="inherit">{filename}</Typography>
      </div>
    </DropZoneField>
  );
};

type FileDropZoneProps = {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  filename?: string;
  disabled?: boolean;
};

export default FileDropZone;
