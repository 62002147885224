import pick from 'lodash/pick';
import { TDeviceDetails } from './interface';
import omit from 'lodash/omit';
import { SupportedApps } from '../../../types/device';

export const prepareFormValues = (
  values: TDeviceDetails,
  toPublish: boolean,
): FormData => {
  const deviceFields = pick(
    values,
    'modelName',
    'productDescription',
    'deviceDocumentation',
    'deviceInfo',
    'supportedApps',
    'connectionType',
    'nfcActivationValue',
    'bleName',
    'showCustomFirmware',
    'productModelName',
  );

  const omittedDeviceFields = omit(deviceFields, [
    'connectionType',
    'bleName',
    'nfcActivationValue',
  ]);

  const imageId = window.crypto.randomUUID();

  const preparedForEditDeviceFields = deviceFields.supportedApps?.includes(
    SupportedApps.MOBILE,
  )
    ? {
        ...omittedDeviceFields,
        imageFieldNameId: imageId,
        connectionType: {
          ...(values.connectionType === 'NFC'
            ? {
                bleName: deviceFields.bleName,
                nfcActivationValue: deviceFields.nfcActivationValue,
              }
            : {}),
          mode: deviceFields.connectionType,
        },
      }
    : {
        ...omittedDeviceFields,
      };

  const jsonData = {
    ...(!preparedForEditDeviceFields.productModelName
      ? omit(preparedForEditDeviceFields, 'productModelName')
      : preparedForEditDeviceFields),
    toPublish,
  };
  const imgExtension =
    values.image && typeof values.image !== 'string'
      ? values.image.name.split('.').pop()
      : '';
  const formData = new FormData();

  formData.set('jsonData', JSON.stringify(jsonData));
  if (values.image instanceof Blob) {
    formData.set(
      imageId,
      values.image,
      `${values.modelId}_image.${imgExtension}`,
    );
  }

  return formData;
};
