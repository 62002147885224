import React from 'react';
// import { Grid } from '@material-ui/core';
import { Control } from 'react-hook-form';
import TextFormField from '../../formElements/TextFormField';

const SpecificationVersionFields = ({ control }: TProps): JSX.Element => {
  return (
    // <Grid container spacing={3}>
    //   <Grid item xs={6}>
    <TextFormField
      name="atVersion"
      placeholder="AT Command Version"
      label="AT Command Version"
      control={control}
    />
    //   </Grid>
    //   <Grid item xs={6}>
    //     <TextFormField
    //       name="aliasName"
    //       placeholder="AT alias name"
    //       label="AT Alias Name"
    //       control={control}
    //     />
    //   </Grid>
    // </Grid>
  );
};

type TProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
};

export default SpecificationVersionFields;
