import React, { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, ButtonGroup, Divider } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import DeviceForm from '../../../components/forms/DeviceFields';
import FirmawareDetailsFields from '../../../components/forms/FirmawareDetailsFields';

import { getRequestStatus } from '../../../redux/api/selectors';
import types from '../../../redux/deviceDetails/types';
import { RequestStatus } from '../../../redux/api/interface';

import CustomModal from '../CustomModal';
import { prepareFormValues } from './utils';
import { createDevice } from '../../../redux/deviceDetails/actions';
import { AddDeviceModalFormValues } from './interface';
import { getValidationShema } from '../../../utils/forms/validation';
import styles from './styles';
import { getDevicesList } from '../../../redux/devices/selectors';

const useStyles = makeStyles(styles);

const AddDeviceModal = ({ handleClose }: AddDeviceModalProps): JSX.Element => {
  const [isPublish, setIsPublish] = useState<boolean | null>(null);
  const [showError, setShowError] = useState(false);

  const deviceList = useSelector(getDevicesList);

  const classes = useStyles();

  const dispatch = useDispatch();

  const { control, handleSubmit, setValue, watch } =
    useForm<AddDeviceModalFormValues>({
      resolver: yupResolver(getValidationShema('both', isPublish)),
      mode: 'onSubmit',
      defaultValues: {
        connectionType: 'BLE',
        showCustomFirmware: true,
        isSensorSpecificationRequired: false,
      },
    });

  const { modelId, supportedApps, connectionType } = watch();

  useEffect(() => {
    if (modelId) {
      setValue('modelId', modelId.toLowerCase());
    }
  }, [modelId]);

  const handleSubmitForm = useRef(handleSubmit);
  handleSubmitForm.current = handleSubmit;

  const isSumbiting =
    useSelector(getRequestStatus(types.CREATE_DEVICE))?.status ===
    RequestStatus.loading;

  const submit = (toPublish: boolean) => (values: AddDeviceModalFormValues) => {
    if (values.hardwareModelName || values.productModelName) {
      const namesList = deviceList.map((device) => {
        if (!device.productModelName) return true;
        return (
          device.hardwareModelName + device.productModelName
        ).toLowerCase();
      });

      const currentCombination =
        values.hardwareModelName + values.productModelName;
      if (namesList.includes(currentCombination?.toLowerCase())) {
        setShowError(true);
        return;
      }
    }

    const requestBody = prepareFormValues(values, toPublish);
    setShowError(false);
    dispatch(createDevice({ data: requestBody }))
      .promise?.then(handleClose)
      .catch((response) => {
        toast.error(
          <div>
            {response.errors?.map(
              (error: Record<'errorMessage' | 'errorCode', string>) => (
                <div>{error.errorMessage}</div>
              ),
            )}
          </div>,
          {
            position: 'bottom-center',
            toastId: response.type,
            className: 'toast-lg',
          },
        );
      });
  };

  const submitDraft = submit(false);
  const submitPublish = submit(true);

  return (
    <CustomModal
      headerText="Add New Device"
      buttonsBlock={
        <ButtonGroup variant="contained">
          <Button
            disabled={isSumbiting}
            onClick={() => {
              setIsPublish(true);
              setTimeout(handleSubmitForm.current(submitPublish));
            }}
          >
            Save and publish
          </Button>
          <Button
            disabled={isSumbiting}
            onClick={() => {
              setIsPublish(false);
              setTimeout(handleSubmitForm.current(submitDraft));
            }}
          >
            Save draft
          </Button>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <DeviceForm
        connectionType={connectionType}
        setValue={setValue}
        control={control}
        supportedApps={supportedApps}
        isNotUniqueCombination={showError}
      />

      <div className={classes.divider}>
        <Divider />
      </div>

      <FirmawareDetailsFields
        control={control}
        prefix="firmware"
        withSectionLabel
        isAddDeviceModal
      />
    </CustomModal>
  );
};

type AddDeviceModalProps = {
  handleClose: () => unknown;
};

export default AddDeviceModal;
