/* eslint-disable @typescript-eslint/no-explicit-any */
import { ShownOnType, TCreateSectionData } from '../../../types/spec';
import * as yup from 'yup';
import {
  patternForLatinCharacters,
  requiredField,
} from '../../../utils/forms/constants';

export const sectionFieldsValidationSchema = yup.object().shape({
  setDependency: yup.string(),
  setConditionalDependency: yup.string(),
  hint: yup
    .string()
    .required(requiredField)
    .min(1)
    .max(126)
    .matches(patternForLatinCharacters),
  title: yup.string().required(requiredField),
  position: yup.string().required(requiredField),
  shownOn: yup.array().required('At least one parameter should be selected!'),
});

const prepareDependency = (value: string[] | undefined): string[] => {
  return value && typeof value === 'string' ? [value] : [];
};

export const prepareShownOn = (value: string[]): ShownOnType[] => {
  return value?.map((elem) => {
    return ShownOnType[elem as keyof typeof ShownOnType];
  });
};

export const prepareValues = (
  values: TCreateSectionData,
): TCreateSectionData => {
  return {
    shownOn: prepareShownOn(values.shownOn),
    identifier: `device_info_${values.title}`,
    isShowTemplate: values.isShowTemplate ?? false,
    dependOn: prepareDependency(values.dependOn),
    conditionalDependency: values.conditionalDependency,
    position: values.position ?? 0,
    title: values.title ?? '',
    hint: values.hint ?? '',
  };
};
