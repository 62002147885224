import Cookie from './Cookies';

const getCookieDomain = (): string => {
  const [topLevel, root] = window.location.hostname.split('.').reverse();
  return `.${root}.${topLevel}`;
};

class AuthCookie extends Cookie {
  constructor() {
    super(
      process.env.NODE_ENV === 'development' ? 'localhost' : getCookieDomain(),
    );
  }
}

const authCookie = new AuthCookie();

export default authCookie;
