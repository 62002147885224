/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { Method } from 'axios';

export type RequestType = {
  method: Method;
  url: string;
  params?: { [key: string]: any };
  data?: { [key: string]: any };
};

export type DispatchTypes = {
  START: string;
  SUCCESS: string;
  ERROR: string;
};

export type ResponceType<T> = {
  data: T;
  status: number;
};

export type AsyncAction<Responce> = Action & {
  payload: {
    request: RequestType;
    dispatch: DispatchTypes;
  };
  promise?: Promise<PayloadAction<Responce>>;
};

export enum RequestStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export type ApiErrorType = { message: string; code: string };
export type ApiErrorsType = { errorCode: string; errorMessage: string };

export type ApiResponseErrorType = {
  error: ApiErrorType;
  errors?: ApiErrorsType;
};

export type ApiRequestsInitialStateType = {
  [key: string]: {
    status: RequestStatus;
    error?: ApiErrorType;
    errors?: ApiErrorsType[];
  };
};
