import React from 'react';
import Section from '../../ui/Section';
import { useForm } from 'react-hook-form';
import { UiElement } from '../../../types/spec';
import { UiElementValidationSettings } from '../../forms/AtCommand/components/UiElementSection/UiElementValidationSettings';
import { UiElementSettings } from '../../forms/AtCommand/components/UiElementSection/UiElementSettings';
import DisplayDetailsSection from '../../forms/AtCommand/components/DisplayDetailsSection';
import Divider from '../../../components/ui/Divider/Divider';

const ListOfUiElementsTemplate = ({
  uiElement,
  handleDeleteUIElement,
}: {
  uiElement: UiElement;
  handleDeleteUIElement: (id: number) => void;
}): JSX.Element => {
  const { control } = useForm<UiElement>({
    mode: 'onChange',
    defaultValues: uiElement,
  });
  return (
    <>
      <Section
        headerText={`${uiElement.title} settings`}
        initialIsOpen={false}
        sectionVariant="light"
        withDelete
        deleteCallback={() => handleDeleteUIElement(uiElement.id)}
      >
        <UiElementSettings control={control} onlyView={false} withTooltips />
        <UiElementValidationSettings
          control={control}
          onlyView={false}
          withTooltips
        />
      </Section>
      <DisplayDetailsSection
        headerText="Ui element display Details"
        control={control}
      />
      <Divider />
    </>
  );
};

export default ListOfUiElementsTemplate;
