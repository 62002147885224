import React from 'react';

import Section from '../../../components/ui/Section';
import { Button, styled, TextField, Typography } from '@material-ui/core';
import { Control, useController } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';

export enum DESCRIPTION_TYPES {
  addedDescription = 'addedDescription',
  fixedDescription = 'fixedDescription',
  changedDescription = 'changedDescription',
}

const StyledButton = styled(Button)(() => ({
  background: 'rgba(21, 84, 247, 0.7)',
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.24)',
  width: '253px',
  height: '40px',
  color: 'white',
  '&:hover': {
    background: 'rgba(21, 84, 247, 0.5)',
  },
  marginTop: '10px',
}));

const FieldWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));

const InputWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'flex-start',
}));

const Field = styled(TextField)(() => ({
  width: '100%',
  marginBottom: '10px',
}));

const titles = {
  [DESCRIPTION_TYPES.addedDescription]: 'ADDED',
  [DESCRIPTION_TYPES.changedDescription]: 'CHANGED',
  [DESCRIPTION_TYPES.fixedDescription]: 'FIXED',
};

type TProps = {
  onlyView?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control?: Control<any, any>;
  isAddDeviceModal?: boolean;
};

const deleteItemByIndex = (index: number, values: string[]) => {
  return values.filter((_, idx) => idx !== index);
};

const insertElementByIndex = (
  index: number,
  value: string,
  values: string[],
) => {
  return values.map((item, indx) => (indx === index ? value : item));
};

const FirmwareDescription = ({
  onlyView,
  control,
  isAddDeviceModal = false,
}: TProps): JSX.Element => {
  const {
    field: { onChange: addedDescriptionChange, value: addedDescription },
  } = useController({
    name: isAddDeviceModal ? 'firmware.addedDescription' : 'addedDescription',
    control,
  });
  const {
    field: { onChange: fixedDescriptionChange, value: fixedDescription },
  } = useController({
    name: isAddDeviceModal ? 'firmware.fixedDescription' : 'fixedDescription',
    control,
  });
  const {
    field: { onChange: changedDescriptionChange, value: changedDescription },
  } = useController({
    name: isAddDeviceModal
      ? 'firmware.changedDescription'
      : 'changedDescription',
    control,
  });

  const state = {
    [DESCRIPTION_TYPES.addedDescription]: addedDescription ?? [],
    [DESCRIPTION_TYPES.fixedDescription]: fixedDescription ?? [],
    [DESCRIPTION_TYPES.changedDescription]: changedDescription ?? [],
  };

  const handleAddNewField = (key: DESCRIPTION_TYPES) => {
    switch (key) {
      case DESCRIPTION_TYPES.addedDescription:
        addedDescriptionChange([...(addedDescription ?? []), '']);
        break;
      case DESCRIPTION_TYPES.fixedDescription:
        fixedDescriptionChange([...(fixedDescription ?? []), '']);
        break;
      case DESCRIPTION_TYPES.changedDescription:
        changedDescriptionChange([...(changedDescription ?? []), '']);
        break;
      default:
        break;
    }
  };

  const handleDeleteFieldClick = (key: DESCRIPTION_TYPES, index: number) => {
    switch (key) {
      case DESCRIPTION_TYPES.addedDescription:
        addedDescriptionChange(deleteItemByIndex(index, addedDescription));
        break;
      case DESCRIPTION_TYPES.fixedDescription:
        fixedDescriptionChange(deleteItemByIndex(index, fixedDescription));
        break;
      case DESCRIPTION_TYPES.changedDescription:
        changedDescriptionChange(deleteItemByIndex(index, changedDescription));
        break;
      default:
        break;
    }
  };

  const onChange = (value: string, key: DESCRIPTION_TYPES, index: number) => {
    switch (key) {
      case DESCRIPTION_TYPES.addedDescription:
        addedDescriptionChange(
          insertElementByIndex(index, value, addedDescription),
        );
        break;
      case DESCRIPTION_TYPES.fixedDescription:
        fixedDescriptionChange(
          insertElementByIndex(index, value, fixedDescription),
        );
        break;
      case DESCRIPTION_TYPES.changedDescription:
        changedDescriptionChange(
          insertElementByIndex(index, value, changedDescription),
        );
        break;
      default:
        break;
    }
  };
  return (
    <Section headerText="FIRMWARE DESCRIPTION" sectionVariant="primary">
      {(Object.keys(state) as DESCRIPTION_TYPES[])?.map((key) => (
        <Section headerText={titles[key]} key={key}>
          <FieldWrapper>
            {(typeof state[key] === 'string'
              ? [state[key]]
              : (state[key] as string[])
            ).map((value, index) => (
              <InputWrapper key={`${index}`}>
                <div style={{ width: '100%' }}>
                  <Field
                    disabled={onlyView}
                    onChange={(event) =>
                      onChange(event.target.value, key, index)
                    }
                    variant="outlined"
                    size="small"
                    value={value}
                    InputLabelProps={{
                      shrink: true,
                      style: { paddingLeft: 0, paddingRight: 0 },
                    }}
                  />
                  {value.length > 128 && (
                    <Typography color="error">
                      Max field length is 128 characters
                    </Typography>
                  )}
                </div>

                {!onlyView && (
                  <Button
                    style={{ height: '40px' }}
                    variant="text"
                    size="small"
                    color="inherit"
                    onClick={() => handleDeleteFieldClick(key, index)}
                  >
                    <DeleteIcon fontSize="medium" />
                  </Button>
                )}
              </InputWrapper>
            ))}

            {state[key]?.length < 5 && !onlyView && (
              <StyledButton
                variant="contained"
                onClick={() => handleAddNewField(key as DESCRIPTION_TYPES)}
              >
                ADD NEW FIELD
              </StyledButton>
            )}
          </FieldWrapper>
        </Section>
      ))}
    </Section>
  );
};

export default FirmwareDescription;
