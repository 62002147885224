import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styles from './styles';

const useStyles = makeStyles(styles);

const CustomModal = ({
  headerText,
  buttonsBlock,
  children,
}: CustomModalProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Modal disableEnforceFocus open disableScrollLock>
      <Box className={classes.box}>
        <div className={classes.modalHeader}>
          <Typography variant="h5">{headerText}</Typography>
        </div>
        {children && <div className={classes.contentWrap}>{children}</div>}
        {buttonsBlock && (
          <div className={classes.buttonBlock}>{buttonsBlock}</div>
        )}
      </Box>
    </Modal>
  );
};

type CustomModalProps = {
  headerText: string;
  children?: React.ReactNode;
  buttonsBlock?: JSX.Element;
};

CustomModal.defaultProps = {
  buttonsBlock: undefined,
  children: undefined,
};

export default CustomModal;
