import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    wrap: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '7px',
    },
    item: {
      padding: '2px 8px',
      borderRadius: 4,
      backgroundColor: '#F4F5F6',
      color: '#353A3B',
      fontSize: 14,
      textTransform: 'uppercase',
    },
  };
}
