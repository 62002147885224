import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    wrap: {
      width: '100%',
    },
    label: {
      whiteSpace: 'nowrap' as const,
    },
    error: {
      color: '#ff0000',
    },
  };
}
