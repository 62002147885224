import React from 'react';

export const AppLogoIcon = (): JSX.Element => {
  return (
    <svg
      width="132"
      height="24"
      viewBox="0 0 132 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M42.9231 0H0V24H42.9231V0Z" fill="#97999B" />
      <path
        d="M2.47266 4.02051H6.69489L8.39322 11.0969C8.48758 11.5057 8.57407 11.9539 8.65269 12.4414C8.74704 12.9131 8.83353 13.3613 8.91216 13.7859C8.99079 14.2891 9.07727 14.7844 9.17163 15.2719H9.2188C9.29743 14.8316 9.38392 14.3756 9.47827 13.9038C9.5569 13.5107 9.64338 13.0783 9.73774 12.6065C9.84781 12.1347 9.95003 11.6866 10.0444 11.262L11.6248 4.02051H15.4932L17.0736 11.2148C17.1679 11.6551 17.2623 12.1112 17.3566 12.5829C17.451 13.0389 17.5375 13.4714 17.6161 13.8802C17.6947 14.352 17.7812 14.8159 17.8756 15.2719H17.9227C18.0171 14.7844 18.1114 14.2891 18.2058 13.7859C18.2844 13.3613 18.3709 12.9053 18.4653 12.4178C18.5753 11.9303 18.6776 11.4743 18.7719 11.0497L20.4467 4.02051H24.6453L20.0221 20.8859H15.9414L14.1723 12.7952C14.0936 12.465 14.0229 12.119 13.96 11.7573C13.8971 11.3799 13.8342 11.034 13.7713 10.7195C13.7084 10.3578 13.6455 10.004 13.5826 9.65801H13.5354C13.4725 10.004 13.4096 10.3578 13.3467 10.7195C13.2838 11.034 13.2209 11.3799 13.158 11.7573C13.1108 12.119 13.0479 12.465 12.9693 12.7952L11.2002 20.8859H7.09589L2.47266 4.02051Z"
        fill="white"
      />
      <path d="M24.9782 8.80885H28.823V20.8859H24.9782V8.80885Z" fill="white" />
      <path
        d="M35.2129 21.2633C33.4359 21.2633 32.0285 20.8859 30.9906 20.131C29.9528 19.3605 29.4024 18.3069 29.3395 16.9703H32.972C33.0506 17.5678 33.2787 18.016 33.6561 18.3148C34.0335 18.6136 34.5445 18.7629 35.1893 18.7629C35.7711 18.7629 36.2272 18.6607 36.5574 18.4563C36.9033 18.2519 37.0763 17.9531 37.0763 17.56C37.0763 17.2926 36.9898 17.0803 36.8169 16.9231C36.6439 16.7501 36.4159 16.6164 36.1328 16.5221C35.8497 16.412 35.5195 16.3334 35.1421 16.2862C34.7804 16.2233 34.403 16.1525 34.0099 16.0739C33.491 15.9796 32.972 15.8695 32.4531 15.7437C31.9342 15.6022 31.4624 15.3977 31.0378 15.1304C30.629 14.8631 30.2987 14.5093 30.0471 14.0689C29.7955 13.6129 29.6697 13.0311 29.6697 12.3234C29.6697 11.6944 29.8034 11.1362 30.0707 10.6487C30.3538 10.1612 30.7312 9.76023 31.2029 9.44572C31.6747 9.11549 32.2251 8.87175 32.8541 8.7145C33.4988 8.54152 34.175 8.45503 34.8826 8.45503C36.5967 8.45503 37.9098 8.80099 38.8218 9.4929C39.7339 10.1848 40.2371 11.1283 40.3315 12.3234H36.7933C36.7146 11.7731 36.5023 11.4035 36.1564 11.2148C35.8262 11.0104 35.3937 10.9082 34.8591 10.9082C34.3873 10.9082 33.9863 11.0025 33.6561 11.1912C33.3416 11.3642 33.1843 11.6394 33.1843 12.0168C33.1843 12.237 33.2629 12.4099 33.4202 12.5357C33.5774 12.6615 33.7897 12.7716 34.0571 12.866C34.3244 12.9446 34.631 13.0154 34.977 13.0783C35.3387 13.1412 35.7082 13.2041 36.0856 13.267C36.6203 13.3613 37.1549 13.4793 37.6896 13.6208C38.24 13.7466 38.7353 13.951 39.1756 14.2341C39.6317 14.5171 40.0012 14.9024 40.2843 15.3899C40.5673 15.8616 40.7089 16.4828 40.7089 17.2533C40.7089 17.9138 40.5673 18.4956 40.2843 18.9988C40.0169 19.502 39.6395 19.9187 39.1521 20.249C38.6646 20.5792 38.0827 20.8308 37.4065 21.0038C36.7304 21.1768 35.9991 21.2633 35.2129 21.2633Z"
        fill="white"
      />
      <path
        d="M24.9766 4.91602H28.8213V8.02957H24.9766V4.91602Z"
        fill="#006AC6"
      />
      <path
        d="M49.7228 7.53614H44.6406V3.87109H59.1787V7.53614H54.0476V21.3412H49.7228V7.53614Z"
        fill="#00AD5A"
      />
      <path
        d="M84.0537 3.87109H88.0852V21.3412H84.0537V3.87109Z"
        fill="#00AD5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.3316 15.2224L65.776 9.11133L58.6649 9.11133L55.1094 15.2224L58.6649 21.3335H65.776L69.3316 15.2224ZM62.2224 18.0001C63.7565 18.0001 65.0002 16.7565 65.0002 15.2223C65.0002 13.6882 63.7565 12.4446 62.2224 12.4446C60.6883 12.4446 59.4446 13.6882 59.4446 15.2223C59.4446 16.7565 60.6883 18.0001 62.2224 18.0001Z"
        fill="#00AD5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M83.3316 15.2224L79.776 9.11133H72.6649L69.1094 15.2224L72.6649 21.3335H79.776L83.3316 15.2224ZM76.2223 18.0001C77.7564 18.0001 79.0001 16.7565 79.0001 15.2223C79.0001 13.6882 77.7564 12.4446 76.2223 12.4446C74.6882 12.4446 73.4445 13.6882 73.4445 15.2223C73.4445 16.7565 74.6882 18.0001 76.2223 18.0001Z"
        fill="#00AD5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120 15.2224L116.444 9.11133H109.333L105.777 15.2224L109.333 21.3335H116.444L120 15.2224ZM112.892 18.0001C114.426 18.0001 115.67 16.7564 115.67 15.2223C115.67 13.6882 114.426 12.4445 112.892 12.4445C111.358 12.4445 110.114 13.6882 110.114 15.2223C110.114 16.7564 111.358 18.0001 112.892 18.0001Z"
        fill="#00AD5A"
      />
      <path
        d="M90.2656 3.87109H98.6953C99.6075 3.87109 100.397 3.97697 101.065 4.18873C101.733 4.40049 102.303 4.6937 102.776 5.06835C103.786 5.89909 104.291 7.0149 104.291 8.41577C104.291 9.29539 104.079 10.0121 103.655 10.5659C103.232 11.1198 102.629 11.5433 101.847 11.8365V11.9098C102.808 12.203 103.566 12.7324 104.12 13.498C104.69 14.2473 104.975 15.1432 104.975 16.1857C104.975 17.0653 104.82 17.8309 104.51 18.4825C104.201 19.1177 103.761 19.6553 103.191 20.0951C102.132 20.9258 100.699 21.3412 98.8907 21.3412H90.2656V3.87109ZM98.4021 17.8716C99.1188 17.8716 99.6808 17.6843 100.088 17.3097C100.495 16.935 100.699 16.4382 100.699 15.8192C100.699 15.1188 100.471 14.5975 100.015 14.2554C99.5749 13.8971 99.021 13.7179 98.3532 13.7179H94.5415V17.8716H98.4021ZM98.06 10.7858C98.679 10.7858 99.1839 10.6392 99.5749 10.346C99.9658 10.0365 100.161 9.59673 100.161 9.02661C100.161 8.47278 99.974 8.04926 99.5993 7.75606C99.2247 7.44656 98.7197 7.29182 98.0844 7.29182H94.5415V10.7858H98.06Z"
        fill="#00AD5A"
      />
      <path
        d="M122.962 14.7685L119.029 8.83114H123.573L125.43 12.0564H125.479L127.238 8.83114H131.417L127.703 14.7197L131.954 21.3412H127.336L125.284 17.6029H125.235L122.962 21.3412H118.613L122.962 14.7685Z"
        fill="#00AD5A"
      />
    </svg>
  );
};
