import { StylesObjectType } from '../../types/types';

export default function styles(): StylesObjectType {
  return {
    wrap: {
      padding: '32px 36px',
      boxSizing: 'border-box',
    },
  };
}
