import React from 'react';
import { Button, makeStyles } from '@material-ui/core';

import useModal from '../../../hooks/useModal';
import styles from './styles';

const useStyles = makeStyles(styles);

const AddEntityBlock = ({
  buttonText,
  modalElement: Modal,
  modalProps,
  buttonVariant = 'primary',
}: AddEntityBlockProps): JSX.Element => {
  const { isModalOpen, toggleModal } = useModal();
  const classes = useStyles();

  return (
    <div className={classes.buttonToAddDeviceWrapper}>
      <Button
        color="primary"
        variant="contained"
        className={`${classes.buttonToAddDevice} ${classes.buttonToAddDevice}--${buttonVariant}`}
        size="large"
        onClick={() => toggleModal(true)}
      >
        <div className={classes.buttonText}>{buttonText}</div>
      </Button>
      {isModalOpen && (
        <Modal
          handleClose={() => toggleModal(false)}
          {...Object.assign({}, modalProps)}
        />
      )}
    </div>
  );
};

type AddEntityBlockProps = {
  buttonText: React.ReactNode;
  modalElement: React.ElementType;
  modalProps?: unknown;
  buttonVariant?: 'primary' | 'secondary';
};

AddEntityBlock.defaultProps = {
  modalProps: undefined,
};

export default AddEntityBlock;
