import React from 'react';

import { Grid } from '@mui/material';
import TextFormField from '../../../../formElements/TextFormField';
import { AtCommandSectionsGEneralProps } from '../../types';
import { getTooltipText } from '../ReadAtCommandSection/utils';

export const WriteAtCommandSection = ({
  control,
  onlyView,
  withTooltips,
}: AtCommandSectionsGEneralProps): JSX.Element => {
  const { writeCommand, writeSeparator } = getTooltipText(!!withTooltips);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextFormField
            disabled={onlyView}
            name="writeCommand"
            label="Write AT Command"
            control={control}
            tooltipText={writeCommand}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFormField
            disabled={onlyView}
            name="writeSeparator"
            label="Write Command Separator"
            control={control}
            tooltipText={writeSeparator}
          />
        </Grid>
      </Grid>
    </div>
  );
};
