export const getTooltipText = (
  withTooltips: boolean,
): {
  readCommand: string;
  identifier: string;
  separator: string;
  writeCommand: string;
  writeSeparator: string;
} => {
  const toolTips = {
    readCommand: 'Set AT command for Read',
    identifier: 'Set return value identifier',
    separator: 'Specify separator for return values from Read command.',
    writeSeparator: 'Specify separator for return values from Write command.',
    writeCommand: 'Set AT command for Write',
  };
  if (withTooltips) {
    return toolTips;
  }
  return {
    readCommand: '',
    identifier: '',
    separator: '',
    writeSeparator: '',
    writeCommand: '',
  };
};
