import Cookies from 'js-cookie';

class Cookie {
  cookie: Cookies.CookiesStatic;

  options: Cookies.CookiesStatic['attributes'];

  constructor(domain: string) {
    const sameSite = 'Lax' as Cookies.CookiesStatic['attributes']['sameSite'];
    const options = { path: '/', secure: true, sameSite, domain };

    this.cookie = Cookies;
    this.options = options;
  }

  setCookie = (key: string, value: string): void => {
    this.cookie.set(key, value, this.options);
  };

  getCookie = (key: string): string | undefined => {
    return this.cookie.get(key);
  };

  removeCookie = (key: string): void => {
    this.cookie.remove(key, this.options);
  };
}

export default Cookie;
