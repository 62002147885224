import { SpecificationsListType } from '../../types/spec';
import { TAtVersions } from './interface';

export default {
  FETCH_SPEC_LIST_REQUEST: 'specs/FETCH_SPEC_LIST_REQUEST',
  FETCH_SENSOR_SPEC_LIST_REQUEST: 'specs/FETCH_SENSOR_SPEC_LIST_REQUEST',
  FETCH_SPEC_LIST: 'specs/FETCH_SPEC_LIST',
  FETCH_SENSOR_SPEC_LIST: 'specs/FETCH_SENSOR_SPEC_LIST',
  FETCH_AT_VERSIONS_LIST: 'at-commands/FETCH_AT_COMMANDS_LIST',
  FETCH_SENSOR_SPEC_VERSIONS_LIST:
    'at-commands/FETCH_SENSOR_SPEC_COMMANDS_LIST',
};

export enum sortByTypes {
  atVersion = 'SPECIFICATION',
  createdAt = 'DATE_CREATED',
  modifiedDate = 'DATE_EDITED',
}

export type TInitialState = {
  limit: number;
  page: number;
  totalItems: number;
  nextToken: string | null;
  prevTokens: string[];
  specifications: SpecificationsListType[];
  sortBy: sortByTypes;
  sortOrder: string;
  atVersions: TAtVersions[];
  sensorSpecVersions: TAtVersions[];
};
