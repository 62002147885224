import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import {
  deleteDevice,
  deleteFirmware,
  fetchDeviceDetails,
} from '../../redux/deviceDetails/actions';
import {
  getDeviceDetails,
  getDeviceFirmwares,
  getFirmwareToShow,
  getModelIdOfDeviceWithFirmwareToShow,
} from '../../redux/deviceDetails/selectors';
import { fetchDeviceList } from '../../redux/devices/actions';
import { getDevicesList } from '../../redux/devices/selectors';
import { DeviceShortType, FirmwareType } from '../../types/device';
import { RequestStatus } from '../../redux/api/interface';
import { getMultiRequestStatus } from '../../redux/api/selectors';
import {
  fetchAtVersionList,
  fetchSensorSpecVersionList,
} from '../../redux/specs/actions';
import deviceDetailsTypes from '../../redux/deviceDetails/types';
import Loader from '../../components/ui/Loader';
import ListOfDevices from './components/ListOfDevices';
import DeviceDetails from './components/DeviceDetails';
import { SpecificationType } from '../../types/spec';
import styles from './styles';
import { clearDeviceDetails } from '../../redux/deviceDetails/slice';
import { useUpdateSessionStatus } from '../../hooks/useUpdateSessionStatus';

const useStyles = makeStyles(styles);

const Home = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const firmwareToShow = useSelector(getFirmwareToShow);
  const modelIdOfDeviceWithFirmwareToShow = useSelector(
    getModelIdOfDeviceWithFirmwareToShow,
  );
  const deviceData = useSelector(getDeviceDetails);
  const firmwares = useSelector(getDeviceFirmwares);
  const devices = useSelector(getDevicesList);
  const isLoading = useSelector(
    getMultiRequestStatus(
      [
        deviceDetailsTypes.CREATE_DEVICE,
        deviceDetailsTypes.DELETE_DEVICE,
        deviceDetailsTypes.FETCH_DEVICE,
      ],
      RequestStatus.loading,
    ),
  );

  useEffect(() => {
    dispatch(fetchDeviceList({}));
    dispatch(fetchAtVersionList({}));
    dispatch(
      fetchSensorSpecVersionList({
        params: { type: SpecificationType.SENSOR_DATA },
      }),
    );
  }, []);

  const fetchDevice = (deviceId: DeviceShortType['id']) => {
    dispatch(clearDeviceDetails());
    dispatch(
      fetchDeviceDetails({
        uriParams: { deviceId },
      }),
    );
  };

  useEffect(() => {
    const id = devices.find(
      (item) => item.modelId === modelIdOfDeviceWithFirmwareToShow,
    )?.id;
    if (id) {
      fetchDevice(id);
    }
  }, [firmwareToShow]);

  const handleDeleteDevice = (deviceId: DeviceShortType['id']) =>
    dispatch(
      deleteDevice({
        uriParams: { deviceId },
      }),
    );

  const handleDeleteFirmware = (
    firmwareId: FirmwareType['id'],
    deviceId: DeviceShortType['id'],
  ) =>
    dispatch(
      deleteFirmware({
        uriParams: { firmwareId, deviceId },
      }),
    );

  useUpdateSessionStatus();

  return (
    <div className={classes.wrap}>
      {isLoading && <Loader />}
      <ListOfDevices devices={devices} fetchDevice={fetchDevice} />
      {deviceData && (
        <DeviceDetails
          isLoading={isLoading}
          deviceData={deviceData}
          firmwares={firmwares}
          handleDeleteDevice={handleDeleteDevice}
          handleDeleteFirmware={handleDeleteFirmware}
        />
      )}
    </div>
  );
};

export default Home;
