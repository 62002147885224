/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Theme } from '@mui/material';

export default function styles(theme: Theme) {
  return {
    header: {
      width: '100%',
      '&--primary': {
        background: '#0038C8 !important',
        color: `${theme.palette.common.white} !important`,
      },
      '&--secondary': {
        background: '#264494 !important',
        color: `${theme.palette.common.white} !important`,
      },
      '&--light': {
        background: 'white',
        color: 'rgba(0, 0, 0, 0.87) !important',
      },
    },
    headerContent: {
      marginRight: 'auto',
      marginLeft: 15,
      flex: 1,
    },
    sectionContent: {
      padding: '20px',
    },
  };
}
