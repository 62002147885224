import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { GridSortModel } from '@mui/x-data-grid';
import { PayloadAction } from '@reduxjs/toolkit';

import { getSpecRequestData, getSpecsList } from '../../redux/specs/selectors';
import { sortByTypes } from '../../redux/specs/types';
import {
  clearSpecifications,
  setLimit,
  setSortParams,
} from '../../redux/specs/slice';
import { FetchSpecsListMods } from '../../redux/specs/interface';
import { fetchAtVersionList } from '../../redux/specs/actions';
import { fetchDeviceList } from '../../redux/devices/actions';
import { SORT_OPTION_SPECIFICATION } from '../ATSpec/components/ListOfSpec/config';

interface UseSpecPageArgs {
  handleFetchSpecs: (
    mode: FetchSpecsListMods,
  ) => PayloadAction<FetchSpecsListMods, string>;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSpecPage = ({ handleFetchSpecs }: UseSpecPageArgs) => {
  const dispatch = useDispatch();
  const specs = useSelector(getSpecsList);
  const { totalItems } = useSelector(getSpecRequestData);
  const [pageSizeState, setPageSize] = useState<number>(5);
  const [isSorting, setIsSorting] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState<number>(0);

  const handleSortClick = (model: GridSortModel) => {
    setIsSorting(!isSorting);
    if (model.length > 0) {
      const { field, sort } = model[0];
      if (field && sort && Object.keys(sortByTypes).includes(field)) {
        dispatch(
          setSortParams({
            sortBy: Object.entries(sortByTypes).find(
              (item) => item[0] === field,
            )?.[1],
            sortOrder: sort.toUpperCase(),
          }),
        );
      }
    }
  };

  const handleChoseSizeOfPageClick = (pageSize: number) => {
    dispatch(setLimit(pageSize));
    setPageSize(pageSize);
  };

  useEffect(() => {
    handleFetchSpecs(FetchSpecsListMods.default);
  }, [pageSizeState, isSorting]);

  useEffect(() => {
    dispatch(fetchAtVersionList({}));
    dispatch(fetchDeviceList({}));
  }, []);

  const clearData = () => {
    localStorage.removeItem(SORT_OPTION_SPECIFICATION);
    dispatch(clearSpecifications());
  };

  return {
    currentPage,
    setCurrentPage,
    handleChoseSizeOfPageClick,
    specs,
    totalItems,
    pageSizeState,
    handleSortClick,
    clearData,
  };
};
