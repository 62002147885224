import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getUserProfileReq } from '../api/getUserProfile';
import { ROUTES } from '../app/AppRoutes/routes';
import { login, logOut, setUserProfile } from '../redux/authenticate/slice';
import { IUserProfileDTO, UserRoles } from '../types/types';
import NoPermissions from '../pages/Callback/NoPermissions';
import { resetSession } from '../utils/session';
import { clearRakSession } from '../api/refreshToken';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useGetUserProfilePermissions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const checkPermissions = async (profile: IUserProfileDTO) => {
    const isUserRoleAdmin = profile.roles.includes(
      UserRoles.WisToolBoxAdminRole,
    );

    if (isUserRoleAdmin) {
      dispatch(setUserProfile(profile));
      dispatch(login(true));
    } else {
      await clearRakSession();
      dispatch(logOut());
      resetSession();
      navigate(ROUTES.ROOT, { replace: true });

      toast.error(<NoPermissions />, {
        position: 'bottom-center',
        toastId: 'permissions',
        className: 'toast-lg',
      });
    }
  };

  const checkUser = async () => {
    try {
      const response = await getUserProfileReq();
      await checkPermissions(response);

      return new Promise((resolve) => resolve('Success'));
    } catch (error) {
      return new Promise((_, reject) => reject('Error'));
    }
  };

  return { checkUser };
};
