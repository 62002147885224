import pick from 'lodash/pick';
import omit from 'lodash/omit';

import { CreateDeviceRequestBody } from '../../../redux/deviceDetails/interface';
import { AddDeviceModalFormValues } from './interface';
import { SupportedApps } from '../../../types/device';

export const prepareFormValues = (
  values: AddDeviceModalFormValues,
  toPublish: boolean,
): CreateDeviceRequestBody => {
  const deviceFields = pick(
    values,
    'modelId',
    'modelName',
    'productModelName',
    'hardwareModelName',
    'deviceInfo',
    'productDescription',
    'deviceDocumentation',
    'supportedApps',
    'connectionType',
    'nfcActivationValue',
    'bleName',
    'showCustomFirmware',
    'isSensorSpecificationRequired',
  );

  const firmwareFields = pick(
    values.firmware,
    'firmwareVersion',
    'atVersionId',
    'addedDescription',
    'changedDescription',
    'fixedDescription',
    'releaseDate',
    'sensorDataVersionId',
  );

  const omittedDeviceFields = omit(deviceFields, [
    'connectionType',
    'bleName',
    'nfcActivationValue',
  ]);
  const imageId = window.crypto.randomUUID();
  const firmwareId = window.crypto.randomUUID();

  const preparedDeviceFields = deviceFields.supportedApps?.includes(
    SupportedApps.MOBILE,
  )
    ? {
        ...omittedDeviceFields,
        imageFieldNameId: imageId,
        connectionType: {
          bleName:
            deviceFields.connectionType !== 'BLE'
              ? deviceFields.bleName
              : undefined,
          mode: deviceFields.connectionType,
          nfcActivationValue:
            deviceFields.connectionType !== 'BLE'
              ? deviceFields.nfcActivationValue
              : undefined,
        },
      }
    : {
        ...omittedDeviceFields,
      };

  const preparedFirmwareFields = {
    ...firmwareFields,
    releaseDate: new Date(
      new Date(firmwareFields.releaseDate as string).setTime(
        new Date().getTime(),
      ),
    ).toISOString(),
    isSensorSpecificationRequired: values.isSensorSpecificationRequired,
    firmwareFieldNameId: firmwareId,
  };

  const omittedFirmwareFields = omit(preparedFirmwareFields, 'atVersion');

  const jsonData = JSON.stringify({
    ...(!preparedDeviceFields.productModelName
      ? omit(preparedDeviceFields, 'productModelName')
      : preparedDeviceFields),
    imageFieldNameId: imageId,
    toPublish,
    firmwares: !Object.values(values.firmware).every((x) => x === undefined)
      ? [omittedFirmwareFields]
      : [],
  });

  const imgFile = values.image;
  const { firmwareFile } = values.firmware;
  const imgExtension = values.image?.name.split('.').pop();
  const firmwareExtension = values.firmware.firmwareFile?.name.split('.').pop();

  const formData = new FormData();
  formData.set('jsonData', jsonData);

  if (imgFile instanceof Blob) {
    formData.set(imageId, imgFile, `${values.modelId}_image.${imgExtension}`);
  }

  if (firmwareFile instanceof Blob) {
    formData.set(
      firmwareId,
      firmwareFile,
      `${values.firmware.firmwareVersion}.${firmwareExtension}`,
    );
  }

  return formData;
};
