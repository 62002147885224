import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { DeviceDetailsType } from '../../../../types/device';
import DeviceFields from '../../../../components/forms/DeviceFields';

const DeviceViewForm = ({ deviceData }: DeviceInfoForm): JSX.Element => {
  const { control, reset, setValue, watch } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      ...deviceData,
      bleName: deviceData.connectionType?.bleName,
      connectionType: deviceData.connectionType?.mode,
      nfcActivationValue: deviceData.connectionType?.nfcActivationValue,
    },
  });

  const { supportedApps } = watch();

  useEffect(() => {
    reset({
      ...deviceData,
      bleName: deviceData.connectionType?.bleName,
      connectionType: deviceData.connectionType?.mode,
      nfcActivationValue: deviceData.connectionType?.nfcActivationValue,
    });
  }, [deviceData]);

  return (
    <DeviceFields
      onlyView
      setValue={setValue}
      imageLink={deviceData.image}
      control={control}
      supportedApps={supportedApps}
      connectionType={deviceData.connectionType?.mode}
    />
  );
};

type DeviceInfoForm = {
  deviceData: DeviceDetailsType;
};

export default DeviceViewForm;
