import API_ENDPOINTS from '../../api/endpoints';
import asyncActionCreator from '../../utils/redux/asyncActionCreator';
import {
  CreateDeviceRequestBody,
  CreateFirmwareRequestBody,
  CreateFirmwareResponse,
  FetchDeviceDetailsResponse,
} from './interface';
import types from './types';

export const [fetchDeviceDetails, fetchDeviceDetailsActions] =
  asyncActionCreator<
    FetchDeviceDetailsResponse,
    undefined,
    undefined,
    Parameters<typeof API_ENDPOINTS.GET_DEVICE>[0]
  >(types.FETCH_DEVICE, 'GET', API_ENDPOINTS.GET_DEVICE);

export const [createDevice, createDeviceActions] = asyncActionCreator<
  unknown, // TODO add response type
  CreateDeviceRequestBody,
  undefined,
  undefined
>(types.CREATE_DEVICE, 'POST', API_ENDPOINTS.CREATE_DEVICE);

export const [deleteDevice, deleteDeviceActions] = asyncActionCreator<
  unknown, // TODO add response type
  undefined,
  undefined,
  Parameters<typeof API_ENDPOINTS.DELETE_DEVICE>[0]
>(types.DELETE_DEVICE, 'DELETE', API_ENDPOINTS.DELETE_DEVICE);

export const [createFirmware, createFirmwareActions] = asyncActionCreator<
  CreateFirmwareResponse,
  CreateFirmwareRequestBody,
  undefined,
  Parameters<typeof API_ENDPOINTS.CREATE_FIRMWARE>[0]
>(types.CREATE_FIRMWARE, 'POST', API_ENDPOINTS.CREATE_FIRMWARE);

export const [deleteFirmware, deleteFirmwareActions] = asyncActionCreator<
  unknown, // TODO add response type
  undefined,
  undefined,
  Parameters<typeof API_ENDPOINTS.DELETE_FIRMWARE>[0]
>(types.DELETE_FIRMWARE, 'DELETE', API_ENDPOINTS.DELETE_FIRMWARE);

export const [updateDevice, updateDeviceActions] = asyncActionCreator<
  unknown,
  CreateDeviceRequestBody,
  undefined,
  Parameters<typeof API_ENDPOINTS.UPDATE_DEVICE>[0]
>(types.UPDATE_DEVICE, 'PUT', API_ENDPOINTS.UPDATE_DEVICE);

export const [updateFirmware, updateFirmwareActions] = asyncActionCreator<
  CreateFirmwareResponse,
  CreateFirmwareRequestBody,
  undefined,
  Parameters<typeof API_ENDPOINTS.UPDATE_FIRMWARE>[0]
>(types.UPDATE_FIRMWARE, 'PUT', API_ENDPOINTS.UPDATE_FIRMWARE);
