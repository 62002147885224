import { Theme } from '@material-ui/core';
import { StylesObjectType } from '../../../types/types';

export default function styles(theme: Theme): StylesObjectType {
  return {
    wrap: {
      borderRadius: '5px',
      display: 'grid',
      alignContent: 'space-between',
    },
    item: {
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
      textDecoration: 'none',
      borderRadius: '8px',
      color: '#747474',
      '& .MuiTypography-button': {
        textTransform: 'none',
        fontSize: '13px',
        fontWeight: 500,
      },
    },
    active: {
      backgroundColor: theme.palette.common.white,
      color: '#353A3B',
    },
    title: {
      display: 'flex',
      alignItems: 'center',
    },
    logOut: {
      cursor: 'pointer',
      border: '1px solid #D4D4D4',
      borderRadius: '44px',
      width: 'fit-content',
      padding: '8px 20px',
      '& .MuiTypography-button': {
        textTransform: 'none',
        fontSize: '13px',
        fontWeight: 500,
        color: '#747474',
      },
    },
    Icon: {
      marginRight: 10,
    },
    subtitle: {
      fontSize: '15px',
      letterSpacing: '0.4px',
    },
    divider: {
      margin: '20px 0',
    },
  };
}
