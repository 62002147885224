import { all, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { fetchSpecListRequest } from '../specs/actions';
import { getSpecsState } from '../specs/selectors';
import requestSaga from '../api/sagas';
import {
  createNewAtCommandACtions,
  createNewSectionActions,
  createSpecificationActions,
  createSpecificationWithFileActions,
  deleteSpecificationActions,
  fetchSpecificationDetails,
  publishAtSpecificationActions,
} from './actions';
import { getSpecDetails } from './selectors';
import types from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* requestSpecDetailsSaga(): any {
  const { id } = yield select(getSpecDetails);
  yield put(fetchSpecificationDetails({ uriParams: { specId: id } }));
}
function* requestSpecListSaga() {
  const { limit } = yield select(getSpecsState);
  yield put(fetchSpecListRequest({ params: { limit } }));
}

export function* specificationWatcherSaga(): Generator {
  yield all([
    takeLeading(types.CREATE_SPEC, requestSaga),
    takeLeading(types.CREATE_SPEC_WITH_FILE, requestSaga),
    takeLeading(types.FETCH_SPEC_DETAILS, requestSaga),
    takeLeading(types.CREATE_NEW_SECTION, requestSaga),
    takeLeading(types.CREATE_NEW_AT_COMMAND, requestSaga),
    takeLeading(types.DELETE_SPECIFICATION, requestSaga),
    takeLeading(types.DELETE_SPECIFICATION_SECTION, requestSaga),
    takeLeading(types.DELETE_AT_COMMAND, requestSaga),
    takeLeading(types.DELETE_UI_ELEMENT, requestSaga),
    takeLeading(types.EDIT_AT_COMMAND, requestSaga),
    takeLeading(types.PUBLISH_AT_SPECIFICATION, requestSaga),
    takeLatest([createNewSectionActions.SUCCESS], requestSpecDetailsSaga),
    takeLatest(
      [
        createSpecificationActions.SUCCESS,
        publishAtSpecificationActions.SUCCESS,
        deleteSpecificationActions.SUCCESS,
        createSpecificationWithFileActions.SUCCESS,
      ],
      requestSpecListSaga,
    ),

    takeLatest([createNewAtCommandACtions.SUCCESS], requestSpecDetailsSaga),
  ]);
}
