import { ShowOnValues } from '../../../../../types/spec';

export const SHOWN_ON_VALUES_FROM_API = {
  isShownOnDeviceParameters: 'isShownOnDeviceParameters',
  isShowSensorProb: 'isShowSensorProb',
  isShowTemplate: 'isShowTemplate',
  isShownOnDeviceInfo: 'isShownOnDeviceInfo',
};

export const getValuesFromShownOn = (shownOn: string[]): string[] => {
  return shownOn?.map((item) => {
    switch (item) {
      case SHOWN_ON_VALUES_FROM_API.isShowSensorProb:
        return ShowOnValues.SENSOR_PROBE_PAGE;
      case SHOWN_ON_VALUES_FROM_API.isShowTemplate:
        return ShowOnValues.TEMPLATE_PAGE;
      case SHOWN_ON_VALUES_FROM_API.isShownOnDeviceParameters:
        return ShowOnValues.DEVICE_PARAMETERS_PAGE;
      case SHOWN_ON_VALUES_FROM_API.isShownOnDeviceInfo:
        return ShowOnValues.DEVICE_INFO_PAGE;
      default:
        return item;
    }
  });
};
