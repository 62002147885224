import { APP_COOKIES } from './/../constants/appCookie';
import { AUTH_COOKIES } from '../constants/authCookies';
import { RAK_ID_AUTH_KEYS } from '../constants/rakId';
import { appCookie, authCookie } from './cookies';

export const getSessionParams = (
  search: string,
): Record<string, string | null> => {
  const searchParams = new URLSearchParams(search);
  const state = searchParams.get(RAK_ID_AUTH_KEYS.STATE);
  const accessToken = searchParams.get(RAK_ID_AUTH_KEYS.ACCESS_TOKEN);
  const refreshToken = searchParams.get(RAK_ID_AUTH_KEYS.REFRESH_TOKEN);
  const idToken = searchParams.get(RAK_ID_AUTH_KEYS.ID_TOKEN);

  return {
    state,
    accessToken,
    refreshToken,
    idToken,
  };
};

export const saveSession = ({
  idToken,
  accessToken,
}: {
  idToken?: string;
  accessToken: string;
  refreshToken?: string;
}): void => {
  authCookie.removeCookie(AUTH_COOKIES.accessToken);

  authCookie.setCookie(AUTH_COOKIES.accessToken, accessToken);
  if (idToken) authCookie.setCookie(AUTH_COOKIES.idToken, idToken);
};

export const resetSession = (): void => {
  authCookie.removeCookie(AUTH_COOKIES.idToken);
  authCookie.removeCookie(AUTH_COOKIES.accessToken);
  authCookie.removeCookie(AUTH_COOKIES.refreshToken);
  authCookie.removeCookie(AUTH_COOKIES.isUserActive);
  appCookie.removeCookie(APP_COOKIES.SESSION_STATE);
};
