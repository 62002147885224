import { RootStateType } from '../store';
import {
  ApiErrorsType,
  ApiErrorType,
  ApiRequestsInitialStateType,
  RequestStatus,
} from './interface';

export const getApiRequestsState = (
  state: RootStateType,
): ApiRequestsInitialStateType => state.apiRequests;

export const getRequestStatus =
  (requestType: string) =>
  (
    state: RootStateType,
  ): {
    status: RequestStatus;
    error?: ApiErrorType | undefined;
    errors?: ApiErrorsType[] | undefined;
  } =>
    state.apiRequests[requestType];

export const getMultiRequestStatus =
  (requestTypes: string[], status: RequestStatus) =>
  (state: RootStateType): boolean =>
    requestTypes.some((type) => state.apiRequests[type]?.status === status);
