import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    divider: {
      margin: '30px 0',
    },
    button: {
      margin: '5px 5px',
    },
  };
}
