import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from '@mui/material';
import { Slide, ToastContainer } from 'react-toastify';

import AppRoutes from './AppRoutes';
import store from '../redux/store';
import Layout from './Layout';
import theme from '../theme';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';

const App = (): JSX.Element => (
  <Provider store={store}>
    <Router>
      <ThemeProvider theme={theme}>
        <Layout>
          <ToastContainer theme="colored" hideProgressBar transition={Slide} />
          <AppRoutes />
        </Layout>
      </ThemeProvider>
    </Router>
  </Provider>
);
export default App;
