import React from 'react';
import { makeStyles } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Sidebar from '../../components/ui/Sidebar';
import { selectIsAuthenticated } from '../../redux/authenticate/selectors';
import { AppLogoIcon } from '../../components/ui-icons/AppLogoIcon';
import styles from './styles';
import { ROUTES } from '../AppRoutes/routes';

const useStyles = makeStyles(styles);

const Layout = ({ children }: LayoutProps): JSX.Element => {
  const classes = useStyles();

  const isAuthenticated = useSelector(selectIsAuthenticated);

  return (
    <div className={classes.wrap}>
      {isAuthenticated && (
        <div className={classes.sidebar}>
          <div className={classes.logo}>
            <NavLink to={ROUTES.DEVICES}>
              <AppLogoIcon />
            </NavLink>
          </div>
          <Sidebar />
        </div>
      )}
      <div className={classes.content}>{children}</div>
    </div>
  );
};

type LayoutProps = {
  children: React.ReactNode;
};

export default Layout;
