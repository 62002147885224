import React from 'react';
import { FormControlLabel, Checkbox, Grid } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { ShowOnValues } from '../../../../../types/spec';
import Section, { SectionTypeProps } from '../../../../ui/Section';
import TooltipComponent from '../../../../../components/ui/Tooltip';
import { Typography } from '@material-ui/core';
import { getValuesFromShownOn } from './utils';

type DisplayDetailsSectionType = {
  onlyView?: boolean;
  headerText: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  withTooltips?: boolean;
} & Partial<SectionTypeProps>;

const DisplayDetailsSection = ({
  onlyView,
  headerText,
  control,
  withTooltips = true,
  ...rest
}: DisplayDetailsSectionType): JSX.Element => {
  const {
    field,
    fieldState: { error },
  } = useController({ control, name: 'shownOn' });
  const LabelValue = ({
    tooltipText,
    label,
  }: {
    tooltipText: string;
    label: string;
  }) => (
    <div style={{ position: 'relative' }}>
      {label}
      {withTooltips && (
        <div style={{ position: 'absolute', right: -15, top: -10 }}>
          <TooltipComponent title={tooltipText} placement="top" />
        </div>
      )}
    </div>
  );
  const value = (getValuesFromShownOn(field.value) as ShowOnValues[]) || [];

  const template = value.includes(ShowOnValues.TEMPLATE_PAGE);
  const deviceInfo = value.includes(ShowOnValues.DEVICE_INFO_PAGE);
  const device = value.includes(ShowOnValues.DEVICE_PARAMETERS_PAGE);
  const sensorHab = value.includes(ShowOnValues.SENSOR_PROBE_PAGE);

  const onChange = (value: ShowOnValues) => {
    const array =
      field.value && field.value.includes(value)
        ? (field.value as ShowOnValues[]).filter((item) => item !== value)
        : [...(field.value || []), value];
    field.onChange(array);
  };

  return (
    <>
      <Section headerText={headerText} {...rest}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <FormControlLabel
              label={
                <LabelValue
                  tooltipText="Define whether command is shown in Templates"
                  label="Shown in Templates"
                />
              }
              control={
                <Checkbox
                  checked={!!template}
                  onChange={() => onChange(ShowOnValues.TEMPLATE_PAGE)}
                  disabled={onlyView}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              label={
                <LabelValue
                  tooltipText="Define whether command is shown in Device Info"
                  label="Shown in Device Info"
                />
              }
              control={
                <Checkbox
                  checked={!!deviceInfo}
                  onChange={() => onChange(ShowOnValues.DEVICE_INFO_PAGE)}
                  disabled={onlyView}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              label={
                <LabelValue
                  tooltipText="Define whether command is shown in Device Parameter Sections"
                  label="Shown in Device Parameters"
                />
              }
              control={
                <Checkbox
                  checked={!!device}
                  onChange={() => onChange(ShowOnValues.DEVICE_PARAMETERS_PAGE)}
                  disabled={onlyView}
                />
              }
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              label={
                <LabelValue
                  tooltipText="Specific for Sensor Probe"
                  label="Shown in Sensor Probe Info"
                />
              }
              control={
                <Checkbox
                  checked={!!sensorHab}
                  onChange={() => onChange(ShowOnValues.SENSOR_PROBE_PAGE)}
                  disabled={onlyView}
                />
              }
            />
          </Grid>
        </Grid>
      </Section>
      {error && <Typography color="error">{error.message}</Typography>}
    </>
  );
};

export default DisplayDetailsSection;
