import { StylesObjectType } from '../../types/types';

export default function styles(): StylesObjectType {
  return {
    loginPage: {
      width: '100%',
      display: 'flex',
      height: '100vh',
      fontFamily: 'InterRegular',
    },
    title: {
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '28px',
      marginBottom: '12px',
    },

    hint: {
      color: '#A7A7A7',
      fontStyle: 'italic',
      fontSize: '13px',
      width: 344,
      marginTop: '8px',
    },
    leftSide: {
      background: '#006AC6',
      width: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoIcon: { borderRadius: '10px 0 0 10px' },
    bold: { fontWeight: 'bold' },
    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center' as const,
      width: '50%',
      padding: '56px 36px 0 28px ',
    },
    button: {
      background: '#006AC6',
      color: 'white',
      width: 344,
      marginTop: '80px',
    },
  };
}
