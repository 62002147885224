export enum DeviceStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}

export enum SupportedApps {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export type FirmwareType = {
  addedDescription: string[];
  fixedDescription: string[];
  changedDescription: string[];
  firmwareName: string;
  location: string;
  id: string;
  atVersionId?: string;
  size: number;
  firmwareVersion: string;
  releaseDate: string;
  status: DeviceStatus;
  atVersion: string;
  updatedAt: string;
  createdAt: string;
  sensorDataVersion: string;
  sensorDataVersionId: string;
};

export type DeviceDetailsType = {
  modelId: string;
  productDescription: string;
  deviceDocumentation: string;
  image: string;
  updatedAt: string;
  status: DeviceStatus;
  createdAt: string;
  deviceInfo: string;
  id: string;
  modelName: string;
  hardwareModelName: string;
  supportedApps: SupportedApps[];
  connectionType: {
    mode: string;
    bleName?: string;
    nfcActivationValue?: string;
  };
  isSensorSpecificationRequired: boolean;
};

export type DeviceDetailsResponseType = {
  modelId: string;
  productDescription: string;
  deviceDocumentation: string;
  image: string;
  updatedAt: string;
  status: DeviceStatus;
  createdAt: string;
  deviceInfo: string;
  id: string;
  modelName: string;
  hardwareModelName: string;
  supportedApps: SupportedApps[];
  connectionType: { mode: string };
  isSensorSpecificationRequired: boolean;
};

export type DeviceShortType = {
  id: string;
  latestFirmwareVersion: string;
  modelId: string;
  status: DeviceStatus;
  updatedAt: string;
  hardwareModelName: string;
  productModelName?: string;
};
