import React from 'react';

export const SpecPageIcon = ({
  color = 'white',
}: {
  color?: string;
}): JSX.Element => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_43_1700)">
        <rect x="1.5" y="1" width="13" height="15" rx="2.5" stroke={color} />
        <mask id="path-2-inside-1_43_1700" fill={color}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 9L10 5.5H6L4 9L6 12.5H10L12 9ZM8.00004 9.69996C8.36823 9.69996 8.66671 9.38656 8.66671 8.99996C8.66671 8.61336 8.36823 8.29996 8.00004 8.29996C7.63185 8.29996 7.33337 8.61336 7.33337 8.99996C7.33337 9.38656 7.63185 9.69996 8.00004 9.69996Z"
          />
        </mask>
        <path
          d="M10 5.5L10.8682 5.00386L10.5803 4.5H10V5.5ZM12 9L12.8682 9.49614L13.1518 9L12.8682 8.50386L12 9ZM6 5.5V4.5H5.41968L5.13176 5.00386L6 5.5ZM4 9L3.13176 8.50386L2.84825 9L3.13176 9.49614L4 9ZM6 12.5L5.13176 12.9961L5.41968 13.5H6V12.5ZM10 12.5V13.5H10.5803L10.8682 12.9961L10 12.5ZM9.13176 5.99614L11.1318 9.49614L12.8682 8.50386L10.8682 5.00386L9.13176 5.99614ZM6 6.5H10V4.5H6V6.5ZM4.86824 9.49614L6.86824 5.99614L5.13176 5.00386L3.13176 8.50386L4.86824 9.49614ZM6.86824 12.0039L4.86824 8.50386L3.13176 9.49614L5.13176 12.9961L6.86824 12.0039ZM10 11.5H6V13.5H10V11.5ZM11.1318 8.50386L9.13176 12.0039L10.8682 12.9961L12.8682 9.49614L11.1318 8.50386ZM7.66671 8.99996C7.66671 8.88081 7.77053 8.69996 8.00004 8.69996V10.7C8.96593 10.7 9.66671 9.8923 9.66671 8.99996H7.66671ZM8.00004 9.29996C7.77053 9.29996 7.66671 9.1191 7.66671 8.99996H9.66671C9.66671 8.10761 8.96593 7.29996 8.00004 7.29996V9.29996ZM8.33337 8.99996C8.33337 9.11911 8.22955 9.29996 8.00004 9.29996V7.29996C7.03415 7.29996 6.33337 8.10761 6.33337 8.99996H8.33337ZM8.00004 8.69996C8.22955 8.69996 8.33337 8.88081 8.33337 8.99996H6.33337C6.33337 9.8923 7.03415 10.7 8.00004 10.7V8.69996Z"
          fill={color}
          mask="url(#path-2-inside-1_43_1700)"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_1700">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
