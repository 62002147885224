import React, { Dispatch, SetStateAction } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import { SpecificationSectionType } from '../../../types/spec';

const reorder = (
  list: SpecificationSectionType[],
  startIndex: number,
  endIndex: number,
) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const DragAbleCard = ({
  state,
  setState,
  children,
}: {
  state: SpecificationSectionType[];
  setState: Dispatch<SetStateAction<SpecificationSectionType[]>>;
  children: JSX.Element;
}): JSX.Element => {
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const quotes = reorder(
      state,
      result.source.index,
      result.destination.index,
    );

    setState(quotes);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {children}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
