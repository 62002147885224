import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

import {
  deleteDeviceActions,
  deleteFirmwareActions,
  fetchDeviceDetailsActions,
} from './actions';
import {
  DeleteDeviceResponse,
  DeleteFirmwareResponse,
  DeviceDetailsInitialState,
  FetchDeviceDetailsResponse,
} from './interface';

const initialState: DeviceDetailsInitialState = {
  details: undefined,
  firmwares: [],
  firmwareToShow: '',
  modelIdOfDeviceWithFirmwareToShow: '',
};

const deviceDetailsSlice = createSlice({
  name: 'deviceDetails',
  initialState,
  reducers: {
    setFirmwareToShow(state, action) {
      const { firmware, modelId } = action.payload;
      state.firmwareToShow = firmware;
      state.modelIdOfDeviceWithFirmwareToShow = modelId;
    },
    clearDeviceDetails(state) {
      state.details = initialState.details;
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchDeviceDetailsActions.SUCCESS,
      (state, action: PayloadAction<FetchDeviceDetailsResponse>) => {
        const { firmwares } = action.payload.data;
        state.firmwares = firmwares;
        state.details = omit(
          {
            ...action.payload.data,
            connectionType: action.payload.data.connectionType,
          },
          'firmwares',
        );
      },
    );
    builder.addCase(
      deleteDeviceActions.SUCCESS,
      (state, action: PayloadAction<DeleteDeviceResponse>) => {
        const { id } = action.payload.data;

        if (id === state.details?.id) {
          state.details = undefined;
          state.firmwares = [];
        }
      },
    );
    builder.addCase(
      deleteFirmwareActions.SUCCESS,
      (state, action: PayloadAction<DeleteFirmwareResponse>) => {
        const { id } = action.payload.data;

        state.firmwares = state.firmwares.filter(
          (firmware) => firmware.id !== id,
        );
      },
    );
  },
});

export const { setFirmwareToShow, clearDeviceDetails } =
  deviceDetailsSlice.actions;

export default deviceDetailsSlice.reducer;
