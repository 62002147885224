import React from 'react';
import { FormControlLabel, Checkbox, CheckboxProps } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { Control, useController } from 'react-hook-form';
import TooltipComponent from '../../../components/ui/Tooltip';

import styles from './styles';

const useStyles = makeStyles(styles);

const SwitchField = ({
  control,
  label,
  name,
  disabled,
  tooltipText,
}: TProps): JSX.Element => {
  const classes = useStyles();

  const LabelValue = () => (
    <div style={{ position: 'relative' }}>
      {label}
      {tooltipText && (
        <div style={{ position: 'absolute', top: -10, right: -15 }}>
          <TooltipComponent title={tooltipText} placement="top" />
        </div>
      )}
    </div>
  );
  const { field } = useController({
    name,
    control,
  });

  return (
    <FormControlLabel
      className={classes.wrap}
      label={<LabelValue />}
      control={
        <Checkbox checked={field.value} {...field} disabled={disabled} />
      }
    />
  );
};

type TProps = CheckboxProps & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  label: string;
  name: string;
  tooltipText?: string;
};

export default SwitchField;
