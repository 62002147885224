import { all, put, takeLatest } from 'redux-saga/effects';
import {
  createDeviceActions,
  deleteFirmwareActions,
  updateDeviceActions,
  updateFirmwareActions,
} from '../../redux/deviceDetails/actions';
import requestSaga from '../api/sagas';
import { fetchDeviceList } from './actions';
import types from './types';

function* fetchDevicesSaga(): Generator {
  yield put(fetchDeviceList({}));
}

export function* devicesWatcherSaga(): Generator {
  yield all([
    takeLatest(types.FETCH_DEVICE_LIST, requestSaga),
    takeLatest(
      [
        createDeviceActions.SUCCESS,
        updateFirmwareActions.SUCCESS,
        updateDeviceActions.SUCCESS,
        deleteFirmwareActions.SUCCESS,
      ],
      fetchDevicesSaga,
    ),
  ]);
}
