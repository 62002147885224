import pick from 'lodash/pick';
import { SpecDetailsType, SpecificationSectionType } from '../../types/spec';
import { RootStateType } from '../store';

export const getSpecificationState = (state: RootStateType): SpecDetailsType =>
  state.specification;

export const getSpecDetails = (
  state: RootStateType,
):
  | Pick<
      SpecDetailsType,
      | 'atVersion'
      | 'aliasName'
      | 'modifiedDate'
      | 'createdAt'
      | 'sections'
      | 'id'
    >
  | undefined => {
  const specificationState = getSpecificationState(state);

  if (!specificationState.atVersion) {
    return undefined;
  }

  return pick(
    specificationState,
    'atVersion',
    'aliasName',
    'modifiedDate',
    'createdAt',
    'sections',
    'id',
  );
};
export const getSpecSections = (
  state: RootStateType,
): SpecificationSectionType[] => state.specification.sections;
// export const getSpecSections = createDraftSafeSelector(
//   getSpecificationState,
//   (counties) =>
//     Object.values(
//       counties.entities as {
//         [key: SpecificationSectionType["id"]]: SpecificationSectionType;
//       }
//     )
// );
