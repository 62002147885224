import React from 'react';

import { CircularProgress, makeStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const Loader = ({ className }: TProps): JSX.Element => {
  const classes = useStyles();

  return <CircularProgress className={`${classes.loader} ${className}`} />;
};

type TProps = {
  className?: string;
};

Loader.defaultProps = {
  className: '',
};

export default Loader;
