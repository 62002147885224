import Section from '../../../../components/ui/Section';
import React, { useMemo, useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  SpecDetailsType,
  SpecificationSectionType,
  SpecificationsListType,
} from '../../../../types/spec';
import SpecificationSection from '../SpecificationSection';
import HardwareModelItems from '../../../../components/ui/HardwareModelItems';
import ModalWithHint from '../../../../components/modals/ModalWithHint';
import Divider from '../../../../components/ui/Divider/Divider';
import { styled, Button } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  deleteSpecification,
  publishAtSpecification,
} from '../../../../redux/specification/actions';
import { DeviceStatus } from '../../../../types/device';
import { FirmwareFileIcon } from '../../../../components/ui-icons/FirmwareFileIcon';

const StyledButton = styled(Button)(() => ({
  color: 'white',
  background:
    'linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #033BFF',
  borderRadius: '48px',
  width: '236px',
}));

const HeaderWrapper = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between',
}));

const StyledDiv = styled('div')(() => ({ fontSize: '15px' }));

const SpecificationDetails = React.memo(
  ({ data, sections, supportedDevices, specs }: TProps) => {
    const dispatch = useDispatch();
    const [isConfirmPublishModalOpen, setIsConfirmPublishModalOpen] =
      useState<boolean>(false);
    const [isModalToDeleteSpecOpen, setIsModalToDeleteOpen] =
      useState<boolean>(false);
    const [notAbleDeleteSpec, setNotAbleDeleteSpec] = useState<boolean>(false);
    const handleDeleteSpecClick = () => {
      setIsModalToDeleteOpen(true);
    };

    const handleCloseModal = () => {
      setIsModalToDeleteOpen(false);
      setNotAbleDeleteSpec(false);
    };

    const isPublished = useMemo(() => {
      return specs?.find(
        (item) => item.id === data.id && item.status === DeviceStatus.PUBLISHED,
      );
    }, [specs, data]);

    const handleSubmitDelete = () => {
      if (isPublished && supportedDevices.length > 0) {
        setNotAbleDeleteSpec(true);
      } else {
        setIsModalToDeleteOpen(false);
        dispatch(deleteSpecification({ uriParams: { specId: data.id } }));
      }
    };

    let headerText = data.atVersion;

    if (data.aliasName) {
      headerText += ` ${data.aliasName}`;
    }

    const deleteText = notAbleDeleteSpec ? (
      <StyledDiv>
        <Divider />
        You cannot delete{' '}
        <span className="bold-text">Published Specification</span> with linked{' '}
        <span className="bold-text">Supported Devices</span> and{' '}
        <span className="bold-text">Firmwares</span>
      </StyledDiv>
    ) : (
      <StyledDiv>
        <Divider />
        Are you sure you want to delete{' '}
        <span className="bold-text">
          {isPublished ? DeviceStatus.PUBLISHED : DeviceStatus.DRAFT}
        </span>{' '}
        AT Specification <span className="bold-text ">{headerText}</span>
      </StyledDiv>
    );

    const handleConfirmPublish = () => {
      dispatch(
        publishAtSpecification({
          uriParams: { specId: data.id },
        }),
      );

      setIsConfirmPublishModalOpen(false);
    };
    const handleCloseConfirmPublishModal = () => {
      setIsConfirmPublishModalOpen(false);
    };

    const confirmPublishText = (
      <div>
        <Divider />
        <div style={{ margin: '36px 0', fontSize: '16px' }}>
          Are you sure to{' '}
          <span className="bold-text">Publish {headerText}</span> for{' '}
          <span className="bold-text">{supportedDevices.join(',')}</span>?.
        </div>
      </div>
    );

    const handlePublishButtonClick = () => {
      setIsConfirmPublishModalOpen(true);
    };

    const titleText =
      headerText.length > 25 ? `${headerText.slice(0, 25)}...` : headerText;

    return (
      <>
        {isConfirmPublishModalOpen && (
          <ModalWithHint
            headerText="Publish at specification"
            subtitle={confirmPublishText}
            buttonsProps={{
              leftButton: {
                children: 'PUBLISH',
                onClick: handleConfirmPublish,
              },
              rightButton: {
                children: 'CANCEL',
                onClick: handleCloseConfirmPublishModal,
              },
            }}
          />
        )}
        {isModalToDeleteSpecOpen && (
          <ModalWithHint
            singleButton={notAbleDeleteSpec}
            headerText="delete At specification"
            subtitle={deleteText}
            buttonsProps={{
              leftButton: { children: 'Delete', onClick: handleSubmitDelete },
              rightButton: { children: 'CANCEL', onClick: handleCloseModal },
            }}
          />
        )}
        <Section
          headerText={`V. ${titleText}`}
          headerContent={
            <HeaderWrapper>
              <HardwareModelItems items={supportedDevices} />
              {!isPublished && (
                <div>
                  <StyledButton onClick={handlePublishButtonClick}>
                    <FirmwareFileIcon /> PUBLISH
                  </StyledButton>
                </div>
              )}
            </HeaderWrapper>
          }
          sectionVariant="primary"
          initialIsOpen
          withHide
          withDelete
          deleteCallback={handleDeleteSpecClick}
        >
          {sections.map((section, index) => (
            <Draggable draggableId={section.id} index={index} key={section.id}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <SpecificationSection section={section} data={data} />
                </div>
              )}
            </Draggable>
          ))}
        </Section>
      </>
    );
  },
);

type TProps = {
  data: Omit<SpecDetailsType, 'sections'>;
  sections: SpecificationSectionType[];
  supportedDevices: string[];
  specs: SpecificationsListType[] | null;
};
export default SpecificationDetails;
