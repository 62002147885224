import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { fetchSpecificationDetails } from '../../redux/specification/actions';
import {
  getSpecDetails,
  getSpecSections,
} from '../../redux/specification/selectors';
import { fetchSpecList } from '../../redux/specs/actions';
import { FetchSpecsListMods } from '../../redux/specs/interface';
import {
  SpecificationSectionType,
  SpecificationsListType,
} from '../../types/spec';
import AddEntityBlock from '../../components/ui/AddEntityBlock';
import CreateNewSectionsModal from '../../components/modals/CreateNewSectionsModal';
import ListOfSpec from './components/ListOfSpec';
import SpecificationDetails from './components/SpecificationDetails';
import { DragAbleCard } from '../../components/ui/DragableCard/DragAbleCard';
import { useSpecPage } from '../SensorSpec/useSpecPage';
import styles from './styles';
import { useUpdateSessionStatus } from '../../hooks/useUpdateSessionStatus';

const useStyles = makeStyles(styles);

const ATSpec = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const specDetails = useSelector(getSpecDetails);
  const specSections = useSelector(getSpecSections);
  const [specSupportedDevices, setSpecSupportedDevices] = useState<string[]>(
    [],
  );
  const [sections, setSections] =
    useState<SpecificationSectionType[]>(specSections);

  const handleFetchSpecs = (mode: FetchSpecsListMods) =>
    dispatch(fetchSpecList(mode));

  const {
    currentPage,
    setCurrentPage,
    handleChoseSizeOfPageClick,
    specs,
    totalItems,
    pageSizeState,
    handleSortClick,
    clearData,
  } = useSpecPage({ handleFetchSpecs });

  const handleOnPageChange = (page: number) => {
    if (page > currentPage) {
      handleFetchSpecs(FetchSpecsListMods.next);
    } else if (page < currentPage) {
      handleFetchSpecs(FetchSpecsListMods.prev);
    }
    setCurrentPage(page);
  };

  const handleFetchSpecDetails = (specId: SpecificationsListType['id']) => {
    dispatch(fetchSpecificationDetails({ uriParams: { specId } }));
    const selectedSpec = specs.filter((spec) => spec.id === specId)[0];
    const supportedDevices = selectedSpec.supportedDevices;
    setSpecSupportedDevices(supportedDevices.map((device) => device.modelId));
  };

  useEffect(() => {
    setSections(specSections);
  }, [specSections]);

  useEffect(() => clearData, []);

  useUpdateSessionStatus();

  return (
    <div className={classes.wrap}>
      <ListOfSpec
        handleChoseSizeOfPageClick={handleChoseSizeOfPageClick}
        specs={specs}
        handleFetchSpecDetails={handleFetchSpecDetails}
        totalItems={totalItems}
        pageSizeState={pageSizeState}
        handleOnPageChange={handleOnPageChange}
        handleSortClick={handleSortClick}
      />

      {specDetails && (
        <>
          <DragAbleCard state={sections} setState={setSections}>
            <SpecificationDetails
              data={specDetails}
              sections={sections}
              supportedDevices={specSupportedDevices}
              specs={specs}
            />
          </DragAbleCard>
          <AddEntityBlock
            buttonText="Add new section"
            modalElement={CreateNewSectionsModal}
            buttonVariant="secondary"
          />
        </>
      )}
    </div>
  );
};

export default ATSpec;
