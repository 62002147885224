import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    buttonToAddDeviceWrapper: {
      display: 'flex',
      justifyContent: 'center',
    },
    buttonToAddDevice: {
      marginTop: '30px',
      width: '300px',
      '&--primary': {
        background: '#1554F6',
      },
      '&--secondary': {
        background: '#006AC6',
      },
    },
    buttonText: {
      marginLeft: '20px',
    },
  };
}
