import { useState } from 'react';

export default function useModal(): {
  isModalOpen: boolean;
  toggleModal: (state?: boolean) => void;
} {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = (state?: boolean) =>
    setIsModalOpen(state === undefined ? (prev) => !prev : state);

  return {
    isModalOpen,
    toggleModal,
  };
}
