import React from 'react';
import { UiElementSection } from '../../../../forms/AtCommand/components/UiElementSection';
import {
  ProdSpecificationSectionsAtCommandsElement,
  UiElement,
} from '../../../../../types/spec';
import { IRemoveItemHandler } from '../../../../../pages/ATSpec/components/SpecificationSection';

type TProps = {
  atCommand: ProdSpecificationSectionsAtCommandsElement;
  data: UiElement[];
  onlyView?: boolean;
  handleDeleteClick?: ({
    name,
    title,
    item,
    uiElementId,
    atCommandId,
  }: IRemoveItemHandler) => void;
  handleEditClick: (
    atCommand: ProdSpecificationSectionsAtCommandsElement,
    tabIndex: number,
    uiElementId?: string,
  ) => void;
};

// const EmptyBlock = () => <div>s</div>;

export const ListOfUiElements = ({
  data,
  onlyView,
  handleDeleteClick,
  atCommand,
  handleEditClick,
}: TProps): JSX.Element => {
  return (
    <div>
      {data.map((element) => (
        <UiElementSection
          uiElement={element}
          onlyView={onlyView}
          key={element.id}
          handleDeleteClick={handleDeleteClick}
          atCommand={atCommand}
          handleEditClick={handleEditClick}
        />
      ))}
      {/* {onlyView && (
        <AddEntityBlock
          buttonText="Add new UiElement"
          modalElement={EmptyBlock}
        />
      )} */}
    </div>
  );
};
