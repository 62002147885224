import { createSlice } from '@reduxjs/toolkit';
import { IUserProfileDTO } from '../../types/types';
import { TAuthenticateInitialState } from './types';

const initialState: TAuthenticateInitialState = {
  isAuthenticated: !!localStorage.getItem('isAuth'),
  userProfile: null,
};

const authenticateSlice = createSlice({
  name: 'authenticate',
  initialState,
  reducers: {
    login(state, action) {
      state.isAuthenticated = action.payload;
    },
    setUserProfile(state, action: { payload: IUserProfileDTO }) {
      state.userProfile = action.payload;
    },
    logOut(state) {
      state.isAuthenticated = false;
      state.userProfile = null;
    },
  },
});

export const { login, setUserProfile, logOut } = authenticateSlice.actions;

export default authenticateSlice.reducer;
