import React from 'react';

import { Typography } from '@mui/material';

import TextFormField from '../../../../formElements/TextFormField';
import { AtCommandSectionsGEneralProps } from '../../types';
import Box from '@mui/material/Box';
import { Button } from '@material-ui/core';
import { getTooltipText } from './utils';

interface UiElementValidationSettingsProps
  extends AtCommandSectionsGEneralProps {
  handleAddNewValueClick?: () => void;
  noNewValueBtn?: boolean;
}

export const UiElementValidationSettings = ({
  control,
  onlyView,
  withTooltips,
  noNewValueBtn,
  handleAddNewValueClick,
}: UiElementValidationSettingsProps): JSX.Element => {
  const {
    min,
    max,
    exactLength,
    maxLength,
    minLength,
    pattern,
    regexp,
    displayValue,
    sendValue,
  } = getTooltipText(!!withTooltips);
  return (
    <Box
      display="grid"
      height={465}
      style={{ background: '#F0F5FF', padding: '15px', margin: '40px 0' }}
    >
      <Box
        display="grid"
        gridTemplateColumns={'47% 47%'}
        gridTemplateRows="10% 20% 20% 20% 20%"
        style={{
          justifyContent: 'space-between',
          marginBottom: '20px',
        }}
        rowGap={1}
      >
        <Box gridColumn="1 / 3" gridRow={1} style={{ marginBottom: '10px' }}>
          <Typography variant="body1">VALIDATION SETTINGS</Typography>
        </Box>

        <Box>
          <TextFormField
            disabled={onlyView}
            name="validation.min"
            label="Min Value"
            control={control}
            tooltipText={min}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="validation.max"
            label="Max Value"
            control={control}
            tooltipText={max}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="validation.minLength"
            label="Min Characters Length"
            control={control}
            tooltipText={minLength}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="validation.maxLength"
            label="Max Characters Length"
            control={control}
            tooltipText={maxLength}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="validation.exactLength"
            label="Exact Characters Length"
            control={control}
            tooltipText={exactLength}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="validation.pattern"
            label="Pattern"
            control={control}
            tooltipText={pattern}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="validation.regexp"
            label="Regular Expression"
            control={control}
            tooltipText={regexp}
          />
        </Box>
      </Box>
      <Box display="grid" gridTemplateColumns={'47% 47%'} columnGap={8}>
        <Box gridColumn="1">
          <Typography variant="body1">ALLOWED VALUES</Typography>
        </Box>
        <Box gridColumn="1">
          <TextFormField
            disabled={onlyView}
            name="allowValues.displayValue"
            label="Display value"
            control={control}
            tooltipText={displayValue}
          />
        </Box>
        <Box gridColumn="2">
          <TextFormField
            disabled={onlyView}
            name="allowValues.sendValue"
            label="Send value"
            control={control}
            tooltipText={sendValue}
          />
        </Box>
        <Box
          gridColumn="1 / 3"
          display="flex"
          justifyContent="center"
          height={40}
        >
          {!noNewValueBtn && (
            <Button
              style={{ background: '#1554F6', color: 'white', width: 253 }}
              variant="contained"
              size="large"
              onClick={handleAddNewValueClick}
            >
              ADD NEW VALUE
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
