import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { FormControlLabel, Checkbox } from '@mui/material';
import { Control, useController, UseFormSetValue } from 'react-hook-form';
import { SupportedApps } from '../../../types/device';

const SupportedAppsCheckbox = ({
  control,
  onlyView,
  setValue,
}: SupportedAppsCheckboxProps): JSX.Element => {
  const supportedAppsKey = 'supportedApps';

  const { field: supportedAppsField, fieldState: supportedAppsFieldState } =
    useController({
      name: supportedAppsKey,
      control,
    });

  useEffect(() => {
    setValue(supportedAppsKey, supportedAppsField.value || []);
  }, []);

  const handleSupportedAppUpdate = (
    app: SupportedApps,
    checked: boolean,
  ): void => {
    if (checked) {
      if (!supportedAppsField.value.includes(app)) {
        setValue(supportedAppsKey, [...supportedAppsField.value, app]);
      }
    } else if (supportedAppsField.value.includes(app)) {
      const arrayCopy = supportedAppsField.value.slice();
      arrayCopy.splice(arrayCopy.indexOf(app), 1);
      setValue(supportedAppsKey, arrayCopy);
    }
  };

  return (
    <>
      <FormControlLabel
        label="Desktop"
        control={
          <Checkbox
            disabled={onlyView}
            checked={Boolean(
              supportedAppsField.value?.includes(SupportedApps.DESKTOP),
            )}
            onChange={(e) =>
              handleSupportedAppUpdate(SupportedApps.DESKTOP, e.target.checked)
            }
          />
        }
      />
      <FormControlLabel
        label="Mobile"
        control={
          <Checkbox
            disabled={onlyView}
            checked={Boolean(
              supportedAppsField.value?.includes(SupportedApps.MOBILE),
            )}
            onChange={(e) =>
              handleSupportedAppUpdate(SupportedApps.MOBILE, e.target.checked)
            }
          />
        }
      />
      {supportedAppsFieldState.error &&
        supportedAppsField.value?.length === 0 && (
          <Typography color="error">
            {supportedAppsFieldState.error.message}
          </Typography>
        )}
    </>
  );
};

interface SupportedAppsCheckboxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  onlyView?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
}

export default SupportedAppsCheckbox;
