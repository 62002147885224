import React from 'react';

import { Grid } from '@mui/material';
import TextFormField from '../../../../../components/formElements/TextFormField';
import { AtCommandSectionsGEneralProps } from '../../types';
import DisplayDetailsSection from '../DisplayDetailsSection';
import SwitchField from '../../../../../components/formElements/SwitchField';
import { getTooltipText } from './utils';

export const GeneralSection = ({
  control,
  onlyView = false,
  withTooltips = false,
}: AtCommandSectionsGEneralProps): JSX.Element => {
  const {
    title,
    accordionTitle,
    action,
    atCommand,
    conditionalDependency,
    queue,
    dependOn,
  } = getTooltipText(withTooltips);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="title"
            label="Field Title"
            control={control}
            tooltipText={title}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="accordionTitle"
            label="Accordion Title"
            control={control}
            tooltipText={accordionTitle}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="identifier"
            label="Command Identifier"
            control={control}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="command"
            label="At Command"
            control={control}
            tooltipText={atCommand}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="dependOn"
            label="Command dependency"
            control={control}
            tooltipText={dependOn}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="conditionalDependency"
            label="Conditional command dependency"
            control={control}
            tooltipText={conditionalDependency}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SwitchField
            control={control}
            name="queue"
            label="Commands Queue"
            disabled={onlyView}
            tooltipText={queue}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="action"
            label="Action"
            control={control}
            tooltipText={action}
          />
        </Grid>

        {onlyView && (
          <Grid item xs={12}>
            <DisplayDetailsSection
              headerText="Command display Details"
              onlyView={onlyView}
              control={control}
              withTooltips={withTooltips}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
