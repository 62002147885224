import { StylesObjectType } from '../../types/types';

export default function styles(): StylesObjectType {
  return {
    wrap: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      width: '100%',
    },
    sidebar: {
      backgroundColor: '#F4F5F6F2',
      height: '100vh',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      padding: '24px 12px',
      boxSizing: 'border-box',
      minWidth: '240px',
    },
    content: {
      width: '100%',
      height: '100vh',
      overflowY: 'scroll',
    },
    logo: {
      margin: '0 0 30px 8px',
    },
  };
}
