import React from 'react';
import { Grid } from '@mui/material';
import TextFormField from '../../../components/formElements/TextFormField';
import { Control } from 'react-hook-form';
import Divider from '../../../components/ui/Divider/Divider';
import DisplayDetailsSection from '../AtCommand/components/DisplayDetailsSection';

const SpecificationSectionFields = ({
  control,
  onlyView,
}: TProps): JSX.Element => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} order={{ sm: 0 }}>
        <TextFormField
          disabled={onlyView}
          name="title"
          label="Section Title (section)"
          control={control}
        />
      </Grid>
      <Grid item xs={6} order={{ sm: 4 }}>
        <TextFormField
          disabled={onlyView}
          name="hint"
          label="Section hint"
          control={control}
        />
      </Grid>

      <Grid item xs={6} order={{ sm: 2 }}>
        <TextFormField
          disabled={onlyView}
          name="dependOn"
          label="Set dependency (depend on)"
          control={control}
        />
      </Grid>

      <Grid item xs={6} order={{ sm: 6 }}>
        <TextFormField
          disabled={onlyView}
          name="position"
          label="Position on UI"
          control={control}
          type="number"
        />
      </Grid>

      <Grid item xs={6} order={{ sm: 1 }}>
        <TextFormField
          disabled
          name="identifier"
          label="Section Identifier (identifier)"
          control={control}
        />
      </Grid>
      <Grid item xs={6} order={{ sm: 3 }}>
        <TextFormField
          disabled={onlyView}
          name="conditionalDependency"
          label="Set conditional dependency"
          control={control}
        />
      </Grid>
      <Grid item xs={12} order={{ sm: 7 }}>
        <Divider />
      </Grid>
      <Grid item xs={12} order={{ sm: 8 }}>
        <DisplayDetailsSection
          headerText="Section display Details"
          onlyView={onlyView}
          control={control}
        />
      </Grid>
    </Grid>
  );
};

type TProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  onlyView?: boolean;
};
SpecificationSectionFields.defaultProps = {
  onlyView: false,
};

export default SpecificationSectionFields;
