import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import useIsMobileResolution from '../../../hooks/useIsMobileResolution';
import MenuIcon from '@mui/icons-material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import styles from './styles';
import DropdownMenu from '../DropdownMenu';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import TooltipComponent from '../Tooltip';

const useStyles = makeStyles(styles);

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  '& .MuiAccordionDetails-root': {},
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'inherit',
  },
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Header = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
}));

const Section = ({
  headerText,
  children,
  withDelete,
  deleteCallback,
  withEdit,
  editCallback,
  initialIsOpen,
  sectionVariant = 'light',
  withHide = false,
  headerContent,
  tooltipText,
  toggleDeleteDevice,
  ...rest
}: SectionProps & AccordionProps): JSX.Element => {
  const classes = useStyles();
  const isMobile = useIsMobileResolution();
  const [isOpen, setIsOpen] = useState<boolean | undefined>(initialIsOpen);

  const getDropdownMenuItems = () => {
    const items = [];

    if (withEdit) {
      items.push(
        <MenuItem key="edit" onClick={editCallback}>
          Edit
        </MenuItem>,
      );
    }

    if (withDelete) {
      items.push(
        <MenuItem key="delete" onClick={deleteCallback}>
          Delete
        </MenuItem>,
      );
    }

    return items;
  };
  const handleExpand = () => {
    if (!withHide) {
      setIsOpen((prev) => !prev);
    }
  };

  const dropdownMenuItems = getDropdownMenuItems();

  return (
    <Accordion
      {...rest}
      onChange={handleExpand}
      expanded={isOpen}
      TransitionProps={{ unmountOnExit: true }}
    >
      <AccordionSummary
        expandIcon={
          !withHide ? (
            <ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />
          ) : null
        }
        className={`${classes.header}--${sectionVariant}`}
      >
        <Header>
          <Typography
            style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
              width: '300px',
            }}
            component="div"
          >
            {headerText}
            <div style={{ marginLeft: '8px' }}>
              {tooltipText && (
                <TooltipComponent title={tooltipText} placement="top" />
              )}
            </div>
          </Typography>
          {headerContent && (
            <div className={classes.headerContent}>{headerContent}</div>
          )}
          {isMobile && dropdownMenuItems.length ? (
            <DropdownMenu
              anchor={
                <Button variant="text" size="small" color="inherit">
                  <MenuIcon fontSize="medium" />
                </Button>
              }
              menuItems={dropdownMenuItems}
            />
          ) : (
            <div>
              {withEdit && (
                <Button
                  variant="text"
                  size="small"
                  color="inherit"
                  onClick={editCallback}
                >
                  <EditIcon fontSize="medium" />
                </Button>
              )}
              {withDelete && (
                <Button
                  variant="text"
                  size="small"
                  color="inherit"
                  onClick={
                    deleteCallback
                      ? (event: React.MouseEvent<HTMLElement>) =>
                          deleteCallback(event)
                      : toggleDeleteDevice
                  }
                >
                  <DeleteIcon fontSize="medium" />
                </Button>
              )}
            </div>
          )}
        </Header>
      </AccordionSummary>
      <AccordionDetails className={classes.sectionContent}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

type SectionProps = {
  headerText: string;
  headerContent?: React.ReactNode;
  children: React.ReactNode;
  withDelete?: boolean;
  deleteCallback?: (event: React.MouseEvent<HTMLElement>) => void;
  toggleDeleteDevice?: () => void;
  withEdit?: boolean;
  editCallback?: () => void;
  initialIsOpen?: boolean;
  sectionVariant?: 'primary' | 'secondary' | 'light';
  withHide?: boolean;
  tooltipText?: string;
};

export type SectionTypeProps = SectionProps & AccordionProps;

export default Section;
