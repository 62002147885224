import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DropdownMenu from '../../../../components/ui/DropdownMenu';
import { SupportedDevicesType } from '../../../../types/spec';
import SupportedDevice from './SupportedDevice';

import styles from './styles';

const useStyles = makeStyles(styles);

const SpecSupportedDevices = ({
  supportedDevices,
}: {
  supportedDevices: SupportedDevicesType[];
}): JSX.Element => {
  const classes = useStyles();

  const items = supportedDevices.map((device) => (
    <SupportedDevice {...device} key={device.modelId} />
  ));

  return (
    <div>
      <DropdownMenu
        anchor={
          <Button className={classes.listButton}>
            {supportedDevices.length > 0 &&
              supportedDevices[0].modelId.toLowerCase()}
            <ArrowDropDownIcon fontSize="medium" />
          </Button>
        }
        menuItems={items}
      />
    </div>
  );
};

export default SpecSupportedDevices;
