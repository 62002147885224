import { ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AUTH_COOKIES } from '../../constants/authCookies';
import { authCookie } from '../../utils/cookies';
import { ROUTES } from '../../app/AppRoutes/routes';
import { logOut } from '../../redux/authenticate/slice';
import { useGetUserProfilePermissions } from '../../hooks/useGetUserProfilePermissions';

const ProtectedRoute = ({ children }: { children: ReactNode }): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  // const isLoggedIn = useSelector(selectIsAuthenticated);
  const { checkUser } = useGetUserProfilePermissions();

  const isLoginPage = pathname === ROUTES.ROOT;

  const redirect = () => {
    const path = !isLoginPage ? pathname + search : ROUTES.DEVICES;

    navigate(path);
  };

  const checkUserProfile = () => {
    // TODO find out if everything works well in a while
    // if (isLoggedIn) {
    //   redirect();
    // } else {
    checkUser().then(() => redirect());
    // }
  };

  useEffect(() => {
    const accessToken = authCookie.getCookie(AUTH_COOKIES.accessToken);
    // it returns string 'undefined' instead of normal undefined. TODO find out what is going on
    if (!accessToken || accessToken === 'undefined') {
      dispatch(logOut());
      navigate(ROUTES.ROOT, { replace: true });
    } else {
      checkUserProfile();
    }
  }, []);

  return <>{children}</>;
};

export default ProtectedRoute;
