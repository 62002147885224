import {
  DeviceDetailsType,
  DeviceStatus,
  FirmwareType,
} from '../../types/device';
import { RootStateType } from '../store';

export const getDeviceDetails = (
  state: RootStateType,
): DeviceDetailsType & { modelId: string } =>
  Object({
    ...state.deviceDetails.details,
    modelId: state.deviceDetails.details?.modelId.toLowerCase(),
  });

export const getDeviceId = (state: RootStateType): string =>
  getDeviceDetails(state)?.id;

export const getDeviceFirmwares = (state: RootStateType): FirmwareType[] =>
  state.deviceDetails.firmwares;

export const getFirmwareToShow = (state: RootStateType): string =>
  state.deviceDetails.firmwareToShow;

export const getModelIdOfDeviceWithFirmwareToShow = (
  state: RootStateType,
): string => state.deviceDetails.modelIdOfDeviceWithFirmwareToShow;

export const getIsSensorSpecificationRequired = (
  state: RootStateType,
): boolean =>
  state.deviceDetails.details?.isSensorSpecificationRequired ?? false;

export const isDraftDevice = (state: RootStateType): boolean =>
  state.deviceDetails.details?.status === DeviceStatus.DRAFT;
