import React from 'react';
import { ReactComponent as TooltipSvg } from '../../../assets/TooltipSvg.svg';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';

const TooltipComponent = (
  props: Omit<TooltipProps, 'children'>,
): JSX.Element => {
  return (
    <Tooltip {...props}>
      <TooltipSvg />
    </Tooltip>
  );
};

export default TooltipComponent;
