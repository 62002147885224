import {
  createEntityAdapter,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { deleteDeviceActions } from '../deviceDetails/actions';
import { DeleteDeviceResponse } from '../deviceDetails/interface';
import { DeviceShortType } from '../../types/device';
import { fetchDeviceListActions } from './actions';
import { FetchDeviceListResponse } from './interface';

const devicesAdapter = createEntityAdapter<DeviceShortType>();

const initialState = devicesAdapter.getInitialState({
  modelIds: [] as string[],
  hardwareModelNames: [] as string[],
});

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      fetchDeviceListActions.SUCCESS,
      (state, action: PayloadAction<FetchDeviceListResponse>) => {
        const { data } = action.payload;
        state.modelIds = data.map((item) => item.modelId.toLowerCase());
        state.hardwareModelNames = data.map((item) => item.hardwareModelName);
        devicesAdapter.setAll(state, data);
      },
    );
    builder.addCase(
      deleteDeviceActions.SUCCESS,
      (state, action: PayloadAction<DeleteDeviceResponse>) => {
        const { id, modelId } = action.payload.data;
        state.modelIds = state.modelIds.filter((id) => id !== modelId);

        devicesAdapter.removeOne(state, id);
      },
    );
  },
});

export default devicesSlice.reducer;
