/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Control, useController } from 'react-hook-form';
import Typography from '@material-ui/core/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import TooltipComponent from '../../../components/ui/Tooltip';

const SelectField = ({
  name,
  control,
  label,
  options,
  disabled,
  defaultValue,
  tooltipText,
  shouldUnregister,
}: SelectFieldProps): JSX.Element => {
  const {
    field: { value, onChange, ...field },
    fieldState,
  } = useController({
    name,
    control,
    shouldUnregister,
  });

  const [fieldValue, setFieldValue] = useState<string>(
    () =>
      options.find(({ value: optionValue }) => optionValue === value)?.value ||
      {
        value,
        label: value,
      }.value,
  );

  return (
    <FormControl fullWidth error={!!fieldState.error}>
      <InputLabel shrink>
        {label}
        {tooltipText && (
          <span style={{ marginLeft: '3px', paddingBottom: '10px' }}>
            <TooltipComponent
              title={tooltipText}
              placement="top"
              style={{ marginBottom: '8px' }}
            />
          </span>
        )}
      </InputLabel>
      <Select
        {...field}
        defaultValue={defaultValue?.value}
        label={label}
        value={fieldValue}
        onChange={(event: SelectChangeEvent) => {
          onChange(event.target.value);
          setFieldValue(event.target.value);
        }}
        disabled={disabled}
        input={
          <OutlinedInput
            notched
            label={
              <>
                {label}
                {tooltipText && (
                  <div style={{ marginLeft: '8px' }}>
                    <TooltipComponent title={tooltipText} placement="top" />
                  </div>
                )}
              </>
            }
            size="small"
          />
        }
      >
        {options.map((option, index) => (
          <MenuItem value={option.value} key={index}>
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <Typography color="error">{fieldState.error?.message}</Typography>
    </FormControl>
  );
};

type SelectOption = { value: any; label: React.ReactNode };
type SelectFieldProps = {
  name: string;
  options: SelectOption[];
  control: Control<any, any>;
  label: string;
  disabled?: boolean;
  defaultValue?: SelectOption;
  tooltipText?: string;
  shouldUnregister?: boolean;
};

SelectField.defaultProps = {
  disabled: false,
};

export default SelectField;
