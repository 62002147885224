import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ROUTES } from './routes';

import Login from '../../pages/Login';
import Callback from '../../pages/Callback';
import Devices from '../../pages/Devices';
import ATSpec from '../../pages/ATSpec';
import SensorSpec from '../../pages/SensorSpec';

import ProtectedRoute from '../../components/ProtectedRoute';

const { CB, ROOT, DEVICES, AT_SPECIFICATION, SENSOR_SPECIFICATION } = ROUTES;

const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      <Route
        path={ROOT}
        element={
          <ProtectedRoute>
            <Login />
          </ProtectedRoute>
        }
      />
      <Route path={CB} element={<Callback />} />
      <Route
        path={DEVICES}
        element={
          <ProtectedRoute>
            <Devices />
          </ProtectedRoute>
        }
      />
      <Route
        path={AT_SPECIFICATION}
        element={
          <ProtectedRoute>
            <ATSpec />
          </ProtectedRoute>
        }
      />
      <Route
        path={SENSOR_SPECIFICATION}
        element={
          <ProtectedRoute>
            <SensorSpec />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
