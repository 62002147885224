import { styled } from '@material-ui/core';
import React, { ReactNode } from 'react';

type DataGridCellProps = {
  Icon: ReactNode;
  text: ReactNode;
};

const StyledDataGridCell = styled('div')(() => ({
  display: 'flex',
}));

const StyledIconWrapper = styled('div')({
  marginRight: 8,
  display: 'flex',
  alignItems: 'center',
});

export const DataGridCell = ({
  text,
  Icon,
}: DataGridCellProps): JSX.Element => {
  return (
    <StyledDataGridCell>
      <StyledIconWrapper>{Icon}</StyledIconWrapper>
      <div>{text}</div>
    </StyledDataGridCell>
  );
};
