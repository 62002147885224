import React from 'react';
import cn from 'classnames';
import { TextField, TextFieldProps } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { Control, RegisterOptions, useController } from 'react-hook-form';
import FieldControl from '../FieldControl';
import TooltipComponent from '../../../components/ui/Tooltip';
import styles from './styles';

const useStyles = makeStyles(styles);

const TextFormField = ({
  control,
  rules,
  name,
  label,
  multiline,
  tooltipText,
  shouldUnregister,
  textFieldClassname,
  ...props
}: TextFormFieldProps): JSX.Element => {
  const classes = useStyles();
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    shouldUnregister,
  });

  return (
    <FieldControl label={''} fieldState={fieldState}>
      <TextField
        error={fieldState.invalid}
        size="small"
        label={
          <>
            {label}
            <span style={{ marginLeft: '8px' }}>
              {tooltipText && (
                <TooltipComponent title={tooltipText} placement="top" />
              )}
            </span>
          </>
        }
        {...props}
        {...field}
        className={cn(classes.field, textFieldClassname)}
        multiline={multiline}
        minRows={4}
        maxRows={4}
        InputLabelProps={{
          shrink: true,
          style: { paddingLeft: 0, paddingRight: 0 },
        }}
      />
    </FieldControl>
  );
};

type TextFormFieldProps = TextFieldProps & {
  name: string;
  rules?: RegisterOptions;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  multiline?: boolean;
  tooltipText?: string;
  textFieldClassname?: string;
  shouldUnregister?: boolean;
};

TextFormField.defaultProps = {
  rules: {},
  multiline: false,
};

export default TextFormField;
