import API_ENDPOINTS from '../../api/endpoints';
import {
  ProdSpecificationSectionsAtCommandsElement,
  SpecDetailsType,
  TCreateSectionData,
} from '../../types/spec';
import asyncActionCreator from '../../utils/redux/asyncActionCreator';
import { CreateSpecificationBody } from './interface';
import types from './types';

export const [createSpecification, createSpecificationActions] =
  asyncActionCreator<unknown, CreateSpecificationBody>(
    types.CREATE_SPEC,
    'POST',
    API_ENDPOINTS.SPECIFICATIONS.CREATE_SPEC,
  );
export const [createSpecificationWithFile, createSpecificationWithFileActions] =
  asyncActionCreator<unknown, FormData>(
    types.CREATE_SPEC_WITH_FILE,
    'POST',
    API_ENDPOINTS.SPECIFICATIONS.CREATE_SPEC_WITH_FILE,
  );

export const [fetchSpecificationDetails, fetchSpecificationDetailsActions] =
  asyncActionCreator<
    SpecDetailsType,
    undefined,
    undefined,
    Parameters<typeof API_ENDPOINTS.SPECIFICATIONS.GET_SPEC_DETAILS>[0]
  >(
    types.FETCH_SPEC_DETAILS,
    'GET',
    API_ENDPOINTS.SPECIFICATIONS.GET_SPEC_DETAILS,
  );

export const [createNewSection, createNewSectionActions] = asyncActionCreator<
  string,
  TCreateSectionData,
  undefined,
  Parameters<typeof API_ENDPOINTS.CREATE_NEW_SECTION>[0]
>(types.CREATE_NEW_SECTION, 'POST', API_ENDPOINTS.CREATE_NEW_SECTION);

export const [createNewAtCommand, createNewAtCommandACtions] =
  asyncActionCreator<
    unknown,
    ProdSpecificationSectionsAtCommandsElement,
    undefined,
    Parameters<typeof API_ENDPOINTS.SPECIFICATIONS.CREATE_NEW_AT_COMMAND>[0]
  >(
    types.CREATE_NEW_AT_COMMAND,
    'POST',
    API_ENDPOINTS.SPECIFICATIONS.CREATE_NEW_AT_COMMAND,
  );

export const [editAtCommand, editAtCommandActions] = asyncActionCreator<
  unknown,
  ProdSpecificationSectionsAtCommandsElement,
  undefined,
  Parameters<typeof API_ENDPOINTS.SPECIFICATIONS.EDIT_AT_COMMAND>[0]
>(types.EDIT_AT_COMMAND, 'PUT', API_ENDPOINTS.SPECIFICATIONS.EDIT_AT_COMMAND);

export const [deleteSpecification, deleteSpecificationActions] =
  asyncActionCreator<
    unknown,
    undefined,
    undefined,
    Parameters<typeof API_ENDPOINTS.SPECIFICATIONS.DELETE_SPECIFICATION>[0]
  >(
    types.DELETE_SPECIFICATION,
    'DELETE',
    API_ENDPOINTS.SPECIFICATIONS.DELETE_SPECIFICATION,
  );

export const [
  deleteSectionOfSpecification,
  deleteSectionOfSpecificationActions,
] = asyncActionCreator<
  unknown,
  undefined,
  undefined,
  Parameters<
    typeof API_ENDPOINTS.SPECIFICATIONS.DELETE_SPECIFICATION_SECTION
  >[0]
>(
  types.DELETE_SPECIFICATION_SECTION,
  'DELETE',
  API_ENDPOINTS.SPECIFICATIONS.DELETE_SPECIFICATION_SECTION,
);
export const [deleteAtCommand, deleteAtCommandActions] = asyncActionCreator<
  unknown,
  undefined,
  undefined,
  Parameters<typeof API_ENDPOINTS.SPECIFICATIONS.DELETE_AT_COMMAND>[0]
>(
  types.DELETE_AT_COMMAND,
  'DELETE',
  API_ENDPOINTS.SPECIFICATIONS.DELETE_AT_COMMAND,
);

export const [deleteUiElement, deleteUiElementActions] = asyncActionCreator<
  unknown,
  undefined,
  undefined,
  Parameters<typeof API_ENDPOINTS.SPECIFICATIONS.DELETE_UI_ELEMENT>[0]
>(
  types.DELETE_UI_ELEMENT,
  'DELETE',
  API_ENDPOINTS.SPECIFICATIONS.DELETE_UI_ELEMENT,
);

export const [publishAtSpecification, publishAtSpecificationActions] =
  asyncActionCreator<
    unknown,
    undefined,
    undefined,
    Parameters<typeof API_ENDPOINTS.SPECIFICATIONS.PUBLISH_AT_SPECIFICATION>[0]
  >(
    types.PUBLISH_AT_SPECIFICATION,
    'POST',
    API_ENDPOINTS.SPECIFICATIONS.PUBLISH_AT_SPECIFICATION,
  );
