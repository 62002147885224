import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core';

import { fetchSensorSpecList } from '../../redux/specs/actions';
import { FetchSpecsListMods } from '../../redux/specs/interface';
import ListOfSpec from './components/ListOfSpec';
import { useSpecPage } from './useSpecPage';
import styles from './styles';
import { useUpdateSessionStatus } from '../../hooks/useUpdateSessionStatus';

const useStyles = makeStyles(styles);

const SensorSpecPage = (): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleFetchSpecs = (mode: FetchSpecsListMods) =>
    dispatch(fetchSensorSpecList(mode));

  const {
    currentPage,
    setCurrentPage,
    handleChoseSizeOfPageClick,
    specs,
    totalItems,
    pageSizeState,
    handleSortClick,
    clearData,
  } = useSpecPage({ handleFetchSpecs });

  const handleOnPageChange = (page: number) => {
    if (page > currentPage) {
      handleFetchSpecs(FetchSpecsListMods.next);
    } else if (page < currentPage) {
      handleFetchSpecs(FetchSpecsListMods.prev);
    }
    setCurrentPage(page);
  };

  useEffect(() => clearData, []);

  useUpdateSessionStatus();

  return (
    <div className={classes.wrap}>
      <ListOfSpec
        handleChoseSizeOfPageClick={handleChoseSizeOfPageClick}
        specs={specs}
        totalItems={totalItems}
        pageSizeState={pageSizeState}
        handleOnPageChange={handleOnPageChange}
        handleSortClick={handleSortClick}
      />
    </div>
  );
};

export default SensorSpecPage;
