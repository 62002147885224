import React from 'react';

import { Grid } from '@mui/material';
import TextFormField from '../../../../formElements/TextFormField';
import { AtCommandSectionsGEneralProps } from '../../types';
import { getTooltipText } from './utils';

export const ReadAtCommandSection = ({
  control,
  onlyView,
  withTooltips,
}: AtCommandSectionsGEneralProps): JSX.Element => {
  const { readCommand, identifier, separator } = getTooltipText(!!withTooltips);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <TextFormField
            disabled={onlyView}
            name="readCommand"
            label="Read AT Command"
            control={control}
            tooltipText={readCommand}
          />
        </Grid>

        <Grid item xs={4}>
          <TextFormField
            disabled={onlyView}
            name="returnValue"
            label="Return Value Identifier"
            control={control}
            tooltipText={identifier}
          />
        </Grid>
        <Grid item xs={3}>
          <TextFormField
            disabled={onlyView}
            name="separator"
            label="Read Command Separator"
            control={control}
            tooltipText={separator}
          />
        </Grid>
      </Grid>
    </div>
  );
};
