import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  DataGrid,
  GridColumnHeaderParams,
  GridSortModel,
} from '@mui/x-data-grid';

import AddDeviceModal from '../../../../components/modals/AddDeviceModal';
import { DeviceShortType } from '../../../../types/device';
import { columns, SORT_OPTION } from './config';
import AddEntityBlock from '../../../../components/ui/AddEntityBlock';
import styles from './styles';
import { getMultiRequestStatus } from '../../../../redux/api/selectors';
import devicesTypes from '../../../../redux/devices/types';
import { RequestStatus } from '../../../../redux/api/interface';

const useStyles = makeStyles(styles);

const alphabeticalSort = ['modelId', 'latestFirmwareVersion'];
const releaseState = 'status';
const updatedAt = 'updatedAt';

const ListOfDevices = ({
  devices,
  fetchDevice,
}: ListOfDevicesProps): JSX.Element => {
  const classes = useStyles();
  const [tooltipText, setTooltipText] = useState<string>('');
  const [pageSizeState, setPageSize] = useState<number>(5);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const isLoading = useSelector(
    getMultiRequestStatus(
      [devicesTypes.FETCH_DEVICE_LIST],
      RequestStatus.loading,
    ),
  );

  const onHeaderOverHandler = (params: GridColumnHeaderParams) => {
    const isConditionTrue =
      sortModel.length > 0 && params.field === sortModel[0].field;
    const addition = new Date().getMilliseconds();
    if (alphabeticalSort.includes(params.field) && isConditionTrue) {
      const sortType = sortModel[0].sort === 'asc' ? 'A-Z' : 'Z-A';
      setTooltipText(`From ${sortType}/${addition}`);
    } else if (params.field === releaseState && isConditionTrue) {
      const sortType =
        sortModel[0].sort === 'desc' ? 'Publish to Draft' : 'Draft to Publish';
      setTooltipText(`From ${sortType}/${addition}`);
    } else if (params.field === updatedAt && isConditionTrue) {
      const sortType =
        sortModel[0].sort === 'asc' ? 'Newest to Oldest' : 'Oldest to Newest';
      setTooltipText(`From ${sortType}/${addition}`);
    } else {
      setTooltipText(`Sort/${addition}`);
    }
  };

  const setCurrentSortOptionsToLocalStorage = (model: GridSortModel) => {
    setSortModel(model);
    localStorage.setItem(SORT_OPTION, JSON.stringify(model));
  };

  useEffect(() => {
    const option = localStorage.getItem(SORT_OPTION);
    if (option) {
      setSortModel(JSON.parse(option));
    }
  }, []);

  return (
    <div className={classes.wrap}>
      <DataGrid
        rows={devices}
        columns={columns}
        loading={isLoading}
        pageSize={pageSizeState}
        onRowClick={(params) => fetchDevice(String(params.id))}
        hideFooterSelectedRowCount
        className={classes.root}
        rowsPerPageOptions={[5, 15, 30]}
        onPageSizeChange={(pageSize) => setPageSize(pageSize)}
        autoHeight
        disableColumnMenu
        onSortModelChange={setCurrentSortOptionsToLocalStorage}
        sortModel={sortModel}
        localeText={{
          columnHeaderSortIconLabel: tooltipText.split('/')[0],
        }}
        onColumnHeaderOver={onHeaderOverHandler}
      />

      <AddEntityBlock
        buttonText="Add new device"
        modalElement={AddDeviceModal}
        buttonVariant="secondary"
      />
    </div>
  );
};

type ListOfDevicesProps = {
  devices: DeviceShortType[];
  fetchDevice: (id: DeviceShortType['id']) => void;
};

export default ListOfDevices;
