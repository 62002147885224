import Section from '../../../components/ui/Section';
import React from 'react';
import { Control } from 'react-hook-form';
import { ProdSpecificationSectionsAtCommandsElement } from '../../../types/spec';
import { GeneralSection } from './components/GeneralSection';
import { ReadAtCommandSection } from './components/ReadAtCommandSection';
import { WriteAtCommandSection } from './components/WriteAtCommandSection';
import { ListOfUiElements } from './components/ListOfUiElements';
import { IRemoveItemHandler } from '../../../pages/ATSpec/components/SpecificationSection';

type TProps = {
  atCommand: ProdSpecificationSectionsAtCommandsElement;
  onlyView?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  handleEditClick: (
    atCommand: ProdSpecificationSectionsAtCommandsElement,
    tabIndex: number,
    uiElementId?: string,
  ) => void;
  handleDeleteClick?: ({
    name,
    title,
    item,
    uiElementId,
    atCommandId,
  }: IRemoveItemHandler) => void;
};

const AtCommand = ({
  atCommand,
  onlyView = false,
  control,
  handleDeleteClick,
  handleEditClick,
}: TProps): JSX.Element => {
  return (
    <div>
      <Section
        headerText="General Command Settings"
        withEdit
        initialIsOpen={false}
        editCallback={() => handleEditClick(atCommand, 0)}
      >
        <GeneralSection control={control} onlyView={onlyView} />
      </Section>

      <Section headerText="Ui Elements Section" initialIsOpen={false}>
        <ListOfUiElements
          onlyView
          atCommand={atCommand}
          data={atCommand.uiElements}
          handleDeleteClick={handleDeleteClick}
          handleEditClick={handleEditClick}
        />
      </Section>

      <Section
        headerText="Read AT Command Settings"
        withEdit
        initialIsOpen={false}
        editCallback={() => handleEditClick(atCommand, 2)}
      >
        <ReadAtCommandSection control={control} onlyView={onlyView} />
      </Section>

      <Section
        headerText="Write AT Command Settings"
        withEdit
        initialIsOpen={false}
        editCallback={() => handleEditClick(atCommand, 3)}
      >
        <WriteAtCommandSection control={control} onlyView={onlyView} />
      </Section>
    </div>
  );
};

export default AtCommand;
