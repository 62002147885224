import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    contentWrap: {
      display: 'flex',
      wordBreak: 'break-all' as const,
    },
    labelWrap: {
      display: 'flex',
      alignItems: 'center',
    },
    iconWrap: {
      display: 'flex',
      alignItem: 'center',
    },
    icon: {
      width: '30px',
      height: '30px',
      marginRight: '5px',
    },
  };
}
