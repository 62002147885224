import React, { ReactNode, useState } from 'react';
import ModalWithHint from '../ModalWithHint';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@material-ui/styles';
import TabPanel from './TabPanel';
import Section from '../../../components/ui/Section';
import { GeneralSection } from '../../../components/forms/AtCommand/components/GeneralSection';
import { useForm } from 'react-hook-form';
import DisplayDetailsSection from '../../../components/forms/AtCommand/components/DisplayDetailsSection';
import {
  AllowValuesType,
  ProdSpecificationSectionsAtCommandsElement,
  UiElement,
  UpdatedUiElement,
} from '../../../types/spec';
import { ReadAtCommandSection } from '../../../components/forms/AtCommand/components/ReadAtCommandSection';
import { WriteAtCommandSection } from '../../../components/forms/AtCommand/components/WriteAtCommandSection';
import ListOfUiElementsTemplate from './ListOfUiElementsTemplate';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationAtCommand, validationUiElement } from './validation';
import { useDispatch, useSelector } from 'react-redux';
import {
  createNewAtCommand,
  editAtCommand,
} from '../../../redux/specification/actions';
import { UiElementSettings } from '../../forms/AtCommand/components/UiElementSection/UiElementSettings';
import { UiElementValidationSettings } from '../../forms/AtCommand/components/UiElementSection/UiElementValidationSettings';
import { Button } from '@material-ui/core';
import { prepareShownOn } from '../CreateNewSectionsModal/utils';
import { getSpecSections } from '../../../redux/specification/selectors';

interface UiElementModified extends Omit<UiElement, 'allowValues'> {
  allowValues: AllowValuesType;
}

type CreateAtCommandModalType = {
  headerText: string;
  subtitle: ReactNode;
  atSpecificationId: string;
  sectionId: string;
  handleClose: () => void;
  atCommand?: ProdSpecificationSectionsAtCommandsElement;
  initialIndex?: number;
  action?: 'create' | 'edit';
};

const StyledTab = styled(Tab)(() => ({
  '&.MuiButtonBase-root': {
    minHeight: 'unset',
    width: '296px',
    height: '36px',
    borderRadius: '4px',
    '&:not(:last-of-type)': {
      marginRight: '5px',
    },
  },
  '&.MuiTab-root': {
    color: '#747474',
    background: '#F4F5F6',

    '&:hover': {
      background: 'rgba(82, 125, 240, 0.5)',
      color: '#353A3B !important',
    },
  },

  '&.Mui-selected': {
    color: '#353A3B !important',
    background: 'rgba(82, 125, 240, 0.5)',
  },
}));

const CreateAtCommandModal = ({
  headerText,
  subtitle,
  atSpecificationId,
  sectionId,
  handleClose,
  atCommand,
  initialIndex = 0,
  action = 'create',
}: CreateAtCommandModalType): JSX.Element => {
  const dispatch = useDispatch();
  const [allowValues, setAllowValues] = useState<AllowValuesType[]>([]);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [uiElements, setUiElements] = useState<
    ProdSpecificationSectionsAtCommandsElement['uiElements']
  >(atCommand?.uiElements || []);
  const sections = useSelector(getSpecSections);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<Omit<ProdSpecificationSectionsAtCommandsElement, 'uiElements'>>({
    mode: 'onChange',
    resolver: yupResolver(validationAtCommand),
    defaultValues: atCommand,
  });

  const {
    control: UIControl,
    handleSubmit: UIHandleSubmit,
    reset: UIReset,
    watch: UIWatch,
    setValue: UISetValue,
  } = useForm<UiElementModified>({
    mode: 'onChange',
    resolver: yupResolver(validationUiElement),
  });
  const [value, setValue] = useState<number>(initialIndex);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const addNewUiElement = (newUiElement: UiElement) => {
    setUiElements((prevState) => [...prevState, newUiElement]);
  };

  const configureUIElement = (element: UiElementModified): UpdatedUiElement => {
    const shownOn = prepareShownOn(element.shownOn);
    const delimiter =
      typeof element.delimiter === 'string'
        ? element?.delimiter
        : element?.delimiter.symbol;
    const elements =
      typeof element.elements === 'string'
        ? [element?.elements]
        : element.elements;

    const inActive = element?.inActive || false;
    const copiedField = element?.copiedField || false;
    const isShowCounter = element?.isShowCounter || false;

    const preparedAllowValues = [...allowValues, element.allowValues];

    return {
      ...element,
      shownOn,
      elements,
      inActive,
      delimiter,
      copiedField,
      isShowCounter,
      allowValues: preparedAllowValues,
    };
  };

  const onCreateClick = handleSubmit(
    (
      values: Omit<ProdSpecificationSectionsAtCommandsElement, 'uiElements'>,
    ) => {
      const uiValues = UIWatch();
      const currentSection = sections.find(
        (section) => section.id === sectionId,
      );

      const configuredUIElement = configureUIElement(uiValues);

      const preparedSHowOn = prepareShownOn(values.shownOn);
      const preparedValues = !values.queue
        ? {
            ...values,
            queue: false,
            position: currentSection?.atCommands.length || 1,
            shownOn: preparedSHowOn,
            uiElements: [...uiElements, configuredUIElement],
          }
        : {
            ...values,
            shownOn: preparedSHowOn,
            uiElements: [...uiElements, configuredUIElement],
          };

      dispatch(
        createNewAtCommand({
          data: { ...preparedValues },
          uriParams: { atSpecificationId, sectionId },
        }),
      ).promise?.then(handleClose);
    },
  );

  const onEditAtCommand = handleSubmit(
    (values: Omit<ProdSpecificationSectionsAtCommandsElement, 'uiElements'>) =>
      dispatch(
        editAtCommand({
          data: { ...values, uiElements },
          uriParams: {
            atSpecificationId,
            sectionId,
            id: atCommand?.id?.toString() || '',
          },
        }).promise?.then(handleClose),
      ),
  );

  const handleDeleteUIElement = (id: number) => {
    setUiElements((prevState) => prevState.filter((item) => item.id !== id));
  };

  const values = UIWatch();

  const onSubmit = UIHandleSubmit((values: UiElementModified) => {
    const preparedSHowOn = prepareShownOn(values.shownOn);

    addNewUiElement({ ...values, allowValues, shownOn: preparedSHowOn });
    UISetValue('validation', {
      max: '',
      maxLength: '',
      minLength: '',
      exactLength: '',
      min: '',
      pattern: '',
      regexp: '',
    });
    UIReset();
    setExpanded((prev) => !prev);
    setAllowValues([]);
  });

  const handleAddNewValueClick = () => {
    setAllowValues((prevState) => [...prevState, values.allowValues]);
    UISetValue('allowValues', { sendValue: '', displayValue: '' });
  };

  return (
    <ModalWithHint
      headerText={headerText}
      subtitle={subtitle}
      buttonsProps={{
        leftButton: {
          disabled: Object.keys(errors).length > 0,
          children: action === 'edit' ? 'SAVE' : 'CREATE',
          onClick: action === 'edit' ? onEditAtCommand : onCreateClick,
        },
        rightButton: { children: 'CANCEL', onClick: handleClose },
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <StyledTab label="GENERAL COMMAND SETTINGS" {...a11yProps(0)} />
        <StyledTab label="UI element settings" {...a11yProps(1)} />
        <StyledTab label="READ AT COMMAND SETTINGS" {...a11yProps(2)} />
        <StyledTab label="WRITE AT COMMAND SETTINGS" {...a11yProps(3)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Section headerText="General Command Settings" initialIsOpen>
          <GeneralSection control={control} onlyView={false} withTooltips />
        </Section>

        <DisplayDetailsSection
          headerText="Command display Details"
          onlyView={false}
          control={control}
          withTooltips
          initialIsOpen={true}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {uiElements.map((element) => (
          <ListOfUiElementsTemplate
            handleDeleteUIElement={handleDeleteUIElement}
            uiElement={element}
            key={element.id}
          />
        ))}
        <>
          <Section
            headerText={'UI element settings'}
            initialIsOpen={false}
            sectionVariant="light"
            expanded={expanded}
          >
            <UiElementSettings
              control={UIControl}
              onlyView={false}
              withTooltips
            />
            <UiElementValidationSettings
              control={UIControl}
              onlyView={false}
              withTooltips
              handleAddNewValueClick={handleAddNewValueClick}
            />
          </Section>
          <DisplayDetailsSection
            headerText="Ui element display Details"
            control={UIControl}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '20px',
            }}
          >
            <Button
              style={{
                background: 'rgba(21, 84, 246)',
                color: 'white',
                width: 400,
                height: '40px',
              }}
              variant="contained"
              size="large"
              onClick={onSubmit}
            >
              ADD NEW UI ELEMENT
            </Button>
          </div>
        </>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Section headerText="Read AT Command Settings" initialIsOpen={true}>
          <ReadAtCommandSection
            control={control}
            onlyView={false}
            withTooltips
          />
        </Section>
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Section
          headerText="Write AT Command Settings"
          initialIsOpen={true}
          tooltipText=""
        >
          <WriteAtCommandSection
            control={control}
            onlyView={false}
            withTooltips
          />
        </Section>
      </TabPanel>
    </ModalWithHint>
  );
};

export default CreateAtCommandModal;
