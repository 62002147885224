import React from 'react';

export const DevicePageIcon = ({ color }: { color?: string }): JSX.Element => {
  const currentColor = color ?? 'currentColor';
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_43_1697" fill={currentColor}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 0.665039H14C15.1046 0.665039 16 1.56047 16 2.66504V12.665C16 13.7696 15.1046 14.665 14 14.665H4C2.89543 14.665 2 13.7696 2 12.665V11.3317H0V2.66504L2 2.66504C2 1.56047 2.89543 0.665039 4 0.665039Z"
        />
      </mask>
      <path
        d="M2 11.3317H3V10.3317H2V11.3317ZM0 11.3317H-1V12.3317H0V11.3317ZM0 2.66504L-1.19209e-07 1.66504L-1 1.66504V2.66504H0ZM2 2.66504V3.66504H3V2.66504H2ZM14 -0.334961H4V1.66504H14V-0.334961ZM17 2.66504C17 1.00818 15.6569 -0.334961 14 -0.334961V1.66504C14.5523 1.66504 15 2.11275 15 2.66504H17ZM17 12.665V2.66504H15V12.665H17ZM14 15.665C15.6569 15.665 17 14.3219 17 12.665H15C15 13.2173 14.5523 13.665 14 13.665V15.665ZM4 15.665H14V13.665H4V15.665ZM1 12.665C1 14.3219 2.34315 15.665 4 15.665V13.665C3.44772 13.665 3 13.2173 3 12.665H1ZM1 11.3317V12.665H3V11.3317H1ZM0 12.3317H2V10.3317H0V12.3317ZM-1 2.66504V11.3317H1V2.66504H-1ZM2 1.66504L-1.19209e-07 1.66504L1.19209e-07 3.66504L2 3.66504L2 1.66504ZM4 -0.334961C2.34315 -0.334961 1 1.00818 1 2.66504H3C3 2.11275 3.44772 1.66504 4 1.66504V-0.334961Z"
        fill={currentColor}
        mask="url(#path-1-inside-1_43_1697)"
      />
      <line
        x1="0.666504"
        y1="8.83301"
        x2="2.6665"
        y2="8.83301"
        stroke={currentColor}
      />
      <line
        x1="0.666504"
        y1="6.83301"
        x2="2.6665"
        y2="6.83301"
        stroke={currentColor}
      />
      <line
        x1="0.666504"
        y1="4.83301"
        x2="2.6665"
        y2="4.83301"
        stroke={currentColor}
      />
      <circle cx="11.9998" cy="4.66634" r="0.833333" stroke={currentColor} />
      <rect
        x="5.8335"
        y="7.83301"
        width="7"
        height="3"
        rx="0.833333"
        stroke={currentColor}
      />
    </svg>
  );
};
