import * as yup from 'yup';
import { API } from '../../constants/api';
import { isLocalDev } from '../../utils/isLocalDev';
import { APP_COOKIES } from '../../constants/appCookie';
import { appCookie } from '../../utils/cookies';

const { REACT_APP_PASSWORD, REACT_APP_EMAIL } = process.env;

export const validationForLogin = yup.object().shape({
  username: yup
    .string()
    .required('Please input your Username!')
    .oneOf([REACT_APP_EMAIL], "User does't exist!"),
  password: yup
    .string()
    .required('Please input your Password!')
    .oneOf([REACT_APP_PASSWORD], 'Wrong password!'),
});

export const generateAuthState = (): string => {
  const state = String(Date.now());
  appCookie.setCookie(APP_COOKIES.SESSION_STATE, state);
  return state;
};

export const validateState = (state: string): boolean => {
  const currentState = appCookie.getCookie(APP_COOKIES.SESSION_STATE);

  if (!state || !currentState) return false;

  return state === currentState;
};

const {
  REACT_APP_RAK_CLIENT_ID: clientId,
  REACT_APP_RAK_ID_OAUTH_URL: rakIdAuthUrl,
  REACT_APP_REDIRECT_URI: adminUrl,
} = process.env;

export const generateLoginUrl = (): URL => {
  const origin = isLocalDev ? window.location.host : adminUrl;

  const url = new URL(API.RAK_ID.AUTH, rakIdAuthUrl);
  url.searchParams.set('client_id', `${clientId}`);
  url.searchParams.set('response_type', 'token');
  url.searchParams.set(
    'redirect_uri',
    `http${process.env.NODE_ENV === 'development' ? '' : 's'}://${origin}/cb`,
  );
  url.searchParams.set('state', generateAuthState());

  return url;
};
