import React, { ReactNode } from 'react';
import ModalWithHint from '../../../components/modals/ModalWithHint';
import { styled } from '@material-ui/styles';
import Divider from '../Divider/Divider';

const StyledDiv = styled('div')(() => ({ fontSize: '15px' }));

type ConfirmDeleteModalProps = {
  text: ReactNode;
  handleSubmit: () => void;
  handleCancel: () => void;
  headerText: string;
};

const ConfirmDeleteModal = ({
  text,
  handleSubmit,
  handleCancel,
  headerText,
}: ConfirmDeleteModalProps): JSX.Element => {
  return (
    <ModalWithHint
      headerText={headerText}
      subtitle={
        <StyledDiv>
          <Divider />
          {text}
        </StyledDiv>
      }
      buttonsProps={{
        leftButton: { children: 'DELETE', onClick: handleSubmit },
        rightButton: { children: 'CANCEL', onClick: handleCancel },
      }}
    />
  );
};

export default ConfirmDeleteModal;
