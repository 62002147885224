import * as yup from 'yup';
import {
  errorMessageInvalidChar,
  patternForLatinCharacters,
} from '../../../utils/forms/constants';

export const validationAtCommand = yup.object().shape({
  shownOn: yup.array().required(),
  title: yup
    .string()
    .required()
    .matches(patternForLatinCharacters, errorMessageInvalidChar)
    .min(1, 'Min field length is 1 character')
    .max(256, 'Max field length is 256 characters'),
  accordionTitle: yup
    .string()
    .matches(patternForLatinCharacters, errorMessageInvalidChar)
    .min(1, 'Min field length is 1 character')
    .max(256, 'Max field length is 256 characters'),
  command: yup
    .string()
    .required()
    .test('startWith', errorMessageInvalidChar, (value) => {
      if (!value) return true;
      return value.startsWith('AT');
    })
    .matches(patternForLatinCharacters, errorMessageInvalidChar)
    .min(1, 'Min field length is 1 character')
    .max(256, 'Max field length is 256 characters'),
  dependOn: yup
    .string()
    .matches(patternForLatinCharacters, errorMessageInvalidChar)
    .min(1, 'Min field length is 1 character')
    .max(256, 'Max field length is 256 characters'),
  readCommand: yup
    .string()
    .test('startWith', errorMessageInvalidChar, (value) => {
      if (!value) return true;
      return value.startsWith('AT');
    }),
  returnValue: yup
    .string()
    .max(512, 'Max field length is 512 characters')
    .matches(patternForLatinCharacters, errorMessageInvalidChar),
  readSeparator: yup
    .string()
    .matches(
      /[$&+,:;=?@#|'<>.^*()%!-]/,
      'Invalid characters. Only symbols :  ;  , . - are allowed',
    ),
  writeCommand: yup
    .string()
    .test('startWith', errorMessageInvalidChar, (value) => {
      if (!value) return true;
      return value.startsWith('AT');
    })
    .min(1, 'Min field length is 1 character'),
  writeSeparator: yup
    .string()
    .matches(
      /[$&+,:;=?@#|'<>.^*()%!-]/,
      'Invalid characters. Only symbols :  ;  , . - are allowed',
    ),
});

export const validationUiElement = yup.object().shape({
  shownOn: yup.array().required(),
  title: yup
    .string()
    .required()
    .matches(patternForLatinCharacters, errorMessageInvalidChar)
    .min(1, 'Min field length is 1 character')
    .max(256, 'Max field length is 256 characters'),
  elements: yup.string().required('Please choose UI element from the list'),
  position: yup.string().matches(/[0-9]/, errorMessageInvalidChar).required(),
  separator: yup
    .string()
    .matches(/[$&+,:;=?@#|'<>.^*()%!-]/, errorMessageInvalidChar),
  delimiter: yup.object().shape({
    symbol: yup
      .string()
      .max(32, 'Max field length is 32 characters')
      .matches(patternForLatinCharacters, errorMessageInvalidChar),
  }),
  validation: yup.object().shape({
    min: yup.string().matches(/[0-9]/, errorMessageInvalidChar),
    max: yup.string().matches(/[0-9]/, errorMessageInvalidChar),
    minLength: yup.string().matches(/[0-9]/, errorMessageInvalidChar),
    maxLength: yup.string().matches(/[0-9]/, errorMessageInvalidChar),
    exactLength: yup.string().matches(/[0-9]/, errorMessageInvalidChar),
    regexp: yup
      .string()
      .matches(patternForLatinCharacters, errorMessageInvalidChar)
      .max(256, 'Max field length is 256 characters'),
    pattern: yup
      .string()
      .matches(patternForLatinCharacters, errorMessageInvalidChar)
      .max(256, 'Max field length is 256 characters'),
  }),
  allowValues: yup.object().shape({
    displayValue: yup
      .string()
      .required('Display value is a required field')
      .matches(patternForLatinCharacters, errorMessageInvalidChar)
      .max(256, 'Max field length is 256 characters'),
    sendValue: yup
      .string()
      .required('Send value is a required field')
      .matches(patternForLatinCharacters, errorMessageInvalidChar)
      .max(256, 'Max field length is 256 characters'),
  }),
});
