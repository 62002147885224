import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { ControllerFieldState } from 'react-hook-form';
import styles from './styles';

const useStyles = makeStyles(styles);

const FieldControl = ({
  label,
  children,
  fieldState,
}: FieldControlProps): JSX.Element => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      <div>
        <Typography className={classes.label} variant="caption">
          {label}
        </Typography>
      </div>
      {children}
      {fieldState?.error && (
        <Typography color="error">{fieldState?.error?.message}</Typography>
      )}
    </div>
  );
};

type FieldControlProps = {
  label: string;
  children: JSX.Element;
  fieldState?: ControllerFieldState;
};

FieldControl.defaultProps = {
  fieldState: {},
};

export default FieldControl;
