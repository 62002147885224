import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    imgWrap: {
      width: '100%',
      height: 'calc(100% - 20px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px',
    },
    deviceImg: {
      maxHeight: '100px',
      maxWidth: '100%',
    },
    field: { width: '100%' },
    modelId: { textTransform: 'lowercase' as const, color: 'red' },
    input: { height: 101, justifyContent: 'center' as const },
    fieldsGrid: {
      display: 'grid',
      rowGap: 20,
      height: 'fit-content',
    },
    errorMessage: {
      color: '#d32f2f',
      fontSize: '1rem',
      lineHeight: 1.5,
      fontFamily: 'RobotoRegular',
      marginLeft: '25px',
      width: '500px',
    },
    deviceRadioGroup: {
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
      },
    },
    flexEnd: {
      '&.MuiGrid-root': {
        display: 'flex',
        alignItems: 'flex-end',
      },
    },
  };
}
