import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const DropZoneField = ({
  name,
  control,
  children,
  disabled = false,
  isEdit,
}: DropZoneFieldProps): JSX.Element => {
  const classes = useStyles();

  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (files: File[]) => onChange(files[0]),
    maxFiles: 1,
    multiple: false,
    disabled,
  });

  const showUploadButton =
    (isEdit && typeof value === 'string') || (!isEdit && !value);

  return (
    <div
      {...getRootProps()}
      className={`${
        !showUploadButton && !disabled ? classes.emptyState : classes.dropZone
      } ${disabled ? classes.disabled : ''}`}
    >
      <div className={classes.labelWrap}>
        {value?.name && !disabled ? (
          <Typography>{value.name}</Typography>
        ) : (
          children
        )}
      </div>
      <input {...getInputProps()} />
    </div>
  );
};

type DropZoneFieldProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  children: React.ReactNode;
  icon: React.ElementType;
  label: string;
  disabled?: boolean;
  isEdit?: boolean;
};

export default DropZoneField;
