import React from 'react';

import {
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import TextFormField from '../../formElements/TextFormField';
import {
  Control,
  useController,
  UseFormSetValue,
  Controller,
} from 'react-hook-form';
import PhotoDropZone from '../../../components/formElements/PhotoDropZone';
import { makeStyles } from '@material-ui/core';
import SupportedAppsCheckbox from './SupportedAppsCheckbox';

import styles from './styles';
import { ApiErrorsType } from '../../../redux/api/interface';

const useStyles = makeStyles(styles);

const DeviceFields = ({
  connectionType,
  control,
  supportedApps,
  onlyView,
  imageLink,
  isEdit,
  setValue,
  errors,
  isNotUniqueCombination,
}: DeviceFieldsProps): JSX.Element => {
  const classes = useStyles();
  const Upload = () => (
    <PhotoDropZone
      name="image"
      isEdit={isEdit}
      control={control}
      label="UPLOAD PICTURE"
    />
  );

  const { field: showCustomFirmwareField } = useController({
    name: 'showCustomFirmware',
    control,
  });

  const { field: isSensorSpecificationRequired } = useController({
    name: 'isSensorSpecificationRequired',
    control,
  });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            Device Info:
          </Typography>
        </Grid>

        <Grid item xs={12} sm={4} className={classes.fieldsGrid}>
          <TextFormField
            disabled={onlyView || isEdit}
            name="modelId"
            label="Model ID"
            control={control}
            placeholder="e.g. rak4631"
            className={classes.modelId}
          />
          <TextFormField
            disabled={onlyView}
            name="hardwareModelName"
            label="Hardware model name"
            control={control}
            placeholder="e.g. rak4631"
            tooltipText="Name of the Hardware model as reported by AT+HWMODEL."
          />
        </Grid>

        <Grid item xs={12} sm={4} className={classes.fieldsGrid}>
          <TextFormField
            disabled={onlyView}
            name="modelName"
            label="Model name"
            control={control}
            placeholder="e.g. WisBlock LPWAN Module"
          />
          <TextFormField
            disabled={onlyView}
            name="productModelName"
            label="Product model name"
            control={control}
            placeholder="e.g. rak4631"
            tooltipText="Name of the finished product model as reported by ATC+PRD_INFO. Leave empty if it is the core module."
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          {!onlyView && (
            <TextFormField
              disabled
              name="image"
              label="Device picture"
              control={control}
              tooltipText="Optimal image aspect ratio: aim for 1:1 for best rendering results."
              InputProps={{
                className: classes.input,
                components: { Input: Upload },
              }}
            />
          )}
          {imageLink && (
            <div className={classes.imgWrap}>
              <img src={imageLink} alt="device" className={classes.deviceImg} />
            </div>
          )}
          {onlyView && !imageLink && (
            <Typography sx={{ textAlign: 'center' }}>No image link</Typography>
          )}
        </Grid>

        {isNotUniqueCombination && (
          <div className={classes.errorMessage}>
            Combination of Hardware model name and Product model name should be
            unique
          </div>
        )}

        <Grid item xs={12} sm={onlyView ? 4 : 8}>
          <TextFormField
            disabled={onlyView}
            name="deviceInfo"
            label="Device description"
            control={control}
            multiline
            placeholder="e.g. The RAK4631 WisBlock LPWAN is a WisBlock Core module which delivers a powerful Nordic nRF52840 MCU..."
          />
        </Grid>

        <Grid item xs={12} sm={4} className={classes.flexEnd}>
          <div>
            <FormControlLabel
              label="Show custom firmware"
              control={
                <Checkbox
                  disabled={onlyView}
                  checked={showCustomFirmwareField.value}
                  onChange={(e) =>
                    setValue('showCustomFirmware', e.target.checked)
                  }
                />
              }
            />
            <FormControlLabel
              label="Requires sensor specification"
              control={
                <Checkbox
                  disabled={onlyView || isEdit}
                  checked={isSensorSpecificationRequired.value}
                  onChange={(e) =>
                    setValue('isSensorSpecificationRequired', e.target.checked)
                  }
                />
              }
            />
          </div>
        </Grid>

        {onlyView && (
          <Grid item xs={12} sm={4}>
            <TextFormField
              disabled={onlyView}
              name="createdAt"
              label="Date added"
              control={control}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            External links:
          </Typography>
        </Grid>

        <Grid item xs={6} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="productDescription"
            label="Link to product description"
            control={control}
            placeholder="https://docs.rakwireless.com/…"
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <TextFormField
            disabled={onlyView}
            name="deviceDocumentation"
            label="Link to device documentation"
            control={control}
            placeholder="https://docs.rakwireless.com/…"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography color="primary" variant="h6">
            Specify WisToolBox platform
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <SupportedAppsCheckbox
            control={control}
            onlyView={onlyView}
            setValue={setValue}
          />
        </Grid>
        {supportedApps?.includes('MOBILE') && (
          <>
            <Grid item xs={12}>
              <Controller
                control={control}
                name="connectionType"
                defaultValue="BLE"
                render={({ field }) => (
                  <div className={classes.deviceRadioGroup}>
                    <RadioGroup {...field}>
                      <FormControlLabel
                        value="BLE"
                        control={<Radio />}
                        label="BLE"
                        disabled={onlyView}
                      />
                      <FormControlLabel
                        value="NFC"
                        control={<Radio />}
                        label="NFC"
                        disabled={onlyView}
                      />
                    </RadioGroup>
                  </div>
                )}
              />
            </Grid>
            {connectionType === 'NFC' && (
              <>
                <Grid item xs={12} sm={4}>
                  <TextFormField
                    disabled={onlyView}
                    name="nfcActivationValue"
                    label="NFC activation value"
                    control={control}
                    placeholder="e.g. rak2560"
                    className={classes.modelId}
                    tooltipText="A string value to use when waking up the device via ATC+BTADV."
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextFormField
                    disabled={onlyView}
                    name="bleName"
                    label="BLE name"
                    control={control}
                    placeholder="e.g. rak2560"
                    tooltipText="A distinctive string, usually model-related, the device BLE broadcast name starts with."
                  />
                </Grid>
              </>
            )}
          </>
        )}
        {errors &&
          errors.map((error) => (
            <div className={classes.errorMessage}>{error.errorMessage}</div>
          ))}
      </Grid>
    </div>
  );
};

type DeviceFieldsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  connectionType?: string;
  errors?: ApiErrorsType[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  onlyView?: boolean;
  imageLink?: string;
  isEdit?: boolean;
  isNotUniqueCombination?: boolean;
  supportedApps?: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>;
};

DeviceFields.defaultProps = {
  onlyView: false,
  imageLink: undefined,
  isEdit: false,
};

export default DeviceFields;
