import { Theme } from '@material-ui/core';
import { StylesObjectType } from '../../../types/types';

export default function styles(theme: Theme): StylesObjectType {
  return {
    loader: {
      zIndex: 2000,
      position: 'fixed' as const,
      left: 'calc(50% - 20px)',
      top: 'calc(50% - 20px)',
      color: theme.palette.info.main,
    },
  };
}
