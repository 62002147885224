import React, { useState } from 'react';

import AtCommand from '../../../../components/forms/AtCommand';
import SpecificationSectionFields from '../../../../components/forms/SpecificationSectionFields';
import { Button, styled } from '@material-ui/core';
import Section from '../../../../components/ui/Section';
import { useForm } from 'react-hook-form';
import {
  ProdSpecificationSectionsAtCommandsElement,
  SpecDetailsType,
  SpecificationSectionType,
} from '../../../../types/spec';
import CreateAtCommandModal from '../../../../components/modals/CreateAtCommandModal';
import ConfirmDeleteModal from '../../../../components/ui/ConfirmDeleteModal';
import { useDispatch } from 'react-redux';
import {
  deleteAtCommand,
  deleteSectionOfSpecification,
  deleteUiElement,
} from '../../../../redux/specification/actions';

export type Items = 'atCommand' | 'section' | 'uiElement';

const StyledButton = styled(Button)(() => ({
  width: '300px',
  background: '#006AC6',
  margin: '30px 0',
}));

const StyledButtonWrapper = styled('div')(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
}));

export type ATCommandModalState = {
  tabIndex?: number;
  atCommand?: ProdSpecificationSectionsAtCommandsElement;
  idOfUiElement?: string;
  action?: 'create' | 'edit';
};

export type IRemoveItemHandler = {
  event: React.MouseEvent<HTMLElement>;
  name: string;
  title: string;
  item: Items;
  uiElementId?: string;
  atCommandId?: string;
};

const SpecificationSection = ({ section, data }: TProps): JSX.Element => {
  const dispatch = useDispatch();
  const [createOrEditATCommandModalState, setCreateOrEditATCommandModalState] =
    useState<ATCommandModalState | null>(null);
  const [nameOfItemToDelete, setNameOfItemToDelete] = useState<{
    name: string;
    title: string;
    item: Items;
    uiElementId?: string;
    atCommandId?: string;
  } | null>(null);
  const { control } = useForm({
    defaultValues: {
      ...section,
      dependOn: section.dependOn ? section.dependOn.join(',') : null,
    },
  });

  const handleCancelClick = () => {
    setNameOfItemToDelete(null);
  };

  const handleEditClick = (
    atCommand: ProdSpecificationSectionsAtCommandsElement,
    tabIndex: number,
    uiElementId?: string,
  ) => {
    setCreateOrEditATCommandModalState({
      atCommand,
      tabIndex,
      idOfUiElement: uiElementId,
      action: 'edit',
    });
  };

  const handleDeleteClick = ({
    event,
    name,
    title,
    item,
    uiElementId,
    atCommandId,
  }: IRemoveItemHandler) => {
    event.stopPropagation();
    setNameOfItemToDelete({ name, title, item, uiElementId, atCommandId });
  };

  const handleClose = () => {
    setCreateOrEditATCommandModalState(null);
  };

  const handleAddNewCommandClick = () => {
    setCreateOrEditATCommandModalState({ action: 'create' });
  };

  const handleSubmitDelete = () => {
    switch (nameOfItemToDelete?.item) {
      case 'section':
        dispatch(
          deleteSectionOfSpecification({
            uriParams: { sectionId: section.id, atSpecificationId: data.id },
          }),
        ).promise?.then(handleCancelClick);
        break;
      case 'atCommand':
        dispatch(
          deleteAtCommand({
            uriParams: {
              atSpecificationId: data.id,
              sectionId: section.id,
              id: nameOfItemToDelete?.atCommandId || '',
            },
          }),
        ).promise?.then(handleCancelClick);
        break;
      case 'uiElement':
        dispatch(
          deleteUiElement({
            uriParams: {
              atSpecificationId: data.id,
              sectionId: section.id,
              atCommandId: nameOfItemToDelete.atCommandId || '',
              id: nameOfItemToDelete.uiElementId || '',
            },
          }),
        ).promise?.then(handleCancelClick);
        break;
    }
  };

  const DeleteSectionText = (
    <>
      Are you sure to delete{' '}
      <span className="bold-text">{nameOfItemToDelete?.name} Section</span> of{' '}
      <span className="bold-text">{data.atVersion}</span> At Specification?
      Please, be aware that AT Specification status will be changed to{' '}
      <span className="bold-text">Draft</span> and you’ll need to{' '}
      <span className="bold-text">Publish</span> it again
    </>
  );
  const { atCommand, tabIndex, action } = {
    ...createOrEditATCommandModalState,
  };
  return (
    <>
      {createOrEditATCommandModalState && (
        <CreateAtCommandModal
          action={action}
          initialIndex={tabIndex}
          handleClose={handleClose}
          atSpecificationId={data.id}
          sectionId={section.id}
          atCommand={atCommand}
          headerText={
            action === 'create' ? 'ADD NEW AT COMMAND' : 'EDIT AT COMMAND'
          }
          subtitle={
            action === 'create' ? (
              <div>
                Here you can add information for new{' '}
                <span style={{ fontWeight: 'bold' }}>AT Command.</span>
              </div>
            ) : (
              <div>
                Here you can edit{' '}
                <span style={{ fontWeight: 'bold' }}>AT Command.</span>{' '}
                information.
              </div>
            )
          }
        />
      )}
      {nameOfItemToDelete && (
        <ConfirmDeleteModal
          headerText={nameOfItemToDelete.title}
          text={DeleteSectionText}
          handleSubmit={handleSubmitDelete}
          handleCancel={handleCancelClick}
        />
      )}
      <Section
        headerText={section.title}
        withDelete
        initialIsOpen={false}
        sectionVariant="secondary"
        deleteCallback={(event: React.MouseEvent<HTMLElement>) =>
          handleDeleteClick({
            event,
            name: section.title,
            title: 'delete At Commands section',
            item: 'section',
          })
        }
      >
        <Section headerText="Section Details">
          <SpecificationSectionFields control={control} onlyView />
        </Section>
        <div style={{ marginTop: '20px' }}>
          {section.atCommands.map((atCommand) => (
            <Section
              headerText={atCommand.title}
              initialIsOpen={false}
              key={atCommand.id}
              sectionVariant="secondary"
              withDelete
              deleteCallback={(event: React.MouseEvent<HTMLElement>) =>
                handleDeleteClick({
                  event,
                  name: atCommand.title,
                  title: 'delete At Command',
                  item: 'atCommand',
                  atCommandId: atCommand.id?.toString(),
                })
              }
            >
              <AtCommand
                onlyView
                atCommand={atCommand}
                control={control}
                handleDeleteClick={handleDeleteClick}
                handleEditClick={handleEditClick}
              />
            </Section>
          ))}
          <StyledButtonWrapper>
            <StyledButton
              color="primary"
              variant="contained"
              size="large"
              onClick={handleAddNewCommandClick}
            >
              <div>Add new command</div>
            </StyledButton>
          </StyledButtonWrapper>
        </div>
      </Section>
    </>
  );
};

type TProps = {
  section: SpecificationSectionType;
  data: Omit<SpecDetailsType, 'sections'>;
};

export default SpecificationSection;
