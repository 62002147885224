import { SpecificationType, SpecShortType } from '../../types/spec';

export type FetchSpecsListResponse = {
  data: SpecShortType;
  // page: number;
  // totalItems: number;
  errors: unknown; // TODO: add error type
};

export type FetchSpecListParams = {
  limit?: number;
  nextToken?: string;
  sortBy?: string;
  sortOrder?: string;
  type?: SpecificationType;
};

export enum FetchSpecsListMods {
  prev = 'PREV',
  next = 'NEXT',
  default = 'DEFAULT',
}

export type TAtVersions = {
  id: string;
  status: 'DRAFT' | 'PUBLISHED';
  atVersion: string;
  aliasName?: string;
};

export type FetchAtCommandsResponse = {
  data: {
    atVersions: TAtVersions[];
  };
  errors: unknown;
};
