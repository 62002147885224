export const getTooltipText = (
  withTooltips: boolean,
): {
  title: string;
  elements: string;
  position: string;
  conditionalDependency: string;
  formatUI: string;
  inActive: string;
  copiedField: string;
  isShowCounter: string;
  symbol: string;
  delimiter: string;
  min: string;
  max: string;
  minLength: string;
  maxLength: string;
  exactLength: string;
  regexp: string;
  pattern: string;
  displayValue: string;
  sendValue: string;
  defaultTemplateValue: string;
} => {
  const toolTips = {
    title: 'Set field title',
    elements: 'Set UI element for the field',
    position: 'Set position inside section (e.g. 1)',
    conditionalDependency: 'Set dependency if field would be shown on UI',
    formatUI:
      'Set arithmetic method and value for format (e.g. READ,DIVISION,100000)',
    inActive: 'Set whether filed should be clickable',
    copiedField: 'Set whether field should have copy to clipboard',
    isShowCounter:
      'Set whether field should have max allowed characters counter',
    symbol: 'Specify if payload symbols should be separated',
    delimiter:
      'Specify if payload message should be separated every specific number of symbols (e.g 2,\xa0)',
    min: 'Specify minimum value that can be entered for a command',
    max: 'Specify maximum value that can be entered for a command',
    minLength: 'Specify min characters length that can be entered',
    maxLength: 'Specify max characters length that can be entered',
    exactLength: 'Specify exact characters length that can be entered',
    regexp:
      'Specify regular expression that validates allowed values for the field (e.g. ^([a-fA-F0-9]{8})+$)',
    pattern:
      'Regular patters that validates allowed values for the field (e.g. a-f, A-F,0-9)',
    displayValue: 'Set value that should be displayed on UI',
    sendValue: 'Set value that should be sent to device in AT command',
    defaultTemplateValue: '',
  };
  if (withTooltips) {
    return toolTips;
  }
  return {
    title: '',
    elements: '',
    position: '',
    conditionalDependency: '',
    formatUI: '',
    inActive: '',
    copiedField: '',
    isShowCounter: '',
    symbol: '',
    delimiter: '',
    min: '',
    max: '',
    minLength: '',
    maxLength: '',
    exactLength: '',
    regexp: '',
    pattern: '',
    displayValue: '',
    sendValue: '',
    defaultTemplateValue: '',
  };
};
