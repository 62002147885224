import React from 'react';

import { useForm } from 'react-hook-form';
import {
  ProdSpecificationSectionsAtCommandsElement,
  UiElement,
} from '../../../../../types/spec';
import Section from '../../../../ui/Section';
import DisplayDetailsSection from '../DisplayDetailsSection';
import { UiElementSettings } from './UiElementSettings';
import { UiElementValidationSettings } from './UiElementValidationSettings';
import { IRemoveItemHandler } from '../../../../../pages/ATSpec/components/SpecificationSection';

interface TProps {
  atCommand: ProdSpecificationSectionsAtCommandsElement;
  uiElement: UiElement;
  onlyView?: boolean;
  handleDeleteClick?: ({
    name,
    title,
    item,
    uiElementId,
    atCommandId,
  }: IRemoveItemHandler) => void;
  handleEditClick: (
    atCommand: ProdSpecificationSectionsAtCommandsElement,
    tabIndex: number,
    uiElementId?: string,
  ) => void;
}

export const UiElementSection = ({
  onlyView,
  uiElement,
  handleDeleteClick,
  atCommand,
  handleEditClick,
}: TProps): JSX.Element => {
  const { control } = useForm({
    defaultValues: {
      ...uiElement,
      allowValues: {
        sendValue:
          uiElement?.allowValues && uiElement?.allowValues[0]?.sendValue,
        displayValue:
          uiElement?.allowValues && uiElement?.allowValues[0]?.displayValue,
      },
    },
  });

  return (
    <Section
      headerText={uiElement.title}
      withDelete
      withEdit
      initialIsOpen={false}
      sectionVariant="primary"
      editCallback={() =>
        handleEditClick(atCommand, 1, uiElement?.id.toString())
      }
      deleteCallback={(event: React.MouseEvent<HTMLElement>) =>
        handleDeleteClick?.({
          event,
          name: uiElement.title,
          title: 'delete UI ELEMENT',
          item: 'uiElement',
          uiElementId: atCommand.id?.toString(),
          atCommandId: uiElement.id.toString(),
        })
      }
    >
      <UiElementSettings control={control} onlyView={onlyView} />
      <UiElementValidationSettings
        control={control}
        onlyView={onlyView}
        noNewValueBtn
      />
      <DisplayDetailsSection
        headerText="Ui element display Details"
        control={control}
      />
    </Section>
  );
};
