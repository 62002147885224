import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FirmwareType } from '../../../../types/device';
import FirmawareDetailsFields from '../../../../components/forms/FirmawareDetailsFields';

const FirmwareViewForm = ({ firmware }: FirmwareFormType): JSX.Element => {
  const { control, reset } = useForm({
    mode: 'onSubmit',
    defaultValues: firmware,
  });

  useEffect(() => {
    reset(firmware);
  }, [firmware]);

  return (
    <FirmawareDetailsFields
      onlyView
      fileLink={firmware.location}
      control={control}
    />
  );
};

type FirmwareFormType = {
  firmware: FirmwareType;
};

export default FirmwareViewForm;
