import { StylesObjectType } from '../../../../types/types';

export default function styles(): StylesObjectType {
  return {
    wrap: {
      marginBottom: '27px',
    },
    root: {
      '& .MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
        outline: 'none !important',
      },
      '& .MuiDataGrid-row.Mui-selected': {
        background: 'rgba(82, 125, 240, 0.5) !important',
      },
    },
  };
}
