import { DeviceShortType, FirmwareType } from '../types/device';
import { SpecDetailsType, SpecificationsListType } from '../types/spec';

const API_PREFIX = process.env.REACT_APP_API_PREFIX;

const API_ENDPOINTS = {
  GET_DEVICE: ({ deviceId }: { deviceId: DeviceShortType['id'] }): string =>
    `${API_PREFIX}/devices/${deviceId}`,
  GET_DEVICE_LIST: `${API_PREFIX}/devices`,
  CREATE_DEVICE: `${API_PREFIX}/devices`,
  DELETE_DEVICE: ({ deviceId }: { deviceId: DeviceShortType['id'] }): string =>
    `${API_PREFIX}/devices/${deviceId}`,
  UPDATE_DEVICE: ({ deviceId }: { deviceId: DeviceShortType['id'] }): string =>
    `${API_PREFIX}/devices/${deviceId}`,
  DELETE_FIRMWARE: ({
    deviceId,
    firmwareId,
  }: {
    deviceId: DeviceShortType['id'];
    firmwareId: FirmwareType['id'];
  }): string => `${API_PREFIX}/devices/${deviceId}/firmwares/${firmwareId}`,
  UPDATE_FIRMWARE: ({
    deviceId,
    firmwareId,
  }: {
    deviceId: DeviceShortType['id'];
    firmwareId: FirmwareType['id'];
  }): string => `${API_PREFIX}/devices/${deviceId}/firmwares/${firmwareId}`,
  CREATE_FIRMWARE: ({
    deviceId,
  }: {
    deviceId: DeviceShortType['id'];
  }): string => `${API_PREFIX}/devices/${deviceId}/firmwares`,

  SPECIFICATIONS: {
    PUBLISH_AT_SPECIFICATION: ({ specId }: { specId: string }): string =>
      `${API_PREFIX}/at-commands/${specId}/publish`,
    EDIT_AT_COMMAND: ({
      atSpecificationId,
      sectionId,
      id,
    }: {
      atSpecificationId: string;
      sectionId: string;
      id: string;
    }): string =>
      `${API_PREFIX}/at-commands/${atSpecificationId}/sections/${sectionId}/command/${id}`,
    DELETE_SPECIFICATION_SECTION: ({
      atSpecificationId,
      sectionId,
    }: {
      atSpecificationId: string;
      sectionId: string;
    }): string => `at-commands/${atSpecificationId}/sections/${sectionId} `,
    DELETE_AT_COMMAND: ({
      atSpecificationId,
      sectionId,
      id,
    }: {
      atSpecificationId: string;
      sectionId: string;
      id: string;
    }): string =>
      `at-commands/${atSpecificationId}/sections/${sectionId}/command/${id}`,
    DELETE_UI_ELEMENT: ({
      atSpecificationId,
      sectionId,
      atCommandId,
      id,
    }: {
      atSpecificationId: string;
      sectionId: string;
      atCommandId: string;
      id: string;
    }): string =>
      `at-commands/${atSpecificationId}/sections/${sectionId}/command/${atCommandId}/ui/${id}`,
    DELETE_SPECIFICATION: ({ specId }: { specId: string }): string =>
      `${API_PREFIX}/at-commands/${specId}`,
    CREATE_SPEC_WITH_FILE: `${API_PREFIX}/at-commands/file`,
    CREATE_SPEC: `${API_PREFIX}/at-commands`,
    GET_SPEC_DETAILS: ({
      specId,
    }: {
      specId: SpecificationsListType['atVersion'];
    }): string => `${API_PREFIX}/at-commands/at-specification/${specId}`,
    GET_SPEC_LIST: `${API_PREFIX}/at-commands/at-specification`,
    GET_SENSOR_SPEC_LIST: `${API_PREFIX}/at-commands/sensor-specification`,
    CREATE_NEW_AT_COMMAND: ({
      atSpecificationId,
      sectionId,
    }: {
      atSpecificationId: string;
      sectionId: string;
    }): string =>
      `${API_PREFIX}/at-commands/${atSpecificationId}/sections/${sectionId}/command`,
  },
  CREATE_NEW_SECTION: ({
    specId,
  }: {
    specId: SpecDetailsType['atVersion'];
  }): string => `${API_PREFIX}/at-commands/${specId}/addsection`,
  GET_LIST_AT_VERSIONS: `${API_PREFIX}/at-commands/at-version`,
};

export default API_ENDPOINTS;
