import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { login } from '../redux/authenticate/slice';
import { selectIsAuthenticated } from '../redux/authenticate/selectors';

export const useUpdateSessionStatus = (): void => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsAuthenticated);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(login(true));
    }
  }, [isLoggedIn]);
};
