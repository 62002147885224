import { SpecificationsListType } from '../../types/spec';
import { RootStateType } from '../store';
import { TAtVersions } from './interface';
import { TInitialState } from './types';

export const getSpecsState = (state: RootStateType): TInitialState =>
  state.specs;
export const getSpecRequestData = (
  state: RootStateType,
): Pick<
  TInitialState,
  'limit' | 'page' | 'totalItems' | 'nextToken' | 'prevTokens'
> => {
  const specs = getSpecsState(state);
  return {
    limit: specs.limit,
    page: specs.page,
    totalItems: specs.totalItems,
    nextToken: specs.nextToken,
    prevTokens: specs.prevTokens,
  };
};

// export const getSpecsList = createDraftSafeSelector(getSpecsState, (counties) =>
//   Object.values(
//     counties.entities as {
//       [key: SpecificationsListType["atVersion"]]: SpecificationsListType;
//     }
//   )
// );
export const getSpecsList = (state: RootStateType): SpecificationsListType[] =>
  state.specs.specifications;

export const getAtVersionList = (state: RootStateType): TAtVersions[] =>
  state.specs.atVersions;

export const getSensorSpecVersionList = (state: RootStateType): TAtVersions[] =>
  state.specs.sensorSpecVersions;
