import { combineReducers } from '@reduxjs/toolkit';
import apiReducer from './api/slice';
import deviceDetailsReducer from './deviceDetails/slice';
import devicesReducer from './devices/slice';
import specsReducer from './specs/slice';
import specificationReducer from './specification/slice';
import authenticateSlice from './authenticate/slice';

const rootReducer = combineReducers({
  apiRequests: apiReducer,
  deviceDetails: deviceDetailsReducer,
  devices: devicesReducer,
  specs: specsReducer,
  specification: specificationReducer,
  authenticate: authenticateSlice,
});

export default rootReducer;
