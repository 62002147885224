import React, { useEffect, useState } from 'react';
import Popper from '@material-ui/core/Popper';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import styles from './styles';

const useStyles = makeStyles(styles);

const DropdownMenu = ({
  anchor,
  menuItems,
  placement,
}: DropdownMenuProps): JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<MenuAnchorElementType>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(
      // .parentElement?.parentElement to select whole section
      anchorEl ? null : event.currentTarget.parentElement?.parentElement,
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    document.addEventListener('click', () => handleClose);
    return () => document.removeEventListener('click', handleClose);
  }, [handleClose]);

  return (
    <div className={classes.container}>
      <div
        className={classes.container}
        onClick={handleClick}
        role="button"
        tabIndex={-1}
      >
        {anchor}
      </div>
      <Popper
        anchorEl={anchorEl}
        open={open}
        onClick={handleClose}
        placement={placement}
        // onMouseLeave={handleClose}
      >
        <Box className={classes.wrap}>{menuItems}</Box>
      </Popper>
    </div>
  );
};

type MenuAnchorElementType = Parameters<typeof Popper>[0]['anchorEl'];

type DropdownMenuProps = {
  menuItems: React.ReactNode;
  anchor: React.ReactNode;
  placement?: Parameters<typeof Popper>[0]['placement'];
};

DropdownMenu.defaultProps = {
  placement: undefined,
};

export default DropdownMenu;
