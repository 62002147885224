import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import TextFormField from '../../../components/formElements/TextFormField';
import { Control, useWatch } from 'react-hook-form';
import SelectField from '../../../components/formElements/SelectField';
import { useSelector } from 'react-redux';
import {
  getAtVersionList,
  getSensorSpecVersionList,
} from '../../../redux/specs/selectors';
import { makeStyles } from '@material-ui/core';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { getFieldNameWithPrefix } from '../../../utils/forms';
import FileDropZone from '../../formElements/FileDropZone';
import Box from '@mui/material/Box';
import FirmwareDescription from './FirmwareDescription';
import styles from '../DeviceFields/styles';
import { getIsSensorSpecificationRequired } from '../../../redux/deviceDetails/selectors';

const useStyles = makeStyles(styles);

const FirmawareDetailsFields = ({
  control,
  withSectionLabel,
  onlyView,
  prefix,
  fileLink,
  firmwareName,
  isAddDeviceModal,
}: FirmawareDetailsFieldsProps): JSX.Element => {
  const classes = useStyles();
  const atVersionsList = useSelector(getAtVersionList);
  const sensorSpecVersionsList = useSelector(getSensorSpecVersionList);

  const isSensorSpecificationRequiredFromDevice = useSelector(
    getIsSensorSpecificationRequired,
  );

  const isSensorSpecificationRequiredFromForm = useWatch({
    control,
    name: 'isSensorSpecificationRequired',
  });

  const isSensorSpecificationRequired = isAddDeviceModal
    ? isSensorSpecificationRequiredFromForm
    : isSensorSpecificationRequiredFromDevice;

  const publishedAtVersions = useMemo(() => {
    return atVersionsList.filter((item) => item.status === 'PUBLISHED');
  }, [atVersionsList]);

  const publishedSensorSpecVersions = useMemo(() => {
    return sensorSpecVersionsList.filter((item) => item.status === 'PUBLISHED');
  }, [sensorSpecVersionsList]);

  const Upload = (): JSX.Element => (
    <FileDropZone
      control={control}
      name={getFieldNameWithPrefix('firmwareFile', prefix)}
      filename={firmwareName}
      label=""
    />
  );

  const File = () => (
    <div className={classes.imgWrap}>
      {fileLink ? (
        <Typography>
          <a href={fileLink}>
            <UploadFileIcon style={{ fontSize: '67px' }} />
          </a>
        </Typography>
      ) : (
        <Typography>No file</Typography>
      )}
    </div>
  );

  return (
    <Box
      display="grid"
      gridTemplateColumns={'32% 32% 32%'}
      rowGap={3}
      columnGap={2}
    >
      {withSectionLabel && (
        <Box gridRow={1} gridColumn={1 / 3}>
          <Typography color="primary" variant="h6">
            Firmware Details:
          </Typography>
        </Box>
      )}

      <Box gridColumn={1} gridRow={2}>
        <TextFormField
          disabled={onlyView}
          name={getFieldNameWithPrefix('firmwareVersion', prefix)}
          label="Firmware version"
          control={control}
          placeholder="e.g. 3.0.0-p1_final.42"
          tooltipText="Device firmware version as reported by ATC+PRD_INFO if available, or AT+VER."
        />
      </Box>

      <Box gridColumn={2} gridRow={2}>
        <TextFormField
          disabled={onlyView}
          name={getFieldNameWithPrefix('releaseDate', prefix)}
          label="Release date"
          control={control}
          type="date"
        />
      </Box>
      <Box gridColumn={3} gridRow={2}>
        {onlyView ? (
          <TextFormField
            disabled={onlyView}
            name={getFieldNameWithPrefix('atVersion', prefix)}
            label="Related AT version"
            control={control}
          />
        ) : (
          <SelectField
            disabled={onlyView}
            name={getFieldNameWithPrefix('atVersionId', prefix)}
            label="Related AT version"
            control={control}
            options={publishedAtVersions.map((item) =>
              Object({
                label: `V ${item.atVersion}`,
                value: item.id,
              }),
            )}
          />
        )}
      </Box>

      {isSensorSpecificationRequired && (
        <Box gridColumn={3} gridRow={3}>
          {onlyView ? (
            <TextFormField
              disabled={onlyView}
              name={getFieldNameWithPrefix('sensorDataVersion', prefix)}
              label="Related sensor specification"
              control={control}
              shouldUnregister
            />
          ) : (
            <SelectField
              name={getFieldNameWithPrefix('sensorDataVersionId', prefix)}
              label="Related sensor specification"
              control={control}
              shouldUnregister
              options={publishedSensorSpecVersions.map((item) =>
                Object({
                  label: `V ${item.atVersion}`,
                  value: item.id,
                }),
              )}
            />
          )}
        </Box>
      )}

      <Box gridColumn={1} gridRow={3}>
        <TextFormField
          disabled
          multiline
          name={getFieldNameWithPrefix('firmwareFile', prefix)}
          label={onlyView ? 'Firmware file' : 'Upload firmware file'}
          control={control}
          InputProps={{
            className: classes.input,
            components: { Input: onlyView ? File : Upload },
          }}
        />
      </Box>

      <Box gridColumn={2} gridRow={3}>
        {onlyView && (
          <TextFormField
            disabled
            name={getFieldNameWithPrefix('createdAt', prefix)}
            label="Date added"
            control={control}
          />
        )}
      </Box>
      <Box gridRow={5} gridColumn={'1 / 4'}>
        <FirmwareDescription
          control={control}
          onlyView={onlyView}
          isAddDeviceModal={isAddDeviceModal}
        />
      </Box>
    </Box>
  );
};

type FirmawareDetailsFieldsProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  withSectionLabel?: boolean;
  onlyView?: boolean;
  prefix?: string | number;
  fileLink?: string;
  firmwareName?: string;
  isAddDeviceModal?: boolean;
};

FirmawareDetailsFields.defaultProps = {
  withSectionLabel: false,
  onlyView: false,
  prefix: undefined,
  fileLink: undefined,
};

export default FirmawareDetailsFields;
