import React, { useCallback } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { ReactComponent as RakIdLogo } from '../../assets/RakIdLogoSvg.svg';
import { generateLoginUrl } from './utils';

import styles from './styles';

const useStyles = makeStyles(styles);

const { REACT_APP_RAK_ID_REDIRECT_URL } = process.env;

const Login = (): JSX.Element => {
  const classes = useStyles();

  const handleLogin = useCallback(() => {
    const url = generateLoginUrl();

    window.location.assign(url.toString());
  }, []);

  return (
    <div className={classes.loginPage}>
      <div className={classes.leftSide}>
        <RakIdLogo className={classes.logoIcon} />
      </div>
      <div className={classes.rightSide}>
        <div className={classes.title}>WisToolBox Admin Panel</div>
        <div className={classes.subtitle}>
          WisToolBox Admin Panel is used for adding support for new{' '}
          <span className={classes.bold}>RAK devices, Firmware versions</span>{' '}
          and <span className={classes.bold}>AT specification versions.</span>
        </div>
        <Button
          variant="contained"
          className={classes.button}
          onClick={handleLogin}
        >
          sign in with rak id
        </Button>
        <div className={classes.hint}>
          If you don't have the <span className={classes.bold}>RAK ID</span> yet
          - ask your{' '}
          <span className={classes.bold}>Support Team Coordinator</span> for
          one. Learn more about{' '}
          <a href={`${REACT_APP_RAK_ID_REDIRECT_URL}`}>RAK ID.</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
