import React, { ReactNode } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import styled from '@mui/system/styled';
import { Button, ButtonProps } from '@material-ui/core';

const StyledModal = styled(Modal)(() => ({
  borderRadius: '4px',
}));

const Title = styled('div')(() => ({
  letterSpacing: '1px',
  textTransform: 'uppercase',
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '16px',
  fontFamily: 'RobotoRegular',
}));

const SubTitle = styled('div')(() => ({
  letterSpacing: '0.4px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',
  fontFamily: 'RobotoRegular',
}));

const StyledButtonLeft = styled(Button)(() => ({
  background: '#00AD5A',
  borderRadius: '4px',
  color: 'white',
  width: '47%',
  '&:hover': {
    background: 'rgba(0, 173, 90, 0.6)',
  },
}));

const StyledButtonRight = styled(Button)(() => ({
  background: 'rgba(148, 38, 38, 0.7)',
  borderRadius: '4px',
  color: 'white',
  width: '47%',
  '&:hover': {
    background: 'rgba(148, 38, 38, 0.5)',
  },
}));

const ButtonsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'singleButton',
})(({ singleButton }: { singleButton: boolean | undefined }) => ({
  display: 'flex',
  justifyContent: singleButton ? 'center' : 'space-between',
}));

const StyledBox = styled(Box)(() => ({
  padding: '25px',
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  display: 'flex',
  flexDirection: 'column' as const,
  transform: 'translate(-50%, -50%)',
  minWidth: '800px',
  maxWidth: '1342px',
  maxHeight: '900px',
  background: 'white',
  borderRadius: '10px',
  '&:focus': {
    outline: 'none',
  },
}));

const ModalWithHint = ({
  headerText,
  children,
  subtitle,
  buttonsProps,
  singleButton,
}: CustomModalProps): JSX.Element => {
  const { leftButton, rightButton } = buttonsProps;
  return (
    <StyledModal disableEnforceFocus open disableScrollLock>
      <StyledBox>
        <div style={{ marginBottom: '18px' }}>
          <Title>{headerText}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </div>
        {children && (
          <div
            style={{
              minHeight: '450px',
              overflow: 'scroll',
            }}
          >
            {children}
          </div>
        )}
        <ButtonsWrapper singleButton={singleButton}>
          {!singleButton && <StyledButtonLeft {...leftButton} />}
          <StyledButtonRight {...rightButton}>Cancel</StyledButtonRight>
        </ButtonsWrapper>
      </StyledBox>
    </StyledModal>
  );
};

type CustomModalProps = {
  headerText: string;
  children?: React.ReactNode;
  subtitle: ReactNode | string;
  buttonsProps: { leftButton: ButtonProps; rightButton: ButtonProps };
  singleButton?: boolean;
};

export default ModalWithHint;
