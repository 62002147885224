import React from 'react';

export const LogOutIcon = ({
  color = 'white',
}: {
  color?: string;
}): JSX.Element => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_43_1704)">
        <path
          d="M5.5 9H15.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.5 5L15.5 9L11.5 13"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 16H1.5C0.948 16 0.5 15.552 0.5 15V3C0.5 2.448 0.948 2 1.5 2H8.5"
          stroke={color}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_43_1704">
          <rect
            width="16"
            height="16"
            fill={color}
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
