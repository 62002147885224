import { CSSProperties } from '@material-ui/core/styles/withStyles';

export interface StylesObjectType {
  [key: string]: CSSProperties;
}

export enum UserRoles {
  WisToolBoxAdminRole = 'WisToolBoxAdminRole',
}

export interface IUserProfileDTO {
  email: string;
  sub: string;
  dateFormat: string;
  connectedProducts: [
    {
      name: string;
      url: string;
      clientId: string;
      roles: [];
    },
  ];
  roles: UserRoles[];
}
