import React from 'react';
import { Control } from 'react-hook-form';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';
import { makeStyles } from '@material-ui/core';
import { Typography } from '@mui/material';
import DropZoneField from '../DropZoneField';

import styles from './styles';

const useStyles = makeStyles(styles);

const PhotoDropZone = ({
  name,
  control,
  label,
  disabled,
  isEdit,
}: PhotoDropZoneProps): JSX.Element => {
  const classes = useStyles();

  return (
    <DropZoneField
      name={name}
      control={control}
      icon={PhotoSizeSelectActualOutlinedIcon}
      label={label}
      disabled={disabled}
      isEdit={isEdit}
    >
      <div className={classes.contentWrap}>
        <Typography color={isEdit ? 'black' : 'white'}>{label}</Typography>
      </div>
    </DropZoneField>
  );
};

type PhotoDropZoneProps = {
  name: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  disabled?: boolean;
  isEdit?: boolean;
};

export default PhotoDropZone;
