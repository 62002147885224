import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    emptyState: { margin: '0 auto', width: '80%', color: '#000' },
    dropZone: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '32px',
      borderRadius: '4px',
      cursor: 'pointer',
      background: '#006AC6',
      margin: '0 auto',
      padding: '0 16px',
      color: 'white',
    },
    labelWrap: {
      display: 'flex',
      alignItems: 'center',
      wordBreak: 'break-all' as const,
    },

    icon: {
      transform: 'scale(1.5)',
    },
    disabled: {
      background: 'rgba(0, 106, 198, 0.5)',
    },
  };
}
