import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import CustomModal from '../CustomModal';

const ConfirmModal = ({
  children,
  headerText,
  handleConfirm,
  confirmText,
  handleCancel,
  cancelText,
}: ConfirmModalProps): JSX.Element => {
  return (
    <CustomModal
      headerText={headerText}
      buttonsBlock={
        <ButtonGroup variant="contained">
          <Button onClick={handleConfirm}>{confirmText}</Button>
          <Button color="error" onClick={handleCancel}>
            {cancelText}
          </Button>
        </ButtonGroup>
      }
    >
      {children}
    </CustomModal>
  );
};

type ConfirmModalProps = {
  children: React.ReactNode;
  headerText: string;
  handleConfirm: () => void;
  confirmText?: string;
  handleCancel: () => void;
  cancelText?: string;
};

ConfirmModal.defaultProps = {
  confirmText: 'Confirm',
  cancelText: 'Cancel',
};

export default ConfirmModal;
