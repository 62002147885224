import React from 'react';
import { makeStyles } from '@material-ui/core';
import styles from './styles';

interface Props {
  items: string[];
}

const useStyles = makeStyles(styles);

const HardwareModelItems: React.FC<Props> = ({ items }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      {items.map((item) => (
        <div className={classes.item} key={item}>
          {item}
        </div>
      ))}
    </div>
  );
};

export default HardwareModelItems;
