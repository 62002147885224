import React, { ReactNode, useMemo, useState } from 'react';
import cn from 'classnames';
import { makeStyles, Typography } from '@material-ui/core';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logOut } from '../../../redux/authenticate/slice';
import styles from './styles';
import { LogOutIcon } from '../../ui-icons/NavBarIcons/LogOutIcon';
import { SpecPageIcon } from '../../ui-icons/NavBarIcons/SpecPageIcon';
import { DevicePageIcon } from '../../ui-icons/NavBarIcons/DevicePageIcon';
import { ROUTES } from '../../../app/AppRoutes/routes';
import { resetSession } from '../../../utils/session';
import ModalWithHint from '../../../components/modals/ModalWithHint';
import Divider from '../Divider/Divider';

type SideBarItemsType = {
  url: string;
  title: string;
  onClick?: () => void;
  className?: boolean;
  Icon: ReactNode;
};

const useStyles = makeStyles(styles);

const { DEVICES, AT_SPECIFICATION, SENSOR_SPECIFICATION } = ROUTES;

const Sidebar = (): JSX.Element => {
  const classes = useStyles();
  const [signOutModalOpen, setSignOutModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleLogOutClick = () => {
    setSignOutModalOpen(true);
  };

  const SIDEBAR_ITEM: SideBarItemsType[] = useMemo(
    () => [
      {
        title: 'Devices',
        url: DEVICES,
        Icon: <DevicePageIcon />,
      },
      {
        title: 'AT Specifications',
        url: AT_SPECIFICATION,
        Icon: <SpecPageIcon color="#747474" />,
      },
      {
        title: 'Sensor Specifications',
        url: SENSOR_SPECIFICATION,
        Icon: <SpecPageIcon color="#747474" />,
      },
    ],
    [],
  );
  const SIGN_OUT = {
    title: 'Sign Out',
    onClick: handleLogOutClick,
    className: true,
    Icon: <LogOutIcon color="#747474" />,
  };
  const handleCloseModal = () => {
    setSignOutModalOpen(false);
  };

  const handleSubmitLogOut = () => {
    dispatch(logOut());
    resetSession();
    handleCloseModal();
    navigate(ROUTES.ROOT, { replace: true });
  };

  return (
    <div className={classes.wrap}>
      <div>
        {signOutModalOpen && (
          <ModalWithHint
            headerText="Sign Out"
            subtitle={
              <div className={classes.subtitle}>
                <Divider />
                Are you sure you want to{' '}
                <span className="bold-text">Sign Out</span> from{' '}
                <span className="bold-text ">WisTooBox Admin Panel?</span>
              </div>
            }
            buttonsProps={{
              leftButton: { children: 'SIGN OUT', onClick: handleSubmitLogOut },
              rightButton: { children: 'CANCEL', onClick: handleCloseModal },
            }}
          />
        )}
        {SIDEBAR_ITEM.map(({ title, url, onClick, Icon }) => (
          <NavLink
            key={title}
            to={url}
            className={cn(classes.item, {
              [classes.active]: pathname.startsWith(url),
            })}
            onClick={() => onClick?.()}
          >
            <div className={classes.title}>
              <div className={classes.Icon}>{Icon}</div>
              <Typography variant="button">{title}</Typography>
            </div>
          </NavLink>
        ))}
      </div>
      <div
        className={cn({
          [classes.logOut]: SIGN_OUT.className,
        })}
        onClick={() => SIGN_OUT.onClick?.()}
      >
        <div className={cn(classes.title)}>
          <div className={classes.Icon}>{SIGN_OUT.Icon}</div>
          <Typography variant="button">{SIGN_OUT.title}</Typography>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
