import React, { ReactNode } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid';
import { format } from 'date-fns';
import SpecSupportedDevices from './SpecSupportedDevices';
import { DataGridCell } from '../../../../components/ui/DataGridCell/DataGridCell';
import { FirmwareFileIcon } from '../../../../components/ui-icons/FirmwareFileIcon';
import { FileIcon } from '../../../../components/ui-icons/FileIcon';
import { DeviceStatus } from '../../../../types/device';
import { CalendarIcon } from '../../../../components/ui-icons/CalendarIcon';
import { DevicePageIcon } from '../../../../components/ui-icons/NavBarIcons/DevicePageIcon';

export const columns: GridColDef[] = [
  {
    field: 'atVersion',
    headerName: 'Specification',
    width: 210,
  },
  {
    field: 'status',
    headerName: 'Release state',
    width: 220,
    sortable: false,
    renderCell: (params: GridValueGetterParams) => (
      <DataGridCell
        text={params.row.status}
        Icon={
          params.row.status === DeviceStatus.PUBLISHED ? (
            <FirmwareFileIcon />
          ) : (
            <FileIcon />
          )
        }
      />
    ),
  },
  {
    field: 'supportedDevices',
    headerName: 'Supported devices',
    renderCell: (params: GridRenderCellParams): ReactNode => {
      const icon = params.value.length > 0 ? <DevicePageIcon /> : undefined;
      const text =
        params.value.length > 0 ? (
          <SpecSupportedDevices supportedDevices={params.value} />
        ) : undefined;
      return <DataGridCell text={text} Icon={icon} />;
    },

    width: 220,
    sortable: false,
  },
  {
    field: 'createdAt',
    headerName: 'Date created UTC',
    renderCell: (params: GridValueGetterParams) => (
      <DataGridCell
        text={format(new Date(params.row.createdAt), 'yyyy/MM/dd, hh:mm:ss')}
        Icon={<CalendarIcon />}
      />
    ),
    width: 230,
  },
  {
    field: 'modifiedDate',
    headerName: 'Date edited UTC',
    renderCell: (params: GridValueGetterParams) => (
      <DataGridCell
        text={format(new Date(params.row.modifiedDate), 'yyyy/MM/dd, hh:mm:ss')}
        Icon={<CalendarIcon />}
      />
    ),

    width: 230,
  },
];

export const SORT_OPTION_SPECIFICATION = 'sortOptionSpecification';
