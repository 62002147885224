import React from 'react';

import { Typography } from '@mui/material';
import SelectField from '../../../../formElements/SelectField';
import SwitchField from '../../../../formElements/SwitchField';

import TextFormField from '../../../../formElements/TextFormField';
import { AtCommandSectionsGEneralProps } from '../../types';
import Box from '@mui/material/Box';
import { getTooltipText } from './utils';

const uiElementsAvailable = [
  'Input',
  'Textfield',
  'Toggle',
  'HexField',
  'Stepper',
  'Switcher',
  'InputSlider',
  'Slider',
  'Dropdown',
];

export const UiElementSettings = ({
  control,
  onlyView,
  withTooltips,
}: AtCommandSectionsGEneralProps): JSX.Element => {
  const {
    title,
    elements,
    position,
    conditionalDependency,
    formatUI,
    inActive,
    copiedField,
    isShowCounter,
    symbol,
    delimiter,
    defaultTemplateValue,
  } = getTooltipText(!!withTooltips);
  return (
    <Box
      display="grid"
      gridTemplateColumns={'35% 5% 60%'}
      gridTemplateRows={'15% 85%'}
    >
      <Box gridColumn={1} gridRow={1}>
        <Typography color="primary" variant="h6">
          Ui Element Settings:
        </Typography>
      </Box>
      <Box gridColumn={1} gridRow={2 / 4} display="grid" rowGap={3}>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="title"
            label="Field Title"
            control={control}
            tooltipText={title}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="identifier"
            label="Command identifier"
            control={control}
          />
        </Box>
        <Box>
          <SelectField
            disabled={onlyView}
            name="elements"
            label="Field UI element"
            control={control}
            options={uiElementsAvailable.map((item) =>
              Object({ label: item, value: item }),
            )}
            tooltipText={elements}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="position"
            label="Position on UI"
            control={control}
            type="number"
            tooltipText={position}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="conditionalDependency"
            label="Conditional field dependency"
            control={control}
            tooltipText={conditionalDependency}
          />
        </Box>
        <Box>
          <TextFormField
            disabled={onlyView}
            name="formatUI"
            label="Format UI"
            control={control}
            tooltipText={formatUI}
          />
        </Box>
      </Box>
      <Box></Box>

      <Box
        style={{ background: '#F0F5FF', padding: '8px 16px', height: 225 }}
        display="grid"
        gridTemplateColumns={'25% 20% 5% 20% 25%'}
        gridTemplateRows={'10% 20% 36.6%'}
      >
        <Box gridColumn="span 1">
          <Typography variant="body1">UI SETTINGS</Typography>
        </Box>
        <Box gridColumn="1">
          <SwitchField
            disabled={onlyView}
            name="inActive"
            label="Inactive on UI"
            control={control}
            tooltipText={inActive}
          />
        </Box>
        <Box gridColumn="2 / 4">
          <SwitchField
            disabled={onlyView}
            name="copiedField"
            label="Copied field"
            control={control}
            tooltipText={copiedField}
          />
        </Box>
        <Box gridColumn="4 / 6">
          <SwitchField
            disabled={onlyView}
            name="isShowCounter"
            label="Show counter"
            control={control}
            tooltipText={isShowCounter}
          />
        </Box>
        <Box gridColumn="1 / 3">
          <SwitchField
            disabled={onlyView}
            name="symbol"
            label="Payload delimiter"
            control={control}
            tooltipText={symbol}
          />

          <TextFormField
            disabled={onlyView}
            name="delimiter.symbol"
            label="Delimiter"
            control={control}
            tooltipText={delimiter}
          />
        </Box>
        <Box
          gridColumn="4 / 6"
          gridRow="3"
          display="flex"
          alignItems="flex-end"
        >
          <TextFormField
            disabled={onlyView}
            name="defaultTemplateValue"
            label="Default value in Templates"
            control={control}
            tooltipText={defaultTemplateValue}
          />
        </Box>
      </Box>
    </Box>
  );
};
