import { all, spawn } from 'redux-saga/effects';
import { deviceDetailsWatcherSaga } from './deviceDetails/sagas';
import { devicesWatcherSaga } from './devices/sagas';
import { specificationWatcherSaga } from './specification/sagas';
import { specsWatcherSaga } from './specs/sagas';

export default function* rootSaga(): Generator {
  yield all([
    spawn(deviceDetailsWatcherSaga),
    spawn(devicesWatcherSaga),
    spawn(specsWatcherSaga),
    spawn(specificationWatcherSaga),
  ]);
}
