import { isLocalDev } from '../isLocalDev';

import Cookie from './Cookies';

const { REACT_APP_REDIRECT_URI: adminUrl } = process.env;

const domain = isLocalDev ? undefined : adminUrl;

class AppCookie extends Cookie {
  constructor() {
    super(domain as string);
  }
}

const appCookie = new AppCookie();

export default appCookie;
