export type SupportedDevicesType = {
  modelId: string;
  firmwareVersions: string[];
};

export enum Calculation {
  'ADDITION' = 'ADDITION',
  'SUBTRACTION' = 'SUBTRACTION',
  'DIVISION' = 'DIVISION',
  'MULTIPLICATION' = 'MULTIPLICATION',
  'INCREMENT' = 'INCREMENT',
  'DECREMENT' = 'DECREMENT',
}

export enum FormatUITypes {
  READ = 'READ',
  WRITE = 'WRITE',
}

export interface FormatUI {
  type: FormatUITypes;
  operator: Calculation;
  operand: string;
}

export type SpecificationsListType = {
  id: string;
  atVersion: string;
  aliasName?: string;
  modifiedDate: string;
  createdAt: string;
  status: 'DRAFT' | 'PUBLISHED';
  supportedDevices: SupportedDevicesType[];
};

export type SpecShortType = {
  nextToken: string | null;
  specifications: SpecificationsListType[];
  quantity: number;
};
export enum ShowOnValues {
  TEMPLATE_PAGE = 'TEMPLATE_PAGE',
  DEVICE_PARAMETERS_PAGE = 'DEVICE_PARAMETERS_PAGE',
  SENSOR_PROBE_PAGE = 'SENSOR_PROBE_PAGE',
  DEVICE_INFO_PAGE = 'DEVICE_INFO_PAGE',
}

export enum ShownOnType {
  DEVICE_INFO_PAGE = 'isShownOnDeviceInfo',
  TEMPLATE_PAGE = 'isShowTemplate',
  DEVICE_PARAMETERS_PAGE = 'isShownOnDeviceParameters',
  SENSOR_PROBE_PAGE = 'isShowSensorProb',
}

export enum allowValuesRangesType {
  VALUES_RANGES = 'VALUES_RANGES',
  SINGLE_CHANNEL_MODE_VALUES = 'SINGLE_CHANNEL_MODE_VALUES',
  EIGHT_CHANNEL_MODE_VALUES = 'EIGHT_CHANNEL_MODE_VALUES',
}

export type ValuesRangesAllowValuesRanges = {
  type: allowValuesRangesType.VALUES_RANGES;
} & {
  data: {
    region: string;
    min: string;
    max: string;
  }[];
};

export type SingleChannelModeAllowValuesRanges = {
  type: allowValuesRangesType.SINGLE_CHANNEL_MODE_VALUES;
} & {
  data: {
    region: string;
    data: {
      min: number;
      max: number;
      startVal: number;
      increment: number;
    }[];
  }[];
};
export type EightChannelModeAllowValuesRanges = {
  type: allowValuesRangesType.EIGHT_CHANNEL_MODE_VALUES;
} & {
  data: {
    region: string;
    data: {
      displayValue: string;
      sendValue: string;
    }[];
  }[];
};

export type AllowValuesRanges =
  | ValuesRangesAllowValuesRanges
  | SingleChannelModeAllowValuesRanges
  | EightChannelModeAllowValuesRanges;

// export interface readedValueFromDeviceType {
//   returnValName: string;
//   value: string;
//   id: string;
// }

export interface AllowValuesType {
  // TODO is copiedField right type?
  copiedField?: string;
  displayValue: string;
  sendValue?: string;
}

export interface Validation {
  max: string | null;
  maxLength: string | null;
  minLength: string | null;
  exactLength: string | null;
  min: string | null;
  pattern: string | null;
  regexp: string | null;
}

export interface UiElement {
  shownOn: ShowOnValues[] | ShownOnType[];
  allowValues: Array<AllowValuesType>;
  copiedField: boolean;
  id: number;
  inActive: boolean;
  default: string | null;
  conditionalDependency: string[] | null;
  valueDependOn: string | null;
  allowValuesRanges: AllowValuesRanges | null;
  elements: string[] | string;
  identifier: string;
  initialValue: string | null;
  isReadOnly: boolean;
  position: number;
  title: string;
  type: string | null;
  isShowCounter: boolean | null;
  delimiter:
    | {
        symbol: string;
        quantity: number;
      }
    | string;
  validation: Validation;
  formatUI: FormatUI[];
  dependOn: string[];
}

export interface UpdatedUiElement {
  shownOn: ShownOnType[] | ShowOnValues[];
  allowValues: Array<AllowValuesType>;
  copiedField: boolean;
  id: number;
  inActive: boolean;
  default: string | null;
  conditionalDependency: string[] | null;
  valueDependOn: string | null;
  allowValuesRanges: AllowValuesRanges | null;
  elements: string[];
  identifier: string;
  initialValue: string | null;
  isReadOnly: boolean;
  position: number;
  title: string;
  type: string | null;
  isShowCounter: boolean | null;
  delimiter: string;
  validation: Validation;
  formatUI: FormatUI[];
  dependOn: string[];
}

export interface ProdSpecificationSectionsAtCommandsElement {
  shownOn: ShowOnValues[] | ShownOnType[];
  command: string;
  isReadOnly: boolean;
  returnValue: string;
  readSeparator: string | null;
  writeSeparator: string | null;
  accordionId: number | null;
  accordionTitle: string | null;
  isShowTemplate: boolean;
  isShownOnDeviceParameters: boolean;
  position: number;
  readCommand: string;
  title: string;
  writeCommand: string;
  id: string | number | null;
  uiElements: Array<UiElement>;
  dependOn: string[];
  queue?: boolean;
}

export type SpecificationSectionType = {
  id: string;
  dependOn: string[];
  conditionalDependency: string[];
  sectionId: string;
  position: number;
  hint: string;
  title: string;
  atCommands: ProdSpecificationSectionsAtCommandsElement[];
  identifier: string;
  isShowTemplate: boolean;
  shownOn: ShowOnValues[];
};

export type DeleteAtCommandType = {
  sectionId: string;
  atCommandId: string;
};

export type DeleteUIElementType = {
  sectionId: string;
  atCommandId: string;
  uiElementId: string;
};

export type SpecDetailsType = {
  id: string;
  atVersion: string;
  aliasName?: string;
  modifiedDate: string;
  createdAt: string;
  sections: SpecificationSectionType[];
};

export type TCreateSectionData = {
  shownOn: string[];
  identifier: string;
  isShowTemplate: boolean;
  position: number;
  title: string;
  hint?: string;
  dependOn?: string[];
  conditionalDependency?: string[];
};

// from the back-end
export const enum SpecificationType {
  AT_COMMAND = 'AT_COMMAND',
  SENSOR_DATA = 'SENSOR_DATA',
  ASYNC_RESPONSE = 'ASYNC_RESPONSE',
}
