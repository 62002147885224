import React, { useRef, useState } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import DeviceFields from '../../../components/forms/DeviceFields';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { updateDevice } from '../../../redux/deviceDetails/actions';
import { getRequestStatus } from '../../../redux/api/selectors';
import types from '../../../redux/deviceDetails/types';
import { RequestStatus } from '../../../redux/api/interface';
import { DeviceDetailsType } from '../../../types/device';
import CustomModal from '../CustomModal';
import { getValidationShema } from '../../../utils/forms/validation';
import { TDeviceDetails } from './interface';
import { prepareFormValues } from './utils';
import { getDevicesList } from '../../../redux/devices/selectors';
import { getDeviceId } from '../../../redux/deviceDetails/selectors';
import { toast } from 'react-toastify';

type TProps = {
  handleClose: () => void;
  deviceData: DeviceDetailsType;
};

const EditDeviceModal = ({ handleClose, deviceData }: TProps): JSX.Element => {
  const [isPublish, setIsPublish] = useState<boolean | null>(null);
  const [showError, setShowError] = useState(false);
  const requestStatus = useSelector(getRequestStatus(types.UPDATE_DEVICE));

  const deviceList = useSelector(getDevicesList);
  const activeDeviceId = useSelector(getDeviceId);

  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { control, handleSubmit, setValue, watch } = useForm<any>({
    resolver: yupResolver(
      getValidationShema(
        isPublish ? 'deviceEdit' : 'deviceDraftEdit',
        isPublish,
      ),
    ),
    mode: 'onSubmit',
    defaultValues: {
      ...deviceData,
      bleName: deviceData.connectionType?.bleName,
      connectionType: deviceData.connectionType?.mode,
      nfcActivationValue: deviceData.connectionType?.nfcActivationValue,
    },
  });

  const { supportedApps, connectionType } = watch();
  const handleSubmitForm = useRef(handleSubmit);
  handleSubmitForm.current = handleSubmit;
  const isSumbiting =
    useSelector(getRequestStatus(types.UPDATE_DEVICE))?.status ===
    RequestStatus.loading;

  const submit = (toPublish: boolean) => (values: TDeviceDetails) => {
    const namesList = deviceList.map((device) => {
      if (!device.productModelName) return true;
      return (device.hardwareModelName + device.productModelName).toLowerCase();
    });

    const currentCombination =
      values.hardwareModelName + values.productModelName;
    if (
      namesList.includes(currentCombination.toLowerCase()) &&
      activeDeviceId !== values.id
    ) {
      setShowError(true);
      return;
    }

    const requestBody = prepareFormValues(values, toPublish);
    setShowError(false);

    dispatch(
      updateDevice({
        data: requestBody,
        uriParams: { deviceId: deviceData.id },
      }),
    )
      .promise?.then(handleClose)
      .catch((response) => {
        toast.error(
          <div>
            {response.errors?.map(
              (error: Record<'errorMessage' | 'errorCode', string>) => (
                <div>{error.errorMessage}</div>
              ),
            )}
          </div>,
          {
            position: 'bottom-center',
            toastId: response.type,
            className: 'toast-lg',
          },
        );
      });
  };

  const submitPublish = submit(true);
  const submitDraft = submit(false);
  return (
    <CustomModal
      headerText="Device details"
      buttonsBlock={
        <ButtonGroup variant="contained">
          <Button
            onClick={() => {
              setIsPublish(true);
              setTimeout(handleSubmitForm.current(submitPublish));
            }}
            disabled={isSumbiting}
          >
            Save and publish
          </Button>
          <Button
            onClick={() => {
              setIsPublish(false);
              setTimeout(handleSubmitForm.current(submitDraft));
            }}
            disabled={isSumbiting}
          >
            Save draft
          </Button>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <DeviceFields
        setValue={setValue}
        onlyView={false}
        control={control}
        isEdit
        connectionType={connectionType}
        supportedApps={supportedApps}
        imageLink={deviceData.image}
        isNotUniqueCombination={showError}
        errors={requestStatus?.errors}
      />
    </CustomModal>
  );
};

export default EditDeviceModal;
