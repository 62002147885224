import React from 'react';

export const FirmwareFileIcon = (): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_43_2160)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.25 1.25H3.75C3.58424 1.25 3.42527 1.31585 3.30806 1.43306C3.19085 1.55027 3.125 1.70924 3.125 1.875V17.5C3.125 17.6658 3.19085 17.8247 3.30806 17.9419C3.42527 18.0592 3.58424 18.125 3.75 18.125H16.25C16.4158 18.125 16.5747 18.0592 16.6919 17.9419C16.8092 17.8247 16.875 17.6658 16.875 17.5V1.875C16.875 1.70924 16.8092 1.55027 16.6919 1.43306C16.5747 1.31585 16.4158 1.25 16.25 1.25Z"
          fill="#00BB61"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.25 1.25H3.75C3.58424 1.25 3.42527 1.31585 3.30806 1.43306C3.19085 1.55027 3.125 1.70924 3.125 1.875V17.5C3.125 17.6658 3.19085 17.8247 3.30806 17.9419C3.42527 18.0592 3.58424 18.125 3.75 18.125H16.25C16.4158 18.125 16.5747 18.0592 16.6919 17.9419C16.8092 17.8247 16.875 17.6658 16.875 17.5V1.875C16.875 1.70924 16.8092 1.55027 16.6919 1.43306C16.5747 1.31585 16.4158 1.25 16.25 1.25Z"
          fill="url(#paint0_linear_43_2160)"
        />
        <g opacity="0.5">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2497 18.125H4.55518L14.7361 1.25H16.2497C16.4154 1.25 16.5744 1.31585 16.6916 1.43306C16.8088 1.55027 16.8747 1.70924 16.8747 1.875V17.5C16.8747 17.6658 16.8088 17.8247 16.6916 17.9419C16.5744 18.0592 16.4154 18.125 16.2497 18.125Z"
            fill="#008645"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.2497 18.125H4.55518L14.7361 1.25H16.2497C16.4154 1.25 16.5744 1.31585 16.6916 1.43306C16.8088 1.55027 16.8747 1.70924 16.8747 1.875V17.5C16.8747 17.6658 16.8088 17.8247 16.6916 17.9419C16.5744 18.0592 16.4154 18.125 16.2497 18.125Z"
            fill="url(#paint1_linear_43_2160)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5016 9.41602C12.5438 9.60783 12.5653 9.80361 12.5657 10C12.5653 10.1964 12.5438 10.3922 12.5016 10.584L13.4268 11.2845L12.745 12.4655L11.6735 12.0134C11.3834 12.2794 11.039 12.4794 10.6641 12.5994L10.5202 13.75H9.15659L9.01273 12.5994C8.63784 12.4794 8.29346 12.2794 8.0033 12.0134L6.93182 12.4655L6.25 11.2845L7.17523 10.584C7.08979 10.1993 7.08979 9.80065 7.17523 9.41602L6.25 8.71545L6.93182 7.53455L8.0033 7.98659C8.29346 7.72058 8.63784 7.52065 9.01273 7.40057L9.15659 6.25H10.5202L10.6641 7.40057C11.039 7.52065 11.3834 7.72058 11.6735 7.98659L12.745 7.53455L13.4268 8.71545L12.5016 9.41602ZM10.8611 10C10.8611 10.5648 10.4032 11.0227 9.83841 11.0227C9.27357 11.0227 8.81568 10.5648 8.81568 10C8.81568 9.43516 9.27357 8.97727 9.83841 8.97727C10.4032 8.97727 10.8611 9.43516 10.8611 10Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_43_2160"
          x1="10"
          y1="1.6766"
          x2="10"
          y2="19.4231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_43_2160"
          x1="11.7397"
          y1="1.07781"
          x2="11.7397"
          y2="17.5586"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.3" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_43_2160">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
