import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonGroup } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { getValidationShema } from '../../../utils/forms/validation';
import FirmawareDetailsFields from '../../../components/forms/FirmawareDetailsFields';
import { getRequestStatus } from '../../../redux/api/selectors';
import types from '../../../redux/deviceDetails/types';
import { RequestStatus } from '../../../redux/api/interface';
import { DeviceShortType, FirmwareType } from '../../../types/device';

import CustomModal from '../CustomModal';
import {
  createFirmware,
  updateFirmware,
} from '../../../redux/deviceDetails/actions';
import { AddFirmwareModalFormValues } from './interface';
import { prepareFormValues } from './utils';
import { getAtVersionList } from '../../../redux/specs/selectors';
import {
  getIsSensorSpecificationRequired,
  isDraftDevice,
} from '../../../redux/deviceDetails/selectors';

const FirmwareModal = ({
  handleClose,
  deviceId,
  firmwareId,
  isEdit,
  initialValues,
}: FirmwareModalProps): JSX.Element => {
  const [isPublish, setIsPublish] = useState<boolean | null>(null);

  const dispatch = useDispatch();
  const atVersionsList = useSelector(getAtVersionList);
  const isSensorSpecificationRequired = useSelector(
    getIsSensorSpecificationRequired,
  );
  const isDraft = useSelector(isDraftDevice);

  const { control, handleSubmit } = useForm<AddFirmwareModalFormValues>({
    defaultValues: {
      ...initialValues,
      firmwareFile: initialValues
        ? new File(
            [],
            initialValues?.firmwareName || '',
            isEdit ? { type: 'application/zip' } : undefined,
          )
        : undefined,
    },
    resolver: yupResolver(
      getValidationShema(
        isEdit ? 'firmwaresEdit' : 'firmwares',
        isPublish,
        isSensorSpecificationRequired && !isDraft,
      ),
    ),
    mode: 'onSubmit',
  });

  const handleSubmitForm = useRef(handleSubmit);
  handleSubmitForm.current = handleSubmit;

  const isSumbiting =
    useSelector(
      getRequestStatus(isEdit ? types.CREATE_FIRMWARE : types.CREATE_FIRMWARE),
    )?.status === RequestStatus.loading;

  const getSubmit =
    (toPublish: boolean) => (values: AddFirmwareModalFormValues) => {
      const valuesWithProperAtVersionId = {
        ...values,
        atVersion:
          // put id instead of label
          atVersionsList.find((vs) => vs.id === values.atVersionId)?.id ??
          values.atVersionId,
      };
      const requestBody = prepareFormValues(
        valuesWithProperAtVersionId,
        toPublish,
      );
      dispatch(
        isEdit
          ? updateFirmware({
              data: requestBody,
              uriParams: { deviceId, firmwareId: firmwareId ?? '' },
            })
          : createFirmware({
              data: requestBody,
              uriParams: { deviceId },
            }),
      )
        .promise?.then(handleClose)
        .catch((response) => {
          toast.error(
            <div>
              {response.errors?.map(
                (error: Record<'errorMessage' | 'errorCode', string>) => (
                  <div>{error.errorMessage}</div>
                ),
              )}
            </div>,
            {
              position: 'bottom-center',
              toastId: response.type,
              className: 'toast-lg',
            },
          );
        });
    };

  const sumbitPublish = getSubmit(true);
  const submitDraft = getSubmit(false);

  return (
    <CustomModal
      headerText={isEdit ? 'Edit firmware' : 'Add new version'}
      buttonsBlock={
        <ButtonGroup variant="contained">
          <Button
            disabled={isSumbiting}
            onClick={() => {
              setIsPublish(true);
              setTimeout(handleSubmitForm.current(sumbitPublish));
            }}
          >
            Save and publish
          </Button>
          <Button
            disabled={isSumbiting}
            onClick={() => {
              setIsPublish(false);
              setTimeout(handleSubmitForm.current(submitDraft));
            }}
          >
            Save draft
          </Button>
          <Button color="error" onClick={handleClose}>
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <FirmawareDetailsFields
        control={control}
        fileLink={initialValues?.location}
        firmwareName={initialValues?.firmwareName}
      />
    </CustomModal>
  );
};

type FirmwareModalProps = {
  handleClose: () => unknown;
  deviceId: DeviceShortType['id'];
  firmwareId?: FirmwareType['id'];
  isEdit?: boolean;
  initialValues?: Partial<FirmwareType>;
};

FirmwareModal.defaultProps = {
  isEdit: false,
  firmwareId: undefined,
  initialValues: {},
};

export default FirmwareModal;
