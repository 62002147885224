import React from 'react';

export const CalendarIcon = (): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 5.5H15.5"
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 2.5H2C1.60218 2.5 1.22064 2.65804 0.93934 2.93934C0.658035 3.22064 0.5 3.60218 0.5 4V13C0.5 13.3978 0.658035 13.7794 0.93934 14.0607C1.22064 14.342 1.60218 14.5 2 14.5H14C14.3978 14.5 14.7794 14.342 15.0607 14.0607C15.342 13.7794 15.5 13.3978 15.5 13V4C15.5 3.60218 15.342 3.22064 15.0607 2.93934C14.7794 2.65804 14.3978 2.5 14 2.5V2.5Z"
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 0.5V2.5"
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 0.5V2.5"
        stroke="#747474"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
