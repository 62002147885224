import pick from 'lodash/pick';

import { AddFirmwareModalFormValues } from './interface';
import omit from 'lodash/omit';

// TODO: check it when BE is ready
export const prepareFormValues = (
  values: AddFirmwareModalFormValues,
  toPublish: boolean,
): FormData => {
  const firmwareExtension = values.firmwareFile?.name.split('.').pop();

  const firmwareFields = pick(
    values,
    'firmwareVersion',
    'atVersionId',
    'addedDescription',
    'changedDescription',
    'fixedDescription',
    'releaseDate',
    'sensorDataVersionId',
  );

  const omittedFirmwareFields = omit(firmwareFields, 'atVersion');

  const jsonData = {
    ...omittedFirmwareFields,
    releaseDate: new Date(firmwareFields.releaseDate).toISOString(),
    toPublish,
  };

  const formData = new FormData();
  formData.set('jsonData', JSON.stringify(jsonData));
  if (values.firmwareFile instanceof Blob) {
    formData.set(
      'files',
      values.firmwareFile,
      `${values.firmwareVersion}.${firmwareExtension}`,
    );
  }

  return formData;
};
