import React from 'react';
import { GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { DeviceStatus } from '../../../../types/device';
import { format } from 'date-fns';
import { DevicePageIcon } from '../../../../components/ui-icons/NavBarIcons/DevicePageIcon';
import { DataGridCell } from '../../../../components/ui/DataGridCell/DataGridCell';
import { FirmwareFileIcon } from '../../../../components/ui-icons/FirmwareFileIcon';
import { FileIcon } from '../../../../components/ui-icons/FileIcon';
import { CalendarIcon } from '../../../../components/ui-icons/CalendarIcon';

export const columns: GridColDef[] = [
  {
    field: 'modelId',
    headerName: 'Device model',
    width: 270,
    renderCell: (params: GridValueGetterParams) => (
      <DataGridCell
        text={params.row.modelId.toUpperCase()}
        Icon={<DevicePageIcon />}
      />
    ),
  },
  {
    field: 'status',
    headerName: 'Release state',
    width: 270,
    sortingOrder: ['desc', 'asc'],
    renderCell: (params: GridValueGetterParams) => (
      <DataGridCell
        text={params.row.status}
        Icon={
          params.row.status === DeviceStatus.PUBLISHED ? (
            <FirmwareFileIcon />
          ) : (
            <FileIcon />
          )
        }
      />
    ),
  },
  {
    field: 'latestFirmwareVersion',
    headerName: 'Latest firmware versions',
    width: 270,
  },
  {
    field: 'createdAt',
    headerName: 'Date created, UTC',
    renderCell: (params: GridValueGetterParams) => (
      <DataGridCell
        text={format(new Date(params.row.createdAt), 'yyyy/MM/dd, hh:mm:ss')}
        Icon={<CalendarIcon />}
      />
    ),
    type: 'date',
    width: 350,
  },
  {
    field: 'updatedAt',
    headerName: 'Date edited, UTC',
    renderCell: (params: GridValueGetterParams) => (
      <DataGridCell
        text={format(new Date(params.row.updatedAt), 'yyyy/MM/dd, hh:mm:ss')}
        Icon={<CalendarIcon />}
      />
    ),
    type: 'date',
    width: 350,
  },
];

export const SORT_OPTION = 'sortOption';
