import { Theme } from '@mui/material';
import { StylesObjectType } from '../../../types/types';

export default function styles(theme: Theme): StylesObjectType {
  return {
    wrap: {
      backgroundColor: theme.palette.background.paper,
      boxShadow:
        'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
      borderRadius: '4px',
      fontFamily: 'RobotoRegular' as const,
      fontWeight: 400,
      fontSize: '12px',
      color: 'rgb(55, 65, 81)',
      letterSpacing: '0.00938em' as const,
    },
  };
}
