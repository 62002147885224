import React from 'react';
import { styled } from '@mui/material';

const StyledDivider = styled('div')(() => ({
  background: '#C4C4C4',
  border: '1px solid #DDD8D8',
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  margin: '16px 0',
}));

const Divider = (): JSX.Element => <StyledDivider />;

export default Divider;
