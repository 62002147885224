import API_ENDPOINTS from '../../api/endpoints';
import asyncActionCreator from '../../utils/redux/asyncActionCreator';
import { FetchDeviceListResponse } from './interface';
import types from './types';

export const [fetchDeviceList, fetchDeviceListActions] = asyncActionCreator<
  FetchDeviceListResponse,
  undefined,
  undefined,
  undefined
>(types.FETCH_DEVICE_LIST, 'GET', API_ENDPOINTS.GET_DEVICE_LIST);
