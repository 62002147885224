import React from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { useForm } from 'react-hook-form';
import { TCreateSectionData } from '../../../types/spec';
import { yupResolver } from '@hookform/resolvers/yup';
import SpecificationSectionFields from '../../../components/forms/SpecificationSectionFields';
import { useDispatch, useSelector } from 'react-redux';
import { getSpecDetails } from '../../../redux/specification/selectors';
import { createNewSection } from '../../../redux/specification/actions';
import CustomModal from '../CustomModal/index';
import { prepareValues, sectionFieldsValidationSchema } from './utils';

type TProps = {
  handleClose: () => void;
};

const CreateNewSectionsModal = ({ handleClose }: TProps): JSX.Element => {
  const dispatch = useDispatch();
  const specDetails = useSelector(getSpecDetails);
  const { control, handleSubmit } = useForm<TCreateSectionData>({
    mode: 'onSubmit',
    resolver: yupResolver(sectionFieldsValidationSchema),
  });

  const onSubmit = handleSubmit((values: TCreateSectionData) => {
    const preparedValues = prepareValues(values);
    const id = specDetails?.id || '';
    dispatch(
      createNewSection({
        uriParams: { specId: id },
        data: preparedValues,
      }),
    ).promise?.then(handleClose);
  });

  return (
    <CustomModal
      headerText="Add New Section"
      buttonsBlock={
        <ButtonGroup variant="contained">
          <Button onClick={onSubmit}>Save</Button>
          <Button onClick={handleClose} color="error">
            Cancel
          </Button>
        </ButtonGroup>
      }
    >
      <div>
        <SpecificationSectionFields onlyView={false} control={control} />
      </div>
    </CustomModal>
  );
};

export default CreateNewSectionsModal;
