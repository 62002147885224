import { StylesObjectType } from '../../../types/types';

export default function styles(): StylesObjectType {
  return {
    fileText: {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      marginBottom: '8px',
    },
    dropZone: {
      width: '249px',
      border: '2px dashed #264494',
      padding: '33px 53px',
      borderRadius: '4px',
      marginRight: '17px',
    },
    boldText: {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
    },
    buttons: {
      display: 'flex',
      margin: '0 auto',
    },
    divider: {
      width: '11px',
    },
    errorState: {
      color: '#D71E1E',
      fontSize: '16px',
      lineHeight: '28px',
    },
    file: {
      display: 'flex',
      fontSize: '16px',
      lineHeight: '28px',
      letterSpacing: '0.44px',
      alignItems: 'center',
    },
    delete: {
      height: 'fit-content',
    },
  };
}
