import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  ApiErrorsType,
  ApiRequestsInitialStateType,
  ApiResponseErrorType,
  RequestStatus,
} from './interface';

const initialState: ApiRequestsInitialStateType = {};

const apiSlice = createSlice({
  name: 'apiRequests',
  initialState,
  reducers: {
    setRequestStatus(
      state,
      action: PayloadAction<
        {
          type: string;
          status: RequestStatus;
          errors?: ApiErrorsType[];
        } & Partial<ApiResponseErrorType>
      >,
    ) {
      const { type, status, error, errors } = action.payload;

      state[type] = { status, error, errors };
    },
  },
});

export const { actions } = apiSlice;
export default apiSlice.reducer;
