import React from 'react';
import { styled } from '@material-ui/core';

const BoldText = styled('span')(() => ({
  fontWeight: 700,
}));

const NoPermissions = (): JSX.Element => {
  return (
    <div>
      You don’t have permissions to <BoldText>authorize</BoldText> in{' '}
      <BoldText>WisToolBox Admin Panel.</BoldText> Contact your{' '}
      <BoldText>Supervisor</BoldText> to obtain access.
    </div>
  );
};

export default NoPermissions;
