import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DeleteAtCommandType,
  DeleteUIElementType,
  SpecDetailsType,
} from '../../types/spec';
import {
  deleteAtCommandActions,
  deleteSectionOfSpecificationActions,
  deleteUiElementActions,
  fetchSpecificationDetailsActions,
} from './actions';

const initialState: SpecDetailsType = {
  atVersion: '',
  aliasName: '',
  modifiedDate: '',
  createdAt: '',
  sections: [],
  id: '',
};

const specificationSlice = createSlice({
  name: 'specification',
  initialState,
  reducers: {
    createNewSection(state, action) {
      state.sections = [...state.sections, action.payload];
    },
  },
  extraReducers(builder) {
    builder.addCase(
      fetchSpecificationDetailsActions.SUCCESS,
      (
        state,
        action: PayloadAction<{
          data: SpecDetailsType;
          error: unknown | boolean;
        }>,
      ) => {
        const { sections, atVersion, aliasName, modifiedDate, createdAt, id } =
          action.payload.data;
        state.atVersion = atVersion;
        state.aliasName = aliasName;
        state.modifiedDate = modifiedDate;
        state.createdAt = createdAt;
        state.sections = sections;
        state.id = id;
      },
    );
    builder.addCase(
      deleteSectionOfSpecificationActions.SUCCESS,
      (
        state,
        action: PayloadAction<{
          data: { sectionId: string };
          error: unknown | boolean;
        }>,
      ) => {
        state.sections = state.sections.filter((section) => {
          return section.id !== action.payload.data.sectionId;
        });
      },
    );
    builder.addCase(
      deleteAtCommandActions.SUCCESS,
      (
        state,
        action: PayloadAction<{
          data: DeleteAtCommandType;
          error: unknown | boolean;
        }>,
      ) => {
        const { sectionId, atCommandId } = action.payload.data;

        state.sections = state.sections.map((section) => {
          if (section.id === sectionId) {
            return {
              ...section,
              atCommands: section.atCommands.filter(
                (atCommand) => atCommand.id !== atCommandId,
              ),
            };
          }

          return section;
        });
      },
    );
    builder.addCase(
      deleteUiElementActions.SUCCESS,
      (
        state,
        action: PayloadAction<{
          data: DeleteUIElementType;
          error: unknown | boolean;
        }>,
      ) => {
        const { sectionId, atCommandId, uiElementId } = action.payload.data;

        state.sections = state.sections.map((section) => {
          if (section.id === sectionId) {
            return {
              ...section,
              atCommands: section.atCommands.map((atCommand) => {
                if (atCommand.id === atCommandId) {
                  return {
                    ...atCommand,
                    uiElements: atCommand.uiElements.filter(
                      (uiElement) => +uiElement.id !== +uiElementId,
                    ),
                  };
                }

                return atCommand;
              }),
            };
          }

          return section;
        });
      },
    );
  },
});

export default specificationSlice.reducer;
