/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Action, PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { call, put, take, race, spawn } from 'redux-saga/effects';
import makeRequest from '../../api';
import {
  ApiErrorType,
  ApiResponseErrorType,
  AsyncAction,
  RequestStatus,
} from './interface';
import { actions } from './slice';

function* updateRequestStatusSaga(action: AsyncAction<unknown>): any {
  const { type, payload } = action;
  const { dispatch } = payload;
  const { ERROR, START, SUCCESS } = dispatch;

  yield take(START);

  yield put(
    actions.setRequestStatus({
      status: RequestStatus.loading,
      type,
    }),
  );

  const resultStatus: [Action, PayloadAction<ApiErrorType> & any] = yield race([
    take(SUCCESS),
    take(ERROR),
  ]);

  if (resultStatus[0]?.type) {
    yield put(
      actions.setRequestStatus({
        status: RequestStatus.success,
        type,
      }),
    );
    return;
  }

  yield put(
    actions.setRequestStatus({
      status: RequestStatus.error,
      type,
      error: resultStatus[1]?.payload,
      errors: resultStatus[1]?.errors,
    }),
  );
}

type ResolveType = (
  value:
    | { payload: unknown; type: string }
    | PromiseLike<{ payload: unknown; type: string }>,
) => void;

export default function* requestSaga(action: AsyncAction<unknown>): any {
  const { dispatch, request } = action.payload;

  let actionPromise: {
    resolve: ResolveType;
    reject: (reason?: unknown) => void;
  };
  action.promise = new Promise((resolve, reject) => {
    actionPromise = { resolve, reject };
  });

  yield spawn(updateRequestStatusSaga, action);
  yield put({ type: dispatch.START });

  try {
    const requestPromise = makeRequest({ request });
    const { data } = yield call(() => requestPromise);

    const successAction = {
      type: dispatch.SUCCESS,
      payload: data,
    };

    yield put(successAction);

    actionPromise!.resolve(successAction);
  } catch (e: any) {
    const { response } = e as AxiosError<ApiResponseErrorType, any>;
    const errAction = {
      type: dispatch.ERROR,
      payload: response?.data?.error,
      errors: response?.data?.errors,
    };
    yield put(errAction);

    actionPromise!.reject(errAction);
  }
}
