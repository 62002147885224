import { StylesObjectType } from '../../../../types/types';

export default function styles(): StylesObjectType {
  return {
    wrap: {
      marginBottom: '20px',

      '& .MuiDataGrid-row.Mui-selected': {
        background: 'rgba(82, 125, 240, 0.5) !important',
      },
    },
    listButton: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    loaderWrapper: {
      top: '200px',
    },
    root: {
      '& .MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
        outline: 'none !important',
      },
    },
    menuItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      textTransform: 'lowercase' as const,
    },
    subMenu: {},
  };
}
