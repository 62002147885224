import React, { useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import Section from '../../../../components/ui/Section';
import FirmwareModal from '../../../../components/modals/FirmwareModal';
import { DeviceShortType, FirmwareType } from '../../../../types/device';
import { AsyncAction } from '../../../../redux/api/interface';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import useModal from '../../../../hooks/useModal';
import { getFirmwareToShow } from '../../../../redux/deviceDetails/selectors';
import FirmwareViewForm from '../FirmwareViewForm';

const DeviceFirmware = ({
  firmware,
  deviceId,
  handleDeleteFirmware,
  isLoading,
  isLast,
}: DeviceFirmwareProps): JSX.Element => {
  const { isModalOpen, toggleModal } = useModal();
  const { isModalOpen: isEditModalOpen, toggleModal: toggleEditModal } =
    useModal();

  const firmwareToShow = useSelector(getFirmwareToShow);
  const firmwareRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (firmwareRef.current) {
      if (!isLoading) {
        firmwareRef.current.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }
    }
  }, [firmwareToShow, isLoading]);

  const isTrue =
    firmwareToShow !== '' && firmware.firmwareName.includes(firmwareToShow);

  return (
    <>
      {isModalOpen && (
        <ConfirmModal
          headerText="Delete firmware"
          handleCancel={() => toggleModal()}
          handleConfirm={() =>
            handleDeleteFirmware().promise?.then(() => toggleModal(false))
          }
        >
          <Typography>Are you sure to delete firmware version?</Typography>
        </ConfirmModal>
      )}

      {isEditModalOpen && (
        <FirmwareModal
          handleClose={() => toggleEditModal(false)}
          deviceId={deviceId}
          firmwareId={firmware.id}
          initialValues={{
            ...firmware,
            releaseDate: firmware.releaseDate
              ? format(new Date(firmware.releaseDate), 'yyyy-MM-dd')
              : '',
          }}
          isEdit
        />
      )}

      <Section
        headerText={`${firmware.firmwareName} (${firmware.status})`}
        withEdit={firmware.status === 'DRAFT' || isLast}
        editCallback={() => toggleEditModal(true)}
        withDelete
        deleteCallback={() => toggleModal(true)}
        initialIsOpen={isTrue}
      >
        <div ref={isTrue ? firmwareRef : null}>
          <FirmwareViewForm
            firmware={{
              ...firmware,
              releaseDate: firmware.releaseDate
                ? format(new Date(firmware.releaseDate), 'yyyy-MM-dd')
                : '',
            }}
            key={firmware.firmwareName}
          />
        </div>
      </Section>
    </>
  );
};

type DeviceFirmwareProps = {
  firmware: FirmwareType;
  deviceId: DeviceShortType['id'];
  handleDeleteFirmware: () => AsyncAction<unknown>;
  isLoading: boolean;
  isLast: boolean;
};

export default DeviceFirmware;
